export default {
  menu: {
    '单量套餐': 'Subscription Service',
    '首页': 'Home',
    "受限页面":"",
    "入库": "Stock-In",
    "调拨": "Transfer",
    "销量统计": "Transfer",
    "店铺利润报表": "Store profit statement",
    "业绩利润报表": "Performance profit statement",
    "Shopee商品数据表": "Shopee Product data table",
    "Shopee店铺数据表": "Shopee Store data table",
    "利润月报": "Profit monthly report",
    '订单地区分布': 'Order region distribution',
    '店铺分析': 'Store Report',
    '订单统计': 'Order Statistics',
    '店铺销售分析': 'Store Sales Analytics',
    '店铺利润分析': 'Store Profit Analytics',
    '店铺周期性对比': 'Periodic Sales contrast',
    '账户健康状况': 'Store Health',
    '订单统计': 'Order Statistics',
    "广告分析": "Ad analysis",
    '客户分析': 'Customer Analytics',
    '商品分析': 'Product Analytics',
    '商品数据分析': 'Product Data analysis',
    '评论分析': 'Review Insights',
    '分析报告': 'Analytics Reports',
    "退货退款": "Return Manage",
    '数据诊断': 'data Insights',
    '订单分析': 'Order Report',
    '物流管理': 'Logistics Management',
    "物流设置": "Shipping Settings",
    '退货报告': 'Return report',
    '利润分析': 'Profit Analytics',
    '订单利润明细': 'Order Profit Details',
    '广告概览': 'Advertising overview',
    '广告业绩': 'Advertising performance',
    '广告采集': 'Advertising collection',
    '商品配置': 'Product configuration',
    '本地SKU': 'Local SKU',
    '商品配对': 'Merchant SKU Mapping',
    '报表中心': 'Reports',
    '经营看板': 'Business dashboard',
    '报表下载': 'Report download',
    '单量套餐': 'Subscription Service',
    '运营待办': 'Operations to-do list',
    '订单管理': 'Orders Management',
    '订单处理': 'Processing Orders',
    '退货管理': 'Return Manage',
    '线上订单': 'Manual Order ',
    '线下订单': 'Retail Orders',
    '运费管理': 'Freight management',
    '商品管理': 'Merchant Management',
    '商品SKU': 'Merchant SKU',
    'SKU匹配关系': 'SKU Mapping',
    '库龄': 'Inventory age',
    '成本中心': 'Cost center',
    '仓库管理': 'Warehouse',
    '货架位': 'Shelves',
    '商品推送': 'Product push',
    '店铺库存预警': ' Stock Alert ',
    '库存列表': 'Inventory List',
    '仓库列表': 'Warehouse',
    '仓库盘点': 'Stock Count',
    '出库': 'Stock-Out',
    '出入库记录': 'Stock Movement',
    '采购管理': 'Purchase Manage',
    '采购单': 'Purchase Order',
    '采购建议': 'Purchase Suggestion',
    '供应商列表': 'Supplier List',
    '收货管理': 'Receiving Management',
    '站点': '站点',
    '短信渠道': '短信渠道',
    '短信模板': '短信模板',
    '短信日志': '短信日志',
    '文件列表': '文件列表',
    '文件配置': '文件配置',
    'Banner管理': 'Banner管理',
    '令牌管理': '令牌管理',
    '应用管理': '应用管理',
    '通知公告': '通知公告',
    '文章管理': '文章管理',
    '第三方服务配置': '第三方服务配置',
    '业务参数配置': '业务参数配置',
    '支付': 'Payment',
    '商户信息': '商户信息',
    '应用信息': '应用信息',
    '支付订单': '支付订单',
    '退款订单量': 'Quantity of Refund orders',
    '监控': '监控',
    'API日志': 'API日志',
    '访问日志': '访问日志',
    '错误日志': '错误日志',
    '审计日志': '审计日志',
    'MySQL:监控': 'MySQL:监控',
    'Redis:监控': 'Redis:监控',
    'Java:监控': 'Java:监控',
    '监控平台': '监控平台',
    'DEV': 'DEV',
    '代码生成': '代码生成',
    '数据源配置': '数据源配置',
    '表单构建': '表单构建',
    '系统接口': '系统接口',
    '数据库文档': '数据库文档',
    '设置': 'Setting',
    '租户': '租户',
    '租户列表': '租户列表',
    '租户套餐': '租户套餐',
    '操作日志': 'Activity Log',
    '账号管理': 'Sub-account Settings',
    '角色管理': 'Roles ',
    '子账号管理': 'Sub-Accounts',
    '授权管理': 'Authorization',
    '账号管理': 'Sub-account Settings',
    '店铺管理': 'Store Authorization',
    '仓库授权': 'Service Provider Authorization',
    '设置': 'Setting',
    '预估利润设置': 'Estimated Profit Setting',
    '测评参数': 'Evaluation parameters',
    '汇率设置': 'Exchange Settings',
    '标签管理': 'Mark Management',
    '订单导出模板': 'Order export template',
    '下载中心': '下载中心',
    '超级管理员': 'Super administrator',
    '用户管理': 'User management',
    '系统角色管理': '系统角色管理',
    '菜单管理': '菜单管理',
    '字典管理': '字典管理',
    '定时任务': '定时任务',
    '公告中心': '公告中心',
    '租户订单': '租户订单',
    '三方仓': 'Third-Party  Warehouses',
    '三方仓厂商管理': 'Warehouse service provider',
    '三方仓授权仓库管理': 'Authorized warehouse',
    '其他': 'Other',
    '部门管理': '部门管理',
    '岗位管理': '岗位管理',
    '配置管理': '配置管理',
    '错误码管理': '错误码管理',
    '敏感词管理': '敏感词管理',
    '地区管理': '地区管理',
    '电商平台管理': '电商平台管理',
    '个人中心': 'Personal Center',
    '字典数据': '字典数据',
    '模板管理': 'Template management',
    'AI模板管理': 'AI模板管理',
    'AI模板分类管理': 'AI模板分类管理',
    '会话管理': 'Session management',
    '创作训练': 'Creative training',
    '授权结果': 'Authorization result',
    '授权失败': 'Authorization failed',

    //  2024.3.5 物流功能新增翻译
    "物流管理": "Logistics Management",
    "物流设置": "Logistics Settings"
  },
  title: {
    '项目': 'Project',
    '总数据': 'Summarized Data',
    '件数': 'Quantity',
    '销售': 'Sales Volume',
    '测评销量': 'test Sales Quantity',
    '测评金额': 'Evaluation amount',
    '平均客单价': 'Average Order Value',
    '支出': 'Expenditure',
    '搜索广告费': 'Search Advertising Cost',
    '搜索广告转化': 'Search Advertising Conversion',
    '搜索广告点击率': 'Search Advertising Click-through Rate',
    '搜索广告转化率': 'Search Advertising Conversion Rate',
    '搜索广告ROI': 'Search Advertising ROI',
    '关联广告费': 'Affiliate Advertising Cost',
    '关联广告转化': 'Affiliate Advertising Conversion',
    '关联广告销售额': 'Affiliate Advertising Sales',
    '关联广告点击率': 'Affiliate Advertising Click-through Rate',
    '关联广告转化率': 'Affiliate Advertising Conversion Rate',
    '关联广告ROI': 'Affiliate Advertising ROI',
    '推广广告费': 'Promotional Advertising Cost',
    '推广广告转化': 'Promotional Advertising Conversion',
    '推广广告销售额': 'Promotional Advertising Sales',
    '推广广告点击率': 'Promotional Advertising Click-through Rate',
    '推广广告转化率': 'Promotional Advertising Conversion Rate',
    '推广广告ROI': 'Promotional Advertising ROI',
    '测评损耗': 'Evaluation Loss',
    '商品成本': 'Cost of Merchant SKU',
    '退货金额': 'Refunds',
    '预估运费': 'Estimated Shipping Cost',
    '产出': 'Output',
    '利润': 'Profit/Loss',
    '销售利润率': 'Profit Rate',
  },
  order: {
    '不在待打单状态': 'Not in Pending Status',
    '请扫描或输入订单号或运单号': 'Please Scan or Enter Order Number or Tracking Number',
    '发货记录': 'Shipping Records',
    'Free': 'Free Shipping Cost',
    'Item': 'Goods Payment',
    'Payment': 'Transaction Fees',
    'Commission': 'Commission',
    'Promotional': 'Seller Coupons',
    'Lazcoin': 'Lazcoin Diskon',
    'LazFlash': "Additional/Subsidy for LazFlash Each Day Below $9.99",
  },
  user: {
    "请输入新手机号码": 'please Enter the new phone number',
    "输入旧密码": 'please Enter the old password',
    "请先使用主账号绑定手机号码": 'Please bind your phone number with the main account first',
    "销售地区": 'Sales Region',
    "时区": 'Time Zone',
    "您的套餐还剩天，为避免服务中断，请尽快续订。立即购买": 'Your plan has {days} days left. To avoid service interruption, please renew as soon as possible. Click here to buy now',
  },
  "缺货": "Kekurangan Stok",
  "推送成功": "Push Successfully",
  "默认日销量": "Default daily sales volume",
  "已完成": "Completed",
  "调拨收货": "Transfer Receiving",
  "采购收货": "Purchase Receiving",
  "已取消": "Canceled",
  "在途中": "On The Way",
  "盘点中": "Counting",
  "采购计划": "Purchase Plan",
  "部分收货": "Partial Reception",
  "是": "Yes",
  "否": "No",
  "亏损商品": "Loss-making Products",
  "改变主意": "Changed mind",
  "台湾": "Taiwan, China",
  "站外广告费": "Off-Site Advertising Fee",
  "站内广告费": "On-Site Advertising Fee",
  "样品费用": "Changed mind",
  "空包费用": "Empty Package Fee",
  "平台调整": "Platform Adjustment",
  "低销商品": "Low-selling Products",
  "利润偏低": "Low Profit Margin",
  "退货暴增": "Surge in Returns",
  "商品缺件": "Missing item",
  "销量暴跌": "Sharp Decline in Sales",
  "销量暴涨": "Sharp Increase in Sales",
  "低回应时间": "Low Response Time",
  "逾期发货率过高": "High Overdue Delivery Rate",
  "商品损坏": "Item damaged",
  "其他": "Others",
  "商品发错了": "Wrong item received",
  "商品存在瑕疵": "Defective item",
  "商品和描述不一致": "Item does not match description",
  "印尼": "Indonesia",
  "马来西亚": "Malaysia",
  "菲律宾": "Philippines",
  "新加坡": "Singapore",
  "泰国": "Thailand",
  "越南": "Vietnam",
  "快递取件":"Express Pickup",
  "打印拣货（含汇总）":"Print Picking (including summary)",
  "确定批量设置仓库":"Confirm Batch Warehouse Settings",
  "仓库盘点时请勿关闭当前窗口，盘点完成后可关闭窗口":"Do not close the current window during warehouse inventory. You can close the window after the inventory is complete.",
  "创建盘点任务时请勿关闭当前窗口，创建完成后可关闭窗口":"Do not close the current window when creating an inventory task. You can close the window after the creation is complete.",
  "正在导入数据":"Importing data",
  "设置全部页商品仓库":"Set warehouse for all pages of goods",
  "设置已选商品仓库":"Set warehouse for selected goods",
  "印度尼西亚":"Indonesia",
  "当前账号下无可新增子账号的额度，请升级版本或删除无用的子账号空出名额后再次尝试。":"There is no quota available for adding sub-accounts under the current account. Please upgrade your version or delete unused sub-accounts to make room and try again.",
  "佣金计算公式":"Commission Calculation Formula",
  "由于Lazada、TikTok平台佣金在订单完成时才结算，此处需要设置一个预估的佣金比例，用于订单未回款时利润的估算。":"Due to Lazada and TikTok platforms settling commissions only when orders are completed, here you need to set an estimated commission rate for estimating profits when orders are not paid back.",
  "物流管理":"Logistics Management",
  "运费正常":"Shipping fee normal",
  "运费亏损":"Shipping fee loss",
  "运费盈余":"Shipping fee surplus",
  "可按物流方式分别设置揽收方式":"Pickup method by shipping method.",
  "自行寄件":"Self-pickup",
  "请选择揽收方式":"Please select pickup method",
  "线上物流":"Online logistics",
  "待处理": "Pesanan Baru",
  "待打单": "To Ship",
  "待揽收":"Menunggu Pickup",
  "运输中":"In transit",
  "已妥投":"Delivered",
  "运输失败":"Delivery failed",
  "未审核": "Unverified",
  "审核中": "Under review",
  "审核失败": "Pesanan Ditolak",
  "缺货": "Kekurangan Stok",
  "SKU编号": "SKU Number",
  "title2726": "已妥投",
  "title0": 'Select Store',
  "title1": 'Current Time',
  "title2": 'China',
  "title3": 'Initial setup guide',
  "title4": 'Fold',
  "title5": 'Help',
  "title6": 'Live Sales data',
  "title7": 'Update Time：',
  "title8": 'Store Name',
  "title9": 'Platform region',
  "title10": 'Yesterday',
  "title11": 'Real-time Advertising Analysis',
  "title12": 'Comprehensive Metrics',
  "title13": 'Current Period:',
  "title14": 'Month-over-Month Period:',
  "title15": 'Month-over-Month Difference:',
  "title16": 'Chain ratio',
  "title17": 'Product Sales Ranking',
  "title18": 'Sales Ranking',
  "title19": 'Profit Ranking',
  "title20": 'Return Ranking',
  "title21": 'Ranking',
  "title22": 'Analyze',
  "title23": 'product',
  "title24": 'Valid Sales',
  "title25": 'Valid Sales Quantity',
  "title26": 'Profit/Loss',
  "title27": 'Profit Rate',
  "title28": 'Return Quantity',
  "title29": 'Return Rate',
  "title30": 'After-sales Analysis',
  "title31": 'Last 7 Days',
  "title32": 'Refunds',
  "title33": 'Quantity of Refund orders',
  "title34": 'Quantity of Cancelled Orders',
  "title35": 'Cancellation Rate',
  "title36": 'Smart Reminder',
  "title37": 'Sharp Increase in Sales',
  "title38": 'Sharp Decline in Sales',
  "title39": 'Loss-making Products',
  "title40": 'Low-selling Products',
  "title41": 'Low Profit Margin',
  "title42": 'Surge in Returns',
  "title43": 'Store Status Alert',
  "title44": 'Neutral or Negative Reviews',
  "title45": 'Promotion Ended',
  "title46": 'Product Violation Removal',
  "title47": 'Low Response Time',
  "title48": 'High Overdue Delivery Rate',
  "title49": 'Store Condition',
  "title50": 'Excellent Store',
  "title51": 'Good Store',
  "title52": 'Store Improvement',
  "title53": 'Poor',
  "title54": 'Notification Center',
  "title55": 'System',
  "title56": 'Upgrade',
  "title57": 'Reminder',
  "title58": "福建象岸科技有限公司",
  "title59": "闽",
  "title60": "备2023007214号",
  "title61": 'Sunday',
  "title62": 'Monday',
  "title63": 'Tuesday',
  "title64": 'Wednesday',
  "title65": 'Thursday',
  "title66": 'Friday',
  "title67": 'Saturday',
  "title68": 'Store Authorization',
  "title69": 'Authorize your store to the system for seamless synchronization of orders, products, etc.',
  "title70": 'Deauthorize',
  "title71": 'Merchant Management',
  "title72": 'Create local product SKUs for fine product management.',
  "title73": 'Go to Settings',
  "title74": 'Merchant SKU Mapping',
  "title75": 'Match local products with online platforms for seamless integration.',
  "title76": 'Match',
  "title77": 'Warehouse',
  "title78": 'Initialize inventory for subsequent purchasing, restocking, shipping, etc.',
  "title79": 'Sub-account Settings',
  "title80": "Configure permissions and operations for different roles in your company's system",
  "title81": 'Plugin Installation',
  "title82": 'Assist in obtaining non-API data, advertising-related data, and product performance data.',
  "title83": 'Go to Install',
  "title84": 'Financial data',
  "title85": 'Analyze operational data such as stores, products, orders, and automatically generate and download report data',
  "title86": 'Details',
  "title88": 'Valid Order Quantity',
  "title89": 'Advertising sales',
  "title90": 'Advertising cost',
  "title91": 'Advertising Orders',
  "title92": 'Ad Click-through Rate',
  "title93": 'Today',
  "title94": 'Same day last week',
  "title95": 'By order',
  "title96": '7 Days',
  "title97": '30 Days',
  "title98": 'At least select one indicator',
  "title99": "领取阿里云通用云产品1888优惠券",
  "title100": "领取腾讯云通用云产品2860优惠券",
  "title101": "阿里云服务器折扣区",
  "title102": "点我进入",
  "title103": "腾讯云服务器秒杀区",
  "title104": "云产品通用红包，可叠加官网常规优惠使用。",
  "title105": "仅限新用户",
  "title106": "芋道后台管理框架",
  "title107": "一直想做一款后台管理系统，看了很多优秀的开源项目但是发现没有合适自己的。于是利用空闲休息时间开始自己写一套后台系统。如此有了芋道管理系统。，她可以用于所有的",
  "title108": "应用程序，如网站管理后台，网站会员中心，",
  "title109": "等等，当然，您也可以对她进行深度定制，以做出更强系统。所有前端后台代码封装过后十分精简易上手，出错概率低。同时支持移动客户端访问。系统会陆续更新一些实用功能。",
  "title110": "当前版本",
  "title111": "免费开源",
  "title112": "访问码云",
  "title113": "访问主页",
  "title114": "技术选型",
  "title115": "后端技术",
  "title116": "前端技术",
  "title117": "联系信息",
  "title118": "官网：",
  "title119": "群：",
  "title120": "满937441",
  "title121": "满887144332",
  "title122": "满180251782",
  "title123": "微信：",
  "title124": "芋道",
  "title125": "支付宝：",
  "title126": "支付宝信息",
  "title127": "更新日志",
  "title128": "新增缓存监控功能",
  "title129": "支持主题风格配置",
  "title130": "修复多级菜单之间切换无法缓存的问题",
  "title131": "多级菜单自动配置组件",
  "title132": "代码生成预览支持高亮显示",
  "title133": "支持",
  "title134": "请求映射",
  "title135": "参数",
  "title136": "删除用户和角色解绑关联",
  "title137": "去除用户手机邮箱部门必填验证",
  "title138": "支持注解",
  "title139": "对齐方式",
  "title140": "支持导入",
  "title141": "型数据",
  "title142": "优化头像样式，鼠标移入悬停遮罩",
  "title143": "代码生成预览提供滚动机制",
  "title144": "代码生成删除多余的数字",
  "title145": "类型",
  "title146": "修正转换字符串的目标字符集属性",
  "title147": "回显数据字典防止空值报错",
  "title148": "日志记录增加过滤多文件场景",
  "title149": "修改缓存",
  "title150": "方法可能导致嵌套的问题",
  "title151": "移除前端一些多余的依赖",
  "title152": "防止安全扫描",
  "title153": "出现的风险提示",
  "title154": "修改",
  "title155": "为",
  "title156": "到最新版本2",
  "title157": "到最新版本4",
  "title158": "到最新版本",
  "title159": "到最新版本1",
  "title160": "到最新版本0",
  "title161": "到最新版本3",
  "title162": "到版本4",
  "title163": "到最新版本6",
  "title164": "到最新版本5",
  "title165": "到最新版本7",
  "title166": "到最新版本10",
  "title167": "其他细节优化",
  "title168": "阻止任意文件下载漏洞",
  "title169": "代码生成支持上传控件",
  "title170": "新增图片上传组件",
  "title171": "调整默认首页",
  "title172": "配置支持分隔符",
  "title173": "权限信息调整",
  "title174": "调整",
  "title175": "默认时间",
  "title176": "解决代码生成没有",
  "title177": "类型的问题",
  "title178": "到最新版1",
  "title179": "版本到2",
  "title180": "提升安全性",
  "title181": "到版本0",
  "title182": "避免",
  "title183": "图标乱码",
  "title184": "代码生成支持同步数据库",
  "title185": "代码生成支持富文本控件",
  "title186": "代码生成页面时不忽略",
  "title187": "属性",
  "title188": "代码生成添加",
  "title189": "必填选项",
  "title190": "导出类型",
  "title191": "支持精度浮点类型",
  "title192": 'Export',
  "title193": "优化获取值，防止",
  "title194": "方法不规范",
  "title195": "注解支持自动统计数据总和",
  "title196": "注解支持设置",
  "title197": "精度",
  "title198": "舍入规则",
  "title199": 'Menu',
  "title200": "数据权限新增（展开",
  "title201": 'Collapse',
  "title202": 'Select all',
  "title203": 'Deselect all',
  "title204": "父子联动）",
  "title205": "允许用户分配到部门父节点",
  "title206": "菜单新增是否缓存",
  "title207": "表格操作列间距调整",
  "title208": "限制系统内置参数不允许删除",
  "title209": "富文本组件优化，支持自定义高度",
  "title210": "图片冲突问题",
  "title211": "富文本工具栏样式对齐",
  "title212": 'Import',
  "title213": "整形值校验优化",
  "title214": "修复页签关闭所有时固定标签路由不刷新问题",
  "title215": "表单构建布局型组件新增按钮",
  "title216": "左侧菜单文字过长显示省略号",
  "title217": "修正根节点为子部门时，树状结构显示问题",
  "title218": "修正调用目标字符串最大长度",
  "title219": "修正菜单提示信息错误",
  "title220": "修正定时任务执行一次权限标识",
  "title221": "修正数据库字符串类型",
  "title222": "优化递归子节点",
  "title223": "优化数据权限判断",
  "title224": "表格工具栏右侧添加刷新",
  "title225": "显隐查询组件",
  "title226": "后端支持",
  "title227": "跨域请求",
  "title228": "代码生成支持选择上级菜单",
  "title229": "代码生成支持自定义路径",
  "title230": "代码生成支持复选框",
  "title231": "导出导入支持",
  "title232": "字典类型",
  "title233": "支持分割字符串组内容",
  "title234": "验证码类型支持（数组计算、字符验证）",
  "title235": "版本到4",
  "title236": "表单类型为",
  "title237": "设置整形默认值",
  "title238": "代码生成器默认",
  "title239": "路径与默认",
  "title240": "路径不一致",
  "title241": "优化防重复提交拦截器",
  "title242": "优化上级菜单不能选择自己",
  "title243": "修复角色的权限分配后，未实时生效问题",
  "title244": "修复在线用户日志记录类型",
  "title245": "修复富文本空格和缩进保存后不生效问题",
  "title246": "修复在线用户判断逻辑",
  "title247": "唯一限制条件只返回单条数据",
  "title248": "添加获取当前的环境配置方法",
  "title249": "超时登录后页面跳转到首页",
  "title250": "全局异常状态汉化拦截处理",
  "title251": "过滤器改为将",
  "title252": "转义",
  "title253": "检查字符支持小数点",
  "title254": "降级改成异常提醒",
  "title255": "单应用调整为多模块项目",
  "title256": 'Delete',
  "title257": "提高编译速度。",
  "title258": "新增菜单默认主类目",
  "title259": "编码文件名修改为",
  "title260": 'Method',
  "title261": "定时任务",
  "title262": "表达式验证",
  "title263": "角色权限修改时已有权限未自动勾选异常修复",
  "title264": "防止切换权限用户后登录出现404",
  "title265": "导出排序",
  "title266": "创建用户不允许选择超级管理员角色",
  "title267": "修复代码生成导入表结构出现异常页面不提醒问题",
  "title268": "修复代码生成点击多次表修改数据不变化的问题",
  "title269": "修复头像上传成功二次打开无法改变裁剪框大小和位置问题",
  "title270": "修复布局为",
  "title271": "者",
  "title272": "用户表单显示错位问题",
  "title273": "修复热部署导致的强换异常问题",
  "title274": "修改用户管理复选框宽度，防止部分浏览器出现省略号",
  "title275": "工具，清除",
  "title276": "特殊字符，防止",
  "title277": "注入攻击",
  "title278": "生成",
  "title279": "如果是浮点型",
  "title280": "统一用",
  "title281": "定时任务调整",
  "title282": "防止部署出现错位",
  "title283": "调整表头固定列默认样式",
  "title284": "代码生成模板调整，字段为",
  "title285": "并且必填则加空串条件",
  "title286": "代码生成字典",
  "title287": "使用",
  "title288": "修复",
  "title289": "不可",
  "title290": "为0的问题",
  "title291": "查询返回增加",
  "title292": "升序排序",
  "title293": "修正岗位导出权限注解",
  "title294": "禁止加密密文返回前端",
  "title295": "修复代码生成页面中的查询条件创建时间未生效的问题",
  "title296": "修复首页搜索菜单外链无法点击跳转问题",
  "title297": "修复菜单管理选择图标，",
  "title298": "删除时不过滤数据",
  "title299": "用户管理部门分支节点不可检查",
  "title300": "显示计数",
  "title301": "数据范围过滤属性调整",
  "title302": "修复高危安全漏洞",
  "title303": "启动默认打开浏览器",
  "title304": "使用默认",
  "title305": "报错优化",
  "title306": "当",
  "title307": "滚动关闭右键菜单",
  "title308": "字典管理添加缓存读取",
  "title309": "参数管理支持缓存操作",
  "title310": "支持一级菜单（和主页同级）在",
  "title311": "区域显示",
  "title312": "限制外链地址必须以",
  "title313": "开头",
  "title314": "主题颜色与",
  "title315": "全局",
  "title316": "同步",
  "title317": "修改数据源类型优先级，先根据方法，再根据类",
  "title318": "支持是否需要设置",
  "title319": "属性，自定义返回码消息。",
  "title320": "请求前缀加入配置。",
  "title321": "登录地点设置内容过长则隐藏显示",
  "title322": "修复定时任务执行一次按钮后不提示消息问题",
  "title323": "修改上级部门（选择项排除本身和下级）",
  "title324": "通用",
  "title325": "发送方法增加参数",
  "title326": "编码类型",
  "title327": "更换",
  "title328": "地址查询接口",
  "title329": "修复页签变量",
  "title330": "添加校验部门包含未停用的子部门",
  "title331": "修改定时任务详情下次执行时间日期显示错误",
  "title332": "角色管理查询设置默认排序字段",
  "title333": "添加",
  "title334": "参数控制是否启用",
  "title335": "只对",
  "title336": "类型请求构建可重复读取",
  "title337": "的",
  "title338": "修改代码生成字典字段",
  "title339": "类型没有自动选中问题",
  "title340": "用户名取值修正",
  "title341": "表格树模板去掉多余的",
  "title342": "代码生成序号修正",
  "title343": "全屏情况下不调整上外边距",
  "title344": "代码生成",
  "title345": "字段添加默认格式",
  "title346": "用户管理角色选择权限控制",
  "title347": "修复路由懒加载报错问题",
  "title348": "模板",
  "title349": "添加菜单状态",
  "title350": "设置用户名称不能修改",
  "title351": "属性，防止",
  "title352": "遮罩",
  "title353": "菜单区分状态和显示隐藏功能",
  "title354": "修复安全加固",
  "title355": "修复代码生成如果选择字典类型缺失逗号问题",
  "title356": "登录请求",
  "title357": "更换为",
  "title358": "防止暴露",
  "title359": "日志返回时间格式处理",
  "title360": "控制允许拖动的元素",
  "title361": "布局设置点击扩大范围",
  "title362": "代码生成列属性排序查询",
  "title363": "代码生成列支持拖动排序",
  "title364": "修复时间格式不支持",
  "title365": "问题",
  "title366": "表单构建添加父级",
  "title367": "防止冲突",
  "title368": "定时任务并发属性修正",
  "title369": "角色禁用",
  "title370": "菜单隐藏不查询权限",
  "title371": "系统监控新增定时任务功能",
  "title372": "添加一个打包",
  "title373": "工程",
  "title374": "修复页签鼠标滚轮按下的时候，可以关闭不可关闭的",
  "title375": "修复点击退出登录有时会无提示问题",
  "title376": "修复防重复提交注解无效问题",
  "title377": "修复通知公告批量删除异常问题",
  "title378": "添加菜单时路由地址必填限制",
  "title379": "代码生成字段描述可编辑",
  "title380": "修复用户修改个人信息导致缓存不过期问题",
  "title381": "个人信息创建时间获取正确属性值",
  "title382": "操作日志详细显示正确类型",
  "title383": "导入表单击行数据时选中对应的复选框",
  "title384": "批量替换表前缀逻辑调整",
  "title385": "固定重定向路径表达式",
  "title386": "操作日志排序调整",
  "title387": "切换侧边栏或者缩放窗口显示",
  "title388": "新增表单构建",
  "title389": "代码生成支持树表结构",
  "title390": "新增用户导入",
  "title391": "修复动态加载路由页面刷新问题",
  "title392": "修复地址开关无效问题",
  "title393": "汉化错误提示页面",
  "title394": "代码生成已知问题修改",
  "title395": "修复多数据源下配置关闭出现异常处理",
  "title396": "过滤器，用于去除",
  "title397": "漏洞隐患",
  "title398": "修复上传头像控制台出现异常",
  "title399": "修改用户管理分页不正确的问题",
  "title400": "修复验证码记录提示错误",
  "title401": "缺少",
  "title402": "引用",
  "title403": "修复表格时间为空出现的异常",
  "title404": "日期反序列化时区配置",
  "title405": "调整根据用户权限加载菜单数据树形结构",
  "title406": "调整成功登录不恢复按钮，防止多次点击",
  "title407": "修改用户个人资料同步缓存信息",
  "title408": "修复页面同时出现",
  "title409": "和",
  "title410": "不显示处理",
  "title411": "修复在角色管理页修改菜单权限偶尔未选中问题",
  "title412": "配置文件新增",
  "title413": "密码属性",
  "title414": 'Setting',
  "title415": "全局的配置文件",
  "title416": "新增代码生成",
  "title417": 'Add',
  "title418": "注解，防止重复提交",
  "title419": "新增菜单主目录添加",
  "title420": "删除操作",
  "title421": "日志记录过滤特殊对象，防止转换异常",
  "title422": "修改代码生成路由脚本错误",
  "title423": "用户上传头像实时同步缓存，无需重新登录",
  "title424": "调整切换页签后不重新加载数据",
  "title425": "实现参数的前端加密",
  "title426": "系统退出删除用户缓存记录",
  "title427": "新增在线用户管理",
  "title428": "新增按钮组功能实现（批量删除、导出、清空）",
  "title429": "新增查询条件重置按钮",
  "title430": 'Configuration',
  "title431": "新增后端参数校验",
  "title432": "修复字典管理页面的日期查询异常",
  "title433": "修改时间函数命名防止冲突",
  "title434": "去除菜单上级校验，默认为顶级",
  "title435": "修复用户密码无法修改问题",
  "title436": "修复菜单类型为按钮时不显示权限标识",
  "title437": "芋道前后端分离系统正式发布",
  "title438": "捐赠支持",
  "title439": "你可以请作者喝杯咖啡表示鼓励",
  "title440": 'Welcome to xinjian',
  "title441": 'Register',
  "title442": 'Forgot Password',
  "title443": 'Tenant',
  "title444": 'Enter phone number',
  "title445": 'User name',
  "title446": 'Password',
  "title447": 'Logging in',
  "title448": "录",
  "title449": "中",
  "title450": 'Auto-login within days',
  "title451": 'Forgot Password ?',
  "title452": 'No account yet?',
  "title453": 'Sign up',
  "title454": 'Enter phone number',
  "title455": 'Enter password',
  "title456": 'Enter the graphic code',
  "title457": 'Get verification code',
  "title458": 'Resend after seconds',
  "title459": 'Read and accept',
  "title460": 'Terms of Service',
  "title461": 'Already have an account？',
  "title462": 'Go to login',
  "title463": 'Enter the graphic code',
  "title464": 'Submit',
  "title465": 'No need to retrieve',
  "title466": 'Enter password',
  "title467": 'Re-enter password',
  "title468": 'Reset password',
  "title469": 'Complete',
  "title470": 'Start using',
  "title471": 'Back to previous step',
  "title472": 'Login',
  "title473": 'Agree and Continue',
  "title474": 'Characters',
  "title475": 'Letters must contain uppercase',
  "title476": 'Must contain at least 2 types of letters, numbers, and characters',
  "title477": 'Password cannot be the same as the username',
  "title478": 'Please re-enter your password',
  "title479": 'The passwords entered do not match',
  "title480": 'Cross-border e-commerce in Southeast Asia',
  "title481": 'Fine-tuned operations and data analysis',
  "title482": 'Please enter the graphic verification code',
  "title483": 'Please enter phone number',
  "title484": 'The phone number should start with 1 and consist of 11 digits',
  "title485": 'Tenant cannot be empty',
  "title486": 'Indonesia',
  "title487": 'Philippines',
  "title488": 'Thailand',
  "title489": 'Vietnam',
  "title490": 'Malaysia',
  "title491": 'Singapore',
  "title492": 'Taiwan, China',
  "title493": 'Account not exists',
  "title494": 'Please read and agree to the terms',
  "title495": 'Verification code obtained successfully',
  "title496": 'Modification successful, please log in again',
  "title497": 'Registration successful, please log in',
  "title498": 'Xinjian',
  "title499": "三方授权（",
  "title500": "此第三方应用请求获得以下权限：",
  "title501": "同意授权",
  "title502": "授权",
  "title503": "权",
  "title504": "拒绝",
  "title505": "自动授权未通过！",
  "title506": "访问你的个人信息",
  "title507": "修改你的个人信息",
  "title508": 'Bind account',
  "title509": 'Account',
  "title510": 'Remember password',
  "title511": 'Username cannot be empty',
  "title512": 'Password cannot be empty',
  "title513": 'Knowledge base',
  "title514": 'Product visitors',
  "title515": 'Message',
  "title516": 'Amount',
  "title517": 'Orders',
  "title518": 'Back',
  "title519": 'Error',
  "title520": 'You do not have access permission!',
  "title521": 'Sorry, you do not have access permission. Please refrain from illegal operations! You can return to the main page.',
  "title522": 'Back to Homepage',
  "title523": 'This function needs to be activated',
  "title524": 'Order package',
  "title525": 'To use, proceed to',
  "title526": 'Learn more',
  "title527": 'or',
  "title528": 'Contact customer service',
  "title529": 'Sorry, the page you are looking for does not exist. Try checking for',
  "title530": 'errors and then press the refresh button on your browser or try finding other content in our application.',
  "title531": 'Return to homepage',
  "title532": 'Page not found！',
  "title533": 'My favorites',
  "title534": 'Q&A',
  "title535": "答",
  "title536": 'Copy',
  "title537": 'Unfavorite',
  "title538": 'Free session',
  "title539": 'Template session',
  "title540": 'Document session',
  "title541": 'Copy successful',
  "title542": 'Unfavorite successful',
  "title543": 'New session',
  "title544": 'Clear all sessions',
  "title545": 'New session',
  "title546": 'Please modify again',
  "title547": 'Modification successful',
  "title548": 'Confirm deletion of session',
  "title549": 'Warning',
  "title550": 'Deletion successful！',
  "title551": 'Delete all sessions?',
  "title552": 'Intelligent knowledge base',
  "title553": 'Engage with documents, quickly extract, pinpoint, and summarize the information you need.',
  "title554": 'Create a new knowledge base',
  "title555": 'Build your own',
  "title556": 'Set up a template knowledge base, quickly parse and comprehend complex technical documents, document frequently asked questions, avoid redundant inquiries, and enhance work efficiency.',
  "title557": 'Edit template',
  "title558": 'Delete template',
  "title559": "Confirm delete this template?",
  "title560": 'Deletion successful',
  "title561": 'Category',
  "title562": 'Please enter category',
  "title563": 'Template type',
  "title564": 'Please select template type',
  "title565": 'Template title',
  "title566": 'Please enter template title',
  "title567": 'Search',
  "title568": 'Reset',
  "title569": "Primary Key",
  "title570": 'Creator',
  "title571": 'Template Introduction',
  "title572": 'Question Content',
  "title573": 'Question Variables',
  "title574": 'Create Time',
  "title575": 'Action',
  "title576": 'Please enter creator',
  "title577": 'Please enter template introduction',
  "title578": 'Please enter question content',
  "title579": 'Question Variables',
  "title580": 'Please enter question variables, using',
  "title581": 'Arrays are stored. Each object in the array contains the following fields: Variable Name',
  "title582": 'Corresponding Description Copy',
  "title583": 'Mandatory',
  "title584": 'Default Value',
  "title585": 'Confirm',
  "title586": "",
  "title587": 'Cancel',
  "title588": "",
  "title589": 'Modification successful',
  "title590": 'Addition successful',
  "title591": 'Confirm deletion',
  "title592": 'Template number is',
  "title593": 'Data item of',
  "title594": 'Confirm export all',
  "title595": 'Template data item',
  "title596": 'Exclusive',
  "title597": 'Edit knowledge base',
  "title598": "林珍",
  "title599": 'Modify question',
  "title600": 'Close',
  "title601": 'Welcome to mine',
  "title602": "频道！今天的主题是如何在生活中实现健康的生活方式。",
  "title603": "首先，一个健康的生活方式需要有一个良好的饮食习惯。我们应该尽量食用新鲜、天然的食物，避免摄入过多的加工食品和糖分。另外，我们也需要保持适当的饮食量，不要过量进食或过度节食。",
  "title604": "其次，适量的运动也是保持健康的重要一环。我们可以选择自己喜欢的运动方式，比如跑步、瑜伽、游泳等等。无论是室内还是户外运动，每天坚持30分钟以上的运动可以帮助我们保持身体健康，同时也有助于缓解压力和焦虑感。",
  "title605": "除了饮食和运动之外，我们还需要注意睡眠质量的保证。一个良好的睡眠环境可以帮助我们更好地入睡和保持深度睡眠状态。同时，规律的作息时间也是非常重要的，它可以帮助我们的身体建立一个健康的生物钟。",
  "title606": "最后，我们还可以通过一些放松的方式来缓解生活中的压力和疲劳感。比如听音乐、读书、冥想等等。这些活动可以帮助我们放松身心，让我们更加愉悦地度过每一天。",
  "title607": "以上就是我对如何在生活中实现健康生活方式的一些",
  "title608": 'Answering, please wait',
  "title609": 'Please enter your question content (Line break:',
  "title610": 'Press Enter to Send',
  "title611": 'Consume 1 point',
  "title612": 'Send',
  "title613": 'Help me write a life',
  "title614": 'Script',
  "title615": 'Describe the issue',
  "title616": 'Image',
  "title617": 'Enter Image',
  "title618": 'Category Name',
  "title619": 'Enter Category Name',
  "title620": 'Category Level',
  "title621": 'Enter Category Level',
  "title622": 'Parent',
  "title623": 'Enter Parent',
  "title624": 'Start Date',
  "title625": 'End Date',
  "title626": 'Template Category',
  "title627": 'Template category number is',
  "title628": 'Template category data items',
  "title629": 'Not sure what to write? Try inserting an example',
  "title630": 'Clear input',
  "title631": 'Start generating',
  "title632": 'Please enter content',
  "title633": 'all',
  "title634": 'Bookmark Success',
  "title635": 'Advertisement Placement Status',
  "title636": 'Collection Status',
  "title637": 'Collection Integrity',
  "title638": 'Last Collection Time',
  "title639": 'Please refer to "Data Collection Integrity" to determine if the advertising data for the past month has been successfully collected. If the data is incomplete, you can review the collection records for specific details.',
  "title640": 'View operation tutorial',
  "title641": 'Store Name',
  "title642": 'Not collected',
  "title643": 'Pause delivery',
  "title644": 'Delivering',
  "title645": 'Collecting',
  "title646": 'Delivering',
  "title647": 'Paused',
  "title648": 'Offline',
  "title649": 'Activate',
  "title650": 'Deactivate',
  "title651": 'Collection period',
  "title652": 'Collection records',
  "title653": 'Please note that not all advertising data has been collected by the system. To obtain complete data, please enable the advertising plugin and re-execute the collection process.',
  "title654": 'Product performance',
  "title655": 'Advertising data',
  "title656": 'Date',
  "title657": 'Complete',
  "title658": 'Incomplete',
  "title659": 'Collected',
  "title660": 'No ads',
  "title661": 'Statistical time',
  "title662": 'Ad analysis',
  "title663": ' Sort By ',
  "title664": 'Region',
  "title665": 'Trend chart',
  "title666": 'Store advertising expenses',
  "title667": 'Product advertising sales',
  "title668": 'product',
  "title669": 'Advertising campaign effect',
  "title670": 'Advertising campaign',
  "title671": 'Ad clicks',
  "title672": 'By Day',
  "title673": 'By Week',
  "title674": 'Monthly',
  "title675": 'Brazil',
  "title676": 'Mexico',
  "title677": 'Click-through rate',
  "title678": 'Ad views',
  "title679": 'Ad Views',
  "title680": 'Today',
  "title681": 'Yesterday',
  "title682": 'Last 7 Days',
  "title683": 'Last 15 Days',
  "title684": 'Last 30 Days',
  "title685": 'Ad Clicks',
  "title686": 'Compared with Yesterday',
  "title687": 'Compared to the previous day',
  "title688": 'Compared to the previous',
  "title689": 'day',
  "title690": 'Performance',
  "title691": 'Store data',
  "title692": 'Product data',
  "title693": 'Operations data',
  "title694": 'Store profit statement',
  "title695": 'Statistical store profits for analyzing store operations',
  "title696": 'Performance profit statement',
  "title697": 'Statistical performance report for operations personnel',
  "title698": 'Product data table',
  "title699": 'Statistical key indicators for understanding the overall operation performance of products',
  "title700": 'Store data table',
  "title701": "Analyze shop operation data for an overall view of the shop's performance.",
  "title702": 'Select region',
  "title703": 'Current period',
  "title704": 'Comparative period',
  "title705": 'Sales data comparison',
  "title706": 'Cost data comparison',
  "title707": 'Comparative detail data',
  "title708": 'Latest exported data',
  "title709": 'Report name',
  "title710": 'Processing',
  "title711": 'Processing',
  "title712": 'Sales data',
  "title713": 'Sales Data',
  "title714": 'Cost Data',
  "title715": 'units',
  "title716": 'Cost of Merchant SKU',
  "title717": 'Ad Cost',
  "title718": 'Packaging Fee',
  "title719": 'Evaluation Expenses',
  "title720": 'Seller Discounts',
  "title721": 'Shipping Expenses',
  "title722": 'Platform Fee',
  "title723": 'Other Platform Fees',
  "title724": 'Select at least one store',
  "title725": 'Individual',
  "title726": 'Shop period comparison-',
  "title727": 'Confirm export of cost composition overview',
  "title728": 'Cost composition overview',
  "title729": 'Confirm order',
  "title730": 'Order Details',
  "title731": 'Order service',
  "title732": 'Single',
  "title733": 'Review Insights',
  "title734": 'Time',
  "title735": 'Point',
  "title736": 'Purchase',
  "title737": 'Quantity',
  "title738": 'Duration',
  "title739": 'Total package',
  "title740": 'Package quantity',
  "title741": 'Package duration',
  "title742": 'Expire Date',
  "title743": 'Unit',
  "title744": 'I have read and accept',
  "title745": 'Amount to be paid',
  "title746": 'I have read and accept',
  "title747": 'User Terms of Service',
  "title748": 'Submit Order',
  "title749": 'Pay Immediately',
  "title750": 'Amount to be paid',
  "title751": 'Remaining Payment Time',
  "title752": 'Unpaid orders will be automatically canceled if they expire',
  "title753": 'Open your phone',
  "title754": 'Alipay',
  "title755": 'WeChat',
  "title756": 'Scan to continue payment',
  "title757": 'Payment method: Bank Transfer (Do not close the current page before transferring, click "Payment Completed" after successful transfer)',
  "title758": 'Receiver Company Name:',
  "title759": 'Receiver Bank Account:',
  "title760": 'Bank Name:',
  "title761": 'China Construction Bank Co., Ltd. Fuzhou Shida Branch',
  "title762": 'Payment completed',
  "title763": 'Payment Success',
  "title764": 'You can view it in [Personal Center] - [My Orders].',
  "title765": 'Estimated time of crediting is 5-10 minutes, please wait a moment',
  "title766": 'You can view it in [Personal Center] - [My Orders].',
  "title767": 'Order Number',
  "title768": 'Alipay Payment',
  "title769": 'Bank Transfer',
  "title770": 'User Analysis Overview',
  "title771": 'Repurchase Rate',
  "title772": 'Compared to Previous Period',
  "title773": 'Sales Performance',
  "title774": 'Valid Order Quantity',
  "title776": 'Sales Per Customers',
  "title777": 'New User',
  "title778": 'Returning User',
  "title779": 'User List',
  "title780": 'Enter Search Information',
  "title781": 'Buyer Payment Amount',
  "title782": 'Customer List',
  "title783": 'Buyer Information',
  "title784": 'Last Order Time',
  "title785": 'Purchase records',
  "title786": 'User [',
  "title787": 'Product records',
  "title788": 'SKU Info',
  "title790": 'Order records',
  "订单号": 'Order No.',
  "title792": 'Product categories',
  "title793": 'Order Time',
  "title794": 'Through',
  "title795": 'Search users',
  "title796": 'Yes',
  "title797": 'No',
  "title798": 'Last 15 Days',
  "title799": 'Total Users',
  "title800": 'Repeat Order Count',
  "title801": 'Last 7 days',
  "title802": 'Date',
  "title803": 'Confirm exporting all data items?',
  "title804": 'Store Sales Data',
  "title805": 'Comprehensive Data',
  "title806": 'Time Comparison',
  "title807": 'Indicator Comparison',
  "title808": 'Current Period',
  "title809": 'Period-on-Period Cycle',
  "title810": 'Period-on-Period Difference',
  "title811": 'By hour',
  "title812": 'Timely statistics refer to analyzing the daily 24-hour data performance of the store.',
  "title813": 'Composition of Product Costs',
  "title814": 'Cost vs. Effective Sales Comparison',
  "title815": 'Inventory Report',
  "title816": 'All Warehouses',
  "title817": 'Third-Party  Warehouses',
  "title818": 'Own Warehouse',
  "title819": 'Inventory value',
  "title820": 'Inventory Aging',
  "title821": 'Duration',
  "title822": 'Ratio',
  "title823": 'Order Area Distribution',
  "title824": 'Please select area',
  "title825": 'Return ',
  "title826": 'city',
  "title827": 'Valid Order Quantity',
  "title828": 'Returned Order Quantity',
  "title829": 'Proportion of Effective Order Quantity',
  "title830": 'Proportion of Returned Order Quantity',
  "title831": 'Available Stock',
  "title832": 'Allocated',
  "title833": 'On The Way',
  "title834": 'Exceeds',
  "title835": 'by store',
  "title836": 'By Site',
  "title837": 'By region',
  "title838": 'Total Sales',
  "title839": 'Total sales',
  "title840": 'Total Order',
  "title841": 'Evaluation Loss',
  "title842": 'Total Quantity',
  "title843": 'Total Inventory Value',
  "title844": 'Total Product Cost',
  "title845": 'Account Info',
  "title846": 'Basic Information',
  "title847": 'Login logs',
  "title848": 'Subscription information',
  "title849": 'My package',
  "title850": 'My orders',
  "title851": 'My account',
  "title852": 'Account',
  "title853": 'Password',
  "title854": 'Set password',
  "title855": 'Name',
  "title856": 'Phone number',
  "title857": 'Change phone',
  "title858": 'Login time',
  "title859": 'Login account',
  "title860": 'Assigned role',
  "title861": 'Phone',
  "title862": 'Login address',
  "title863": 'Your package quota has been used up, please purchase a new package to continue service.',
  "title864": 'Buy now',
  "title865": 'Package status',
  "title866": 'Current Version',
  "title867": 'term of Validity',
  "title868": 'Remaining orders:',
  "title869": 'Purchase package',
  "title870": 'Basic rights',
  "title871": 'Shop Number',
  "title872": 'Sub-Accounts',
  "title873": 'Points',
  "title874": 'Package name',
  "title875": 'Remaining orders',
  "title876": 'Remaining time',
  "title877": 'Purchase time',
  "title878": 'Status',
  "title879": 'Note',
  "title880": 'Refueling package rights',
  "title881": 'Refueling package type',
  "title882": 'Purchase content',
  "title883": 'Refueling package quantity',
  "title884": 'Remaining Quantity',
  "title885": 'Remaining Days',
  "title886": 'Order Type',
  "title887": 'please Enter Order Number',
  "title888": 'Transaction Time',
  "title889": 'Order content',
  "title890": 'Refueling package',
  "title891": 'Payment status',
  "title892": 'Pay now',
  "title893": 'cancel orders',
  "title894": 'Please contact customer service',
  "title895": 'Invoice ',
  "title896": 'Paid Time',
  "title897": "Subscription",
  "title898": 'Unpaid',
  "title899": 'Payment success',
  "title900": 'Payment closed',
  "title901": 'Confirm to cancel order?',
  "title902": 'Order canceled successfully',
  "title903": 'Upload successful',
  "title904": 'Select status',
  "title905": 'Store Health Metrics',
  "title906": 'Underperforming store',
  "title907": 'Average score this quarter',
  "title908": 'Average penalty points this quarter for the selected stores. Points accumulated will remain on record till the end of a quarter. ',
  "title909": ' points will be reset on the first Monday of each quarter (Jan, Apr, Jul, Oct). Move the mouse into the points to view the average points of important metrics.',
  "title910": 'Indicators',
  "title911": 'average',
  "title912": 'Points',
  "title913": 'Violating listing regulations',
  "title914": 'Severe Listing Violations',
  "title915": "Pre-sale Product Quantity",
  "title916": "Other Violations",
  "title917": "Order Completion Rate",
  "title918": "Order Incompletion Rate",
  "title919": "Late Shipment Rate",
  "title920": 'Preparation Time',
  "title921": 'Customer Service Satisfaction-',
  "title922": 'Response Rate',
  "title923": "Buyer Feedback",
  "title924": "Average Score of Key Metrics",
  "title925": 'Store Warning',
  "title926": 'Ongoing Punishment',
  "title927": 'Excellent! You have no store warning.',
  "title928": 'Store Health Status',
  "title929": 'Store authorization expired, please',
  "title930": 'Reauthorize',
  "title10930": 'This store authorization is expired, please reauthorize',
  "title931": 'Penalty Points This Quarter',
  "title932": 'Punishment Warning Tier',
  "title933": 'The warning will be triggered when there is only 1 point remaining from points that trigger the punishment, which means trigger warning at 2, 5, 8, 11, 14 points',
  "title934": 'No warning',
  "title935": 'Level',
  "title936": 'Store penalty score warning distribution',
  "title937": ' no warning',
  "title938": 'Your store is currently in good health with no penalties',
  "title939": 'Punishment',
  "title940": 'Punishment Tier',
  "title941": 'Remaing Days of Punishment',
  "title942": 'Store Punishment Tier',
  "title943": 'Store situation',
  "title944": 'Update data',
  "title945": 'List item settings',
  "title946": 'Overall store health assessment tier. Excellent, Good, Improvement Needed, Poor.',
  "title947": 'Penalty Points This Quarter (points)',
  "title948": 'Points accumulated will remain on record till the end of a quarter. This will be reset on the first Monday of each quarter (Jan, Apr, Jul, Oct).',
  "title949": 'Non-fulfilment Violations (points)',
  "title950": 'Penalty points for non-fulfilment due to order cancellation or return',
  "title951": 'Late Shipment Violations (points)',
  "title952": 'Penalty points for order late shipment',
  "title953": 'Penalty points for listing violations',
  "title954": 'Other Violations (points)',
  "title955": 'Penalty points for other violations',
  "title956": 'Penalty Points This Quarter (points)',
  "title957": "Listings will be deleted if they constitute a serious violation of Shopee's policies",
  "title958": 'My Store',
  "title959": 'Target',
  "title960": 'Penalty Point(s)',
  "title961": 'Spam Listings',
  "title962": 'Attempts to manipulate search results to gain an unfair advantage will be penalized. This includes actions such as duplicate products, keyword spam, and misleading price, etc.',
  "title963": 'Counterfeit/IP infringement',
  "title964": 'Counterfeit/IP infringement',
  "title965": 'Prohibited Listings',
  "title966": 'Products that are not allowed on ShopProducts that are not allowed on ShopeeeeProducts that are not allowed on Shopee',
  "title967": "Listed Items",
  "title968": 'Pre-order Listings',
  "title969": 'Pre-order Listings',
  "title970": 'Live pre-order listings as a percentage of total live listings',
  "title971": 'Days of Pre-order Listing Violation',
  "title972": 'Target',
  "title973": 'The number of days in the last 30 days where pre-order listing precentage exceeds target',
  "title974": 'Other Rules',
  "title975": 'Other Listing Violations',
  "title976": 'Other listing regulations set by the platform, violation may incur penalty. Such as incorrect product categories, low-quality images, etc.',
  "title977": 'The percentage of orders that were either cancelled or returned in the past 7 days',
  "title978": ' Cancellation Rate',
  "title979": 'The percentage of orders cancelled in the past 7 days',
  "title980": 'Cancellation Rate',
  "title981": 'The percentage of orders that were requested for a return-refund in the past 7 days',
  "title982": 'Return-refund Rate',
  "title983": 'The percentage of orders that were shipped late in the past 7 days',
  "title984": 'The number of days it takes a seller to prepare and ship out an order',
  "title985": 'Customer Service',
  "title986": ' Satisfaction',
  "title987": 'The percentage of new chats and offers that a seller responds to within 12 hours of receiving them',
  "title988": 'Response Time',
  "title989": "The average time it takes a seller to respond to a buyer's chat message",
  "title990": 'Shop Rating',
  "title991": 'The average of all order ratings submitted by your buyers',
  "title992": 'Penalty Points This Quarter-Penalty Points This Quarter (points)',
  "title993": 'Penalty Points This Quarter-Non-fulfilment Violations (points）',
  "title994": 'Penalty Points This Quarter-Late Shipment Violations (points)',
  "title995": 'Penalty Points This Quarter-',
  "title996": 'Penalty Points This Quarter-Other Violations (points)',
  "title997": 'Severe Listing Violations-Severe Listing Violations',
  "title998": 'Severe Listing Violations-Spam Listings',
  "title999": 'Severe Listing Violations-Counterfeit/IP infringement',
  "title1000": 'Severe Listing Violations-Prohibited Listings',
  "title1001": 'Pre-order Listings-Pre-order Listings',
  "title1002": 'Pre-order Listings-Days of Pre-order Listing Violation',
  "title1003": 'Pre-order Listings-Other Listing Violations',
  "title1004": 'Fulfilment-fulfilment Rate',
  "title1005": 'Fulfilment-Cancellation Rate',
  "title1006": 'Fulfilment-Return-refund Rate',
  "title1007": 'Fulfilment-Late Shipment Rate',
  "title1008": 'Fulfilment-Preparation Time',
  "title1009": 'Customer Service Satisfaction-Response Rate',
  "title1010": 'Customer Service Satisfaction-Response Time',
  "title1011": 'Customer Service Satisfaction-Shop Rating',
  "title1012": 'Restore default',
  "title1013": 'Confirm',
  "title1014": 'Excellent',
  "title1015": 'Good',
  "title1016": 'Improvement Needed',
  "title1017": 'Repayment exchange rate cannot be empty',
  "title1018": 'Data update successful',
  "title1019": 'Confirm reauthorize the store',
  "title1020": 'Are you sure to export store situation',
  "title1021": 'Store situation-',
  "title1022": 'Subscription Service',
  "title1023": 'Current package',
  "title1024": 'Free Version',
  "title1025": 'Package is valid for 1 year.',
  "title1026": 'Usage of single units begins to be calculated from the start of the package, until the single units are used up or exceed the expiration date.',
  "title1027": 'If you need offline implementation services or online demo assistance, please contact our customer service team.',
  "title1028": 'Year',
  "title1029": 'Number of accounts',
  "title1030": "流程表单",
  "title1031": "表单名",
  "title1032": "请输入表单名",
  "title1033": 'Activation status',
  "title1034": 'Please enter a note',
  "title1035": 'Save',
  "title1036": 'View',
  "title1037": 'Clear',
  "title1038": "从左侧拖入或点选组件进行表单设计",
  "title1039": "输入型组件",
  "title1040": "选择型组件",
  "title1041": "布局型组件",
  "title1042": "表单名不能为空",
  "title1043": "开启状态不能为空",
  "title1044": 'Success',
  "title1045": "代码已复制到剪切板，可粘贴。",
  "title1046": "代码复制失败",
  "title1047": "确定要清空所有组件吗？",
  "title1048": "提示",
  "title1049": "工作流",
  "title1050": "编号",
  "title1051": "表单详情",
  "title1052": "是否确认删除工作表单的编号为",
  "title1053": "定义编号",
  "title1054": "定义名称",
  "title1055": "定义分类",
  "title1056": "表单信息",
  "title1057": "暂无表单",
  "title1058": "流程版本",
  "title1059": "未部署",
  "title1060": "激活",
  "title1061": "挂起",
  "title1062": "部署时间",
  "title1063": "定义描述",
  "title1064": "分配规则",
  "title1065": "流程图",
  "title1066": "组名",
  "title1067": "请输入组名",
  "title1068": 'Please select a status',
  "title1069": 'Description',
  "title1070": 'Members',
  "title1071": 'Please enter a description',
  "title1072": 'Please select members',
  "title1073": 'Group name cannot be empty',
  "title1074": 'Description cannot be empty',
  "title1075": 'Members cannot be empty',
  "title1076": 'Status cannot be empty',
  "title1077": 'Add user group',
  "title1078": 'Modify user group',
  "title1079": "Are you sure you want to delete user group with the group number",
  "title1080": 'Unknown',
  "title1081": 'Save successful',
  "title1082": "流程标识",
  "title1083": "请输入流程标识",
  "title1084": "流程名称",
  "title1085": "请输入流程名称",
  "title1086": "流程分类",
  "title1087": "新建流程",
  "title1088": "导入流程",
  "title1089": "最新部署的流程定义",
  "title1090": "激活状态",
  "title1091": "修改流程",
  "title1092": "设计流程",
  "title1093": "发布流程",
  "title1094": "流程定义",
  "title1095": "请输入流标标识",
  "title1096": "新建后，流程标识不可修改！",
  "title1097": "流程标识不可修改！",
  "title1098": "请选择流程分类",
  "title1099": "流程描述",
  "title1100": "表单类型",
  "title1101": "表单提交路由",
  "title1102": "请输入表单提交路由",
  "title1103": "自定义表单的提交路径，使用",
  "title1104": "的路由地址，例如说：",
  "title1105": "表单查看路由",
  "title1106": "请输入表单查看路由",
  "title1107": "自定义表单的查看路径，使用",
  "title1108": "将文件拖到此处，或",
  "title1109": 'Click to upload',
  "title1110": "提示：仅允许导入“",
  "title1111": 'Or',
  "title1112": "格式文件！",
  "title1113": "流程标识不能为空",
  "title1114": "流程名称不能为空",
  "title1115": "业务表单不能为空",
  "title1116": "流程分类不能为空",
  "title1117": "表单提交路由不能为空",
  "title1118": "表单查看路由不能为空",
  "title1119": "新建模型",
  "title1120": "修改模型",
  "title1121": "修改模型成功",
  "title1122": "新建模型成功！",
  "title1123": "后续需要执行如下",
  "title1124": "个步骤：",
  "title1125": "点击【修改流程】按钮，配置流程的分类、表单信息",
  "title1126": "点击【设计流程】按钮，绘制流程图",
  "title1127": "点击【分配规则】按钮，设置每个用户任务的审批人",
  "title1128": "点击【发布流程】按钮，完成流程的最终发布",
  "title1129": "另外，每次流程修改后，都需要点击【发布流程】按钮，才能正式生效！！！",
  "title1130": "重要提示",
  "title1131": "是否删除该流程！！",
  "title1132": "是否部署该流程！！",
  "title1133": "部署成功",
  "title1134": "是否确认",
  "title1135": "流程名字为",
  "title1136": "导入流程成功！请点击【设计流程】按钮，进行编辑保存后，才可以进行【发布流程】",
  "title1137": "选择",
  "title1138": "申请信息【",
  "title1139": "选择其它流程",
  "title1140": "发起流程成功",
  "title1141": "审批任务【",
  "title1142": "流程名",
  "title1143": "流程发起人",
  "title1144": "审批建议",
  "title1145": "请输入审批建议",
  "title1146": "不通过",
  "title1147": "转办",
  "title1148": "委派",
  "title1149": "退回",
  "title1150": "点击查看",
  "title1151": "审批记录",
  "title1152": "任务：",
  "title1153": "审批人：",
  "title1154": "创建时间：",
  "title1155": "审批时间：",
  "title1156": "耗时：",
  "title1157": "转派审批人",
  "title1158": "新审批人",
  "title1159": "审批建议不能为空",
  "title1160": "新审批人不能为空",
  "title1161": "未传递",
  "title1162": "参数，无法查看流程信息",
  "title1163": "查询不到流程信息！",
  "title1164": "审批通过成功！",
  "title1165": "审批不通过成功！",
  "title1166": "转派任务成功！",
  "title1167": "暂不支持【委派】功能，可以使用【转派】替代！",
  "title1168": "暂不支持【退回】功能！",
  "title1169": "请输入流程名",
  "title1170": "所属流程",
  "title1171": "请输入流程定义的编号",
  "title1172": "提交时间",
  "title1173": "结果",
  "title1174": "请选择流结果",
  "title1175": "发起流程",
  "title1176": "当前审批任务",
  "title1177": "结束时间",
  "title1178": "请输入取消原因？",
  "title1179": "取消流程",
  "title1180": "取消原因不能为空",
  "title1181": "取消成功",
  "title1182": "任务编号",
  "title1183": "任务名称",
  "title1184": "审批意见",
  "title1185": "审批时间",
  "title1186": "耗时",
  "title1187": "审批",
  "title1188": "任务分配规则",
  "title1189": "任务名",
  "title1190": "任务标识",
  "title1191": "规则类型",
  "title1192": "规则范围",
  "title1193": "修改任务规则",
  "title1194": "指定角色",
  "title1195": "指定部门",
  "title1196": "请选择指定部门",
  "title1197": "指定岗位",
  "title1198": "指定用户",
  "title1199": "指定用户组",
  "title1200": "指定脚本",
  "title1201": "规则类型不能为空",
  "title1202": "指定角色不能为空",
  "title1203": "指定部门不能为空",
  "title1204": "指定岗位不能为空",
  "title1205": "指定用户不能为空",
  "title1206": "指定用户组不能为空",
  "title1207": "指定脚本不能为空",
  "title1208": 'Sales Amount Data Comparison',
  "title1209": 'Order Data Comparison',
  "title1210": 'Traffic Data Comparison',
  "title1211": 'Advertising Conversion Data',
  "title1212": 'Platform Comments',
  "title1213": 'Please Enter Platform Comment',
  "title1214": 'Platform Products',
  "title1215": 'Please Enter Platform Product',
  "title1216": 'Please Enter Product',
  "title1217": 'Comment Time',
  "title1218": 'Comment',
  "title1219": 'Buyer',
  "title1220": 'Video',
  "title1221": 'Review Content',
  "title1222": 'Seller Reply',
  "title1223": 'Rating',
  "title1224": 'Enter Buyer',
  "title1225": 'Enter Video',
  "title1226": 'Enter Review Content',
  "title1227": 'Enter Seller Reply',
  "title1228": 'Enter Rating',
  "title1229": 'Select Review Time',
  "title1230": 'Cannot be empty',
  "title1231": 'Order Number Cannot be Empty',
  "title1232": 'Review Time Cannot be Empty',
  "title1233": 'Add Product Review',
  "title1234": 'Edit Product Review',
  "title1235": 'Are you sure to delete the product comment with the number',
  "title1236": 'Are you sure to export all product comment data items',
  "title1237": 'Product Comments',
  "title1238": 'Enter Custom Cost',
  "title1239": 'Cost Modification Records',
  "title1240": 'Batch Import Costs',
  "title1241": 'Import SKU Cost',
  "title1242": 'Import Historical Costs',
  "title1243": 'Add Custom Cost',
  "title1244": 'Batch Import Custom Costs',
  "title1245": 'Batch Delete',
  "title1246": 'please select',
  "title1247": 'Sales Status',
  "title1248": 'Product Cost Status',
  "title1249": 'Expense Type',
  "title1250": 'Export Product Cost',
  "title1251": 'active',
  "title1252": 'stop selling',
  "title1253": 'With Product Cost',
  "title1254": 'No Product Cost',
  "title1255": 'Historical Cost',
  "title1256": 'Product Cost Import Template',
  "title1257": 'Custom Cost Import Template',
  "title1258": 'Import Custom Cost',
  "title1259": 'Custom Cost Table',
  "title1260": 'Warm Prompt',
  "title1261": 'Are you sure to export',
  "title1262": 'Product Cost Details -',
  "title1263": 'Open',
  "title1264": 'Top',
  "title1265": 'Switch Currency',
  "title1266": 'Page Views',
  "title1268": 'Ad Visitors',
  "title1269": 'Organic Visitors',
  "title1270": 'Likes',
  "title1271": 'Like Rate',
  "title1272": 'Add to Cart',
  "title1273": 'Add to Cart Conversion Rate',
  "title1274": 'Ad Sales',
  "title1275": 'Organic Sales',
  "title1276": 'Organic Sales Amount',
  "title1277": 'Conversion Rate',
  "title1278": 'Advertising Cost',
  "title1279": 'Data error, please contact the administrator',
  "title1280": 'Top successful',
  "title1281": 'Cancel Top successful',
  "title1282": 'Organic Order Proportion',
  "title1283": 'Are you sure you want to export all warehouse records',
  "title1285": 'Advertising traffic analysis can collect and analyze store product traffic and conversion rate data, allowing you to promptly view key metrics such as buyer visitation, conversion rate, sales volume, sales revenue, and more, to understand the performance of products in operation.',
  "title1286": 'Please enter product attribute search',
  "title1287": 'Advertisement category',
  "title1288": 'Advertisement clicks',
  "title1289": 'Sales',
  "title1290": 'Details',
  "title1291": 'Advertisement details',
  "title1292": 'Cancel top',
  "title1293": 'Product name',
  "title1294": 'Confirm export all products',
  "title1295": 'Data item',
  "title1296": 'Diagnostic data',
  "title1297": 'Past',
  "title1298": 'Store information',
  "title1299": 'Group',
  "title1301": 'MoM effective sales',
  "title1302": 'Profit/Loss',
  "title1303": 'Please select search type',
  "title1304": 'Please enter search content',
  "title1305": 'Select time',
  "title1306": 'Reviews',
  "title1307": 'Marketing indicators',
  "title1308": 'Number of reviews:',
  "title1309": 'Total score:',
  "title1310": 'Key selling points',
  "title1311": 'Competitor information',
  "title1312": 'Core keyword records',
  "title1313": 'Product status',
  "title1314": 'Off shelf',
  "title1315": 'On shelf',
  "title1316": 'Product reviews',
  "title1317": 'User name',
  "title1318": 'Order number:',
  "title1319": 'Product data details',
  "title1320": 'View trend chart',
  "title1321": 'Total visitors',
  "title1322": 'Total orders',
  "title1323": 'Keyword ad visitors',
  "title1324": 'Keyword click-through rate',
  "title1325": 'Keyword ad volume',
  "title1326": 'Keyword conversion rate',
  "title1327": 'Average cost per order',
  "title1328": 'Keyword',
  "title1329": 'Keyword advertisement',
  "title1330": 'Associated advertisement visitors',
  "title1331": 'Associated advertisement orders',
  "title1332": 'Associated advertisement',
  "title1333": 'Organic visitors',
  "title1334": 'Organic click-through rate',
  "title1335": 'Organic orders',
  "title1336": 'Organic conversion rate',
  "title1337": 'Product data trend analysis chart',
  "title1338": 'Click label to open or close the curve',
  "title1339": 'Selling point remarks',
  "title1340": 'Link',
  "title1341": "Competitor's hot - selling ",
  "title1342": 'Price',
  "title1343": "Competitor's monthly sales volume ",
  "title1344": 'Local keywords',
  "title1345": 'Chinese translation',
  "title1346": 'Search volume',
  "title1347": 'Please enter selling points',
  "title1348": "Competitor's link",
  "title1349": "Please enter competitor's link",
  "title1350": "Please enter competitor's hot - selling",
  "title1351": "Please enter competitor's monthly sales volume",
  "title1352": 'Please enter local keywords',
  "title1353": 'Keyword automatic translation',
  "title1354": 'Please enter keyword search volume',
  "title1355": 'Key selling points note',
  "title1356": 'Add key selling points note',
  "title1357": 'Competitor link records',
  "title1358": 'Add competitor link',
  "title1359": 'Add core keywords',
  "title1360": 'Online product name',
  "title1361": 'Product',
  "title1362": 'Off the shelves',
  "title1363": 'On the shelves',
  "title1364": ' Pilih Jangka Waktu',
  "title1365": 'Silakan masukkan catatan penjualan poin',
  "title1366": 'Biaya rata-rata per pesanan (kata kunci)',
  "title1367": 'Biaya per pesanan (iklan terkait)',
  "title1368": 'Bulan',
  "title1369": 'Apakah Anda yakin untuk mengekspor semua item data pesanan?',
  "title1370": 'Versi upgrade koper 23 inci hitam tebal hitam tebal hitam',
  "title1371": 'Masukkan pencarian kata kunci',
  "title1372": 'Tampilkan posisi',
  "title1373": 'Konversi',
  "title1374": 'Barang telah terjual',
  "title1375": 'Pengaturan kondisi',
  "title1377": 'Aturan diagnostik',
  "title1378": 'Silakan pilih waktu',
  "title1379": 'Jam',
  "title1380": 'Pengaturan belum selesai',
  "title1381": 'Lebih besar dari',
  "title1382": 'Lebih besar dari atau sama dengan',
  "title1383": 'Kurang dari',
  "title1384": 'Kurang dari atau sama dengan',
  "title1385": 'Bulan mulai',
  "title1386": 'Bulan berakhir',
  "title1387": 'Data dasar',
  "title1388": 'Jumlah Pelanggan',
  "title1389": 'Jumlah Pembatalan',
  "title1390": 'Trend changes',
  "title1391": 'Pesanan Dibatalkan',
  "title1392": 'Last 14 days',
  "title1393": 'Bulan',
  "title1394": 'Last month',
  "title1395": 'Enam bulan terakhir',
  "title1396": '1 tahun terakhir',
  "title1397": '3 bulan terakhir',
  "title1398": '6 bulan terakhir',
  "title1399": '1 tahun terakhir',
  "title1400": 'Per tahun',
  "title1401": 'Sehari sebelumnya',
  "title1402": 'Tambahkan tautan produk',
  "title1403": 'Rincian Produk',
  "title1404": 'Marketplace',
  "title1405": 'Waktu analisis',
  "title1406": 'Operasi time out, silakan diulang analisis',
  "title1407": 'Analisis selesai',
  "title1408": 'Analisis sedang berlangsung',
  "title1409": 'Analisis ulang',
  "title1410": 'Laporan Analitika',
  "title1411": 'Tautan produk',
  "title1412": 'Masukkan tautan produk online untuk menganalisis konten komentar, hanya bisa menambahkan satu tautan produk pada satu waktu.',
  "title1413": 'Masukkan tautan produk',
  "title1414": 'Seperti:',
  "title1415": 'Nama produk',
  "title1416": 'Nomor produk',
  "title1417": 'Silakan masukkan tautan produk',
  "title1418": 'Silakan masukkan alamat yang valid',
  "title1419": 'Ditambahkan dengan sukses',
  "title1420": 'Diperbarui dengan sukses',
  "title1421": 'Konfirmasi penghapusan?',
  "title1422": 'Harga:',
  "title1423": 'Total volume penjualan:',
  "title1424": 'Volume penjualan bulanan:',
  "title1425": 'Peringkat bintang:',
  "title1426": 'Tingkat retensi ulasan:',
  "title1427": 'Waktu penjualan:',
  "title1428": 'Buka tautan toko',
  "title1429": 'Gambaran umum',
  "title1430": 'Keuntungan',
  "title1431": 'Kekurangan',
  "title1432": 'Harapan pelanggan',
  "title1433": 'Motivasi pembelian',
  "title1434": 'Variasi yang sedang dijual saat ini:',
  "title1435": 'potong, variasi dengan ulasan historis',
  "title1436": 'potong, daftar variasi dengan ulasan sebagai berikut:',
  "title1437": 'Variasi',
  "title1438": 'Jumlah komentar',
  "title1439": 'Proporsi komentar',
  "title1440": 'Lihat komentar',
  "title1441": 'Alasan',
  "title1442": 'Proporsi kategori saat ini',
  "title1443": 'Proporsi total ulasan produk saat ini',
  "title1444": 'Lihat komentar pembeli',
  "title1445": 'Daftar komentar',
  "title1446": 'Masukkan konten pencarian',
  "title1447": 'Peringkat bintang',
  "title1448": 'Jenis komentar',
  "title1449": 'Konten terjemahan',
  "title1450": 'Unduh komentar',
  "title1451": 'Bintang',
  "title1452": 'Teks',
  "title1453": 'Lainnya',
  "title1454": 'Konfirmasi ekspor ulasan produk',
  "title1455": 'Ulasan produk',
  "title1456": 'Pilih jenis pencarian',
  "title1457": 'Pendapatan penjualan produk',
  "title1458": 'Penilaian toko',
  "title1459": 'Efisiensi pengiriman',
  "title1460": 'Tingkat keterlambatan pengiriman',
  "title1461": 'Tingkat ketidaklengkapan',
  "title1462": 'Waktu tanggapan',
  "title1463": 'Poin denda',
  "title1464": 'Ulasan baru ditambahkan hari ini',
  "title1465": "林明芳",
  "title1466": 'Pengguna',
  "title1467": 'Harap masukkan pengguna',
  "title1468": 'Nama diagnosis',
  "title1469": 'Harap masukkan nama diagnosis',
  "title1470": 'Jenis diagnosis',
  "title1471": 'Harap pilih jenis diagnosis',
  "title1472": 'Jenis pengingat',
  "title1473": 'Harap pilih jenis pengingat',
  "title1474": 'Nomor auto-increment',
  "title1475": 'Deskripsi diagnosis',
  "title1476": 'Harap masukkan aturan diagnosis',
  "title1477": 'Nama diagnosis tidak boleh kosong',
  "title1478": 'Jenis diagnosis tidak boleh kosong',
  "title1479": 'Jenis pengingat tidak boleh kosong',
  "title1480": 'Tambah diagnosis data',
  "title1481": 'Ubah diagnosis data',
  "title1482": 'Apakah Anda yakin untuk menghapus nomor diagnosis data',
  "title1483": 'Apakah Anda yakin untuk mengekspor semua item diagnosis data',
  "title1484": 'Wawasan Data',
  "title1485": 'Ranking list',
  "title1486": 'Waktu Diperbarui：',
  "title1487": 'Peringkat harian',
  "title1488": 'Peringkat mingguan',
  "title1489": 'Peringkat bulanan',
  "title1490": 'Tautan online',
  "title1491": 'Informasi tautan',
  "title1492": 'Informasi',
  "title1493": 'Informasi akun',
  "title1494": 'Informasi platform',
  "title1495": 'Peringkat penjualan produk',
  "title1496": 'Peringkat margin keuntungan produk',
  "title1497": 'Peringkat tingkat pengembalian produk',
  "title1498": 'Peringkat kinerja',
  "title1499": 'Peringkat platform',
  "title1500": 'Peringkat toko',
  "title1501": 'Total penjualan',
  "title1502": 'Spesifikasi produk',
  "title1503": 'Judul',
  "title1504": 'Please enter name',
  "title1505": 'Nama Template',
  "title1506": 'Jenis Barcode',
  "title1507": 'Ukuran Label',
  "title1508": 'Lebar  ',
  "title1509": 'Tinggi',
  "title1510": 'Default',
  "title1511": 'Select barcode type',
  "title1512": 'Lebar  ',
  "title1513": 'Please enter width',
  "title1514": 'Tinggi',
  "title1515": 'Harap masukkan tinggi',
  "title1516": 'Konten definisi template',
  "title1517": 'Nama tidak boleh kosong',
  "title1518": 'Jenis barcode tidak boleh kosong',
  "title1519": 'Lebar tidak boleh kosong',
  "title1520": 'Tinggi tidak boleh kosong',
  "title1521": 'Apakah default tidak boleh kosong',
  "title1522": 'Tambahkan template label',
  "title1523": 'Ubah template label',
  "title1524": 'Apakah Anda yakin untuk menghapus nomor template label',
  "title1525": 'Apakah Anda yakin untuk mengekspor semua item data template label',
  "title1526": 'Template label',
  "title1527": 'Masukkan produk',
  "title1528": 'Lihat peringkat',
  "title1529": 'Harap masukkan nama platform',
  "title1530": 'Nama platform',
  "title1531": 'Ikon platform',
  "title1532": 'Tautan platform',
  "title1533": 'Harap masukkan tautan platform',
  "title1534": 'Nama platform tidak boleh kosong',
  "title1535": 'Ikon platform tidak boleh kosong',
  "title1536": 'Tautan platform tidak boleh kosong',
  "title1537": 'Tambah platform e-commerce',
  "title1538": 'Ubah platform e-commerce',
  "title1539": 'Konfirmasi penghapusan',
  "title1540": 'Apakah Anda yakin untuk mengekspor semua item data platform e-commerce',
  "title1541": 'Platform e-commerce',
  "title1542": 'Harap masukkan nama kategori',
  "title1543": 'Kategori',
  "title1544": 'Kategori induk',
  "title1545": 'Nama kategori',
  "title1546": 'Urutan tampilan',
  "title1547": 'Tingkat',
  "title1548": 'Harap masukkan kategori induk',
  "title1549": 'Harap masukkan urutan tampilan',
  "title1550": 'Harap masukkan tingkat',
  "title1551": 'Nama kategori tidak boleh kosong',
  "title1552": 'Urutan tampilan tidak boleh kosong',
  "title1553": 'Tingkat tidak boleh kosong',
  "title1554": 'Tambahkan kategori produk',
  "title1555": 'Ubah kategori produk',
  "title1556": 'Apakah Anda yakin untuk menghapus kategori produk',
  "title1557": 'Apakah Anda yakin untuk mengekspor semua item data kategori produk',
  "title1558": 'Kategori produk',
  "title1559": 'Anda memiliki',
  "title1560": 'Izin toko kedaluwarsa, harap',
  "title1561": 'Klik di sini untuk Integrasi Ulang',
  "title1562": 'Daftar Tugas',
  "title1563": 'Pesanan Baru',
  "title1564": 'Akan Kedaluwarsa',
  "title1565": 'Kekurangan Stok',
  "title1566": 'Chưa ghép nối',
  "title1567": 'Pesanan Ditolak',
  "title1568": 'Gagal Push Gudang Online',
  "title1569": 'Menunggu Pickup',
  "title1570": 'Pergudangan',
  "title1571": 'Stok Habis',
  "title1572": 'Di Bawah Stok Peringatan',
  "title1573": ' Stok Peringatan ',
  "title1574": 'Pembelian',
  "title1575": 'Saran Pembelian',
  "title1576": 'Rencana Pembelian',
  "title1577": 'Jumlah Toko yang Terintegrasi',
  "title1578": 'Toko yang Kedaluwarsa',
  "title1579": 'Jumlah Pengembalian Dana Pesanan',
  "title1580": 'Harap masukkan',
  "title1581": 'Pesaing',
  "title1582": 'Penjualan bulanan',
  "title1583": 'Harap masukkan tautan',
  "title1584": 'Harap masukkan harga',
  "title1585": 'Harap masukkan penjualan bulanan',
  "title1586": 'Tambah tautan pesaing',
  "title1587": 'Ubah tautan pesaing',
  "title1588": 'Apakah Anda yakin untuk menghapus nomor tautan pesaing',
  "title1589": 'Apakah Anda yakin untuk mengekspor semua item data tautan pesaing',
  "title1590": 'SKU Tunggal',
  "title1591": 'SKU Kombinasi',
  "title1592": 'Semua toko',
  "title1593": 'Edit',
  "title1594": 'Nama produk gabungan',
  "title1595": 'Silakan pilih produk',
  "title1596": 'Silakan pilih toko',
  "title1597": 'Produk tidak boleh kosong',
  "title1598": 'Toko tidak boleh kosong',
  "title1599": 'Tambahkan pasangan produk',
  "title1600": 'Apakah Anda yakin untuk mengekspor semua item data pasangan produk',
  "title1601": 'Pencocokan Barang',
  "title1602": 'Detail pemadanan',
  "title1603": 'Hapus',
  "title1604": 'Konfirmasi menghapus pasangan produk',
  "title1605": 'Silakan masukkan toko',
  "title1606": 'Silakan masukkan platform',
  "title1607": 'Silakan masukkan wilayah',
  "title1608": 'Baru-baru ini',
  "title1609": 'Silakan masukkan baru-baru ini',
  "title1610": 'Auto-increment',
  "title1611": 'Mata Uang ',
  "title1612": 'Nilai Tukar',
  "title1613": 'Jumlah Pengembalian Dana Pesanan',
  "title1614": 'Volume pesanan retur',
  "title1615": 'Volume pesanan dibatalkan',
  "title1616": 'Tingkat retur',
  "title1617": 'Perbandingan volume pesanan dibatalkan',
  "title1618": 'Tingkat pembatalan',
  "title1619": 'Perbandingan tingkat pembatalan',
  "title1620": 'Volume pesanan penilaian',
  "title1621": 'Perbandingan volume pesanan penilaian',
  "title1622": 'Perbandingan volume pesanan efektif',
  "title1623": 'Total Pesanan',
  "title1624": 'Pembayaran yang Dibayarkan oleh Pembeli',
  "title1625": 'MoM effective sales',
  "title1626": 'Ongkos Kirim Dibayar Oleh Pembeli',
  "title1627": 'MoM cost',
  "title1628": 'Other expenses',
  "title1629": 'Settlement amount',
  "title1630": 'Assessment settlement amount',
  "title1631": 'Assessment payment amount',
  "title1632": 'Assessment cost',
  "title1633": 'Assessment expenditure comparison',
  "title1634": 'Assessment payment exchange rate',
  "title1635": 'Commission',
  "title1636": 'Advertising expenses',
  "title1637": 'Return amount comparison',
  "title1638": 'Total Income',
  "title1639": 'Total Expense',
  "title1640": 'Total cost',
  "title1641": 'Estimated Profit',
  "title1642": 'Profit comparison',
  "title1643": 'Average profit',
  "title1644": 'Profit margin comparison',
  "title1645": 'Profit',
  "title1646": 'MoM cost profit margin',
  "title1647": 'Please enter currency',
  "title1648": 'Please enter exchange rate',
  "title1649": 'Please enter refund quantity',
  "title1650": 'Please enter MoM return order quantity',
  "title1651": 'Please enter cancellation quantity',
  "title1652": 'Please enter MoM return rate',
  "title1653": 'Please enter MoM cancellation quantity',
  "title1654": 'Please enter cancellation rate',
  "title1655": 'Please enter cancellation rate comparison',
  "title1656": 'Please enter assessment order volume',
  "title1657": 'Please enter assessment order volume comparison',
  "title1658": 'Please enter effective order volume',
  "title1659": 'Please enter effective order volume comparison',
  "title1660": 'Please enter effective sales volume',
  "title1661": 'Please enter effective sales volume comparison',
  "title1662": 'Please enter order amount',
  "title1663": 'Please enter actual payment amount by buyer',
  "title1664": 'Please enter effective sales revenue comparison',
  "title1665": 'Please Enter Shipping Fee Paid By Buyer',
  "title1666": 'Please enter total cost',
  "title1667": 'Please enter cost ratio',
  "title1668": 'Please enter other expenses',
  "title1669": 'Please enter shipping expenses',
  "title1670": 'Please enter packaging expenses',
  "title1671": 'Please enter settlement amount',
  "title1672": 'Please enter evaluation settlement amount',
  "title1673": 'Please enter evaluation payment amount',
  "title1674": 'Please enter evaluation cost',
  "title1675": 'Please enter evaluation expenses',
  "title1676": 'Please enter evaluation expenses ratio',
  "title1677": 'Please enter evaluation payment exchange rate',
  "title1678": 'Please enter platform commission',
  "title1679": 'Please enter advertising expenses',
  "title1680": 'Please enter return amount',
  "title1681": 'Please enter return amount ratio',
  "title1682": 'Please enter seller discounts',
  "title1683": 'Please enter total revenue',
  "title1684": 'Please enter total expenses',
  "title1685": 'Please enter total cost',
  "title1686": 'Please enter estimated profit',
  "title1687": 'Please enter profit ratio',
  "title1688": 'Please enter average profit',
  "title1689": 'Please enter profit margin',
  "title1690": 'Please enter profit margin ratio',
  "title1691": 'Please enter cost profit margin',
  "title1692": 'Please enter cost profit margin ratio',
  "title1693": 'Platform cannot be empty',
  "title1694": 'Region cannot be empty',
  "title1695": 'Day cannot be empty',
  "title1696": 'Currency cannot be empty',
  "title1697": 'Exchange rate cannot be empty',
  "title1698": 'Refund quantity cannot be empty',
  "title1699": 'MOM return order quantity cannot be empty',
  "title1700": 'Cancellation quantity cannot be empty',
  "title1701": 'MOM return rate cannot be empty',
  "title1702": 'MOM cancellation quantity cannot be empty',
  "title1703": 'Cancellation rate cannot be empty',
  "title1704": 'MOM cancellation rate cannot be empty',
  "title1705": 'Evaluation quantity cannot be empty',
  "title1706": 'MOM evaluation quantity cannot be empty',
  "title1707": 'Effective order quantity cannot be empty',
  "title1708": 'MOM effective order volume cannot be empty',
  "title1709": 'Effective sales volume cannot be empty',
  "title1710": 'MOM effective sales volume cannot be empty',
  "title1711": 'Order amount cannot be empty',
  "title1712": "Buyer's actual payment amount cannot be empty",
  "title1713": 'MOM effective sales amount cannot be empty',
  "title1714": 'Buyer pays shipping fee cannot be empty',
  "title1715": 'Total product cost cannot be empty',
  "title1716": 'MOM cost comparison cannot be empty',
  "title1717": 'Other fees cannot be empty',
  "title1718": 'Shipping expenses cannot be empty',
  "title1719": 'Packaging costs cannot be empty',
  "title1720": 'Settlement amount cannot be empty',
  "title1721": 'Evaluation settlement amount cannot be empty',
  "title1722": 'Evaluation payment amount cannot be empty',
  "title1723": 'Evaluation cost cannot be empty',
  "title1724": 'Evaluation expenditure cannot be empty',
  "title1725": 'MOM evaluation expenditure cannot be empty',
  "title1726": 'Evaluation payment exchange rate cannot be empty',
  "title1727": 'Platform commission cannot be empty',
  "title1728": 'Advertising expenses cannot be empty',
  "title1729": 'Refund amount cannot be empty',
  "title1730": 'MOM refund amount comparison cannot be empty',
  "title1731": 'Seller discounts cannot be empty',
  "title1732": 'Total revenue cannot be empty',
  "title1733": 'Total expenditure cannot be empty',
  "title1734": 'Total cost cannot be empty',
  "title1735": 'Estimated profit cannot be empty',
  "title1736": 'MOM profit cannot be empty',
  "title1737": 'Average profit cannot be empty',
  "title1738": 'Profit margin cannot be empty',
  "title1739": 'MOM profit margin cannot be empty',
  "title1740": 'Cost-profit margin cannot be empty',
  "title1741": 'MOM cost-profit margin cannot be empty',
  "title1742": 'Add recent',
  "title1743": 'Profit in days goods',
  "title1744": 'Modify recent',
  "title1745": 'Confirm deletion of recent',
  "title1746": 'Days goods profit number is',
  "title1747": 'Confirm exporting all recent',
  "title1748": 'Days goods profit data items',
  "title1749": 'Data date',
  "title1750": 'Keyword ad singles',
  "title1751": 'Average cost per order (related ads)',
  "title1752": 'Select data date',
  "title1753": 'Please enter total visitors',
  "title1754": 'Please enter total orders',
  "title1755": 'Please enter keyword ad visitors',
  "title1756": 'Please enter keyword click-through rate',
  "title1757": 'Please enter keyword ad singles',
  "title1758": 'Please enter keyword conversion rate',
  "title1759": 'Please enter average cost per order',
  "title1760": 'Please enter keyword ad',
  "title1761": 'Please enter related ad visitors',
  "title1762": 'Please enter related ad singles',
  "title1763": 'Please enter related ad',
  "title1764": 'Please enter average cost per order (related ad)',
  "title1765": 'Please enter natural visitors',
  "title1766": 'Please enter natural click-through rate',
  "title1767": 'Please enter natural order quantity',
  "title1768": 'Please enter natural conversion rate',
  "title1769": 'Data date cannot be empty',
  "title1770": 'Add product data',
  "title1771": 'Modify product data',
  "title1772": 'Confirm deletion of product data number',
  "title1773": 'Confirm exporting all product data items',
  "title1774": 'Please enter Chinese translation',
  "title1775": 'Please enter search volume',
  "title1776": 'Add product keywords',
  "title1777": 'Modify product keywords',
  "title1778": 'Confirm deletion of product keyword number',
  "title1779": 'Confirm exporting all product keyword data items',
  "title1780": 'Product keywords',
  "title1781": 'Selling point',
  "title1782": 'Selling point content',
  "title1783": 'Add selling point note',
  "title1784": 'Modify selling point note',
  "title1785": 'Confirm deletion of selling point note number',
  "title1786": 'Confirm exporting all selling point note data items',
  "title1787": 'Bulk Actions',
  "title1788": 'Set sales status',
  "title1789": 'Edit group',
  "title1790": 'set group',
  "title1791": 'Import export',
  "title1792": 'Import new combination',
  "title1793": 'Export combined products',
  "title1794": 'Import Mapping',
  "title1795": 'Export pairing',
  "title1796": 'double-click to Bulk Search',
  "title1797": 'All groups',
  "title1798": 'Subproduct information',
  "title1799": 'Expand',
  "title1800": 'Paired product stores',
  "title1801": 'Expand paired',
  "title1802": 'Group',
  "title1803": 'Time',
  "title1804": 'Paired stores',
  "title1805": 'Please enter product name',
  "title1806": 'Please enter product',
  "title1807": 'Product grouping',
  "title1808": 'Product grouping settings',
  "title1809": 'Please select sales status',
  "title1810": 'Is it a combined product?',
  "title1811": 'Please select if it is a combined product',
  "title1812": 'Cancel selection',
  "title1813": 'Selected',
  "title1814": 'Bulk Search',
  "title1815": 'Please enter multiple',
  "title1816": 'Separate with line breaks',
  "title1817": 'For example',
  "title1818": 'Combination',
  "title1819": 'New product import',
  "title1820": 'Product import template',
  "title1821": 'Product pairing import',
  "title1822": 'Product pairing import template',
  "title1823": 'Subproduct',
  "title1824": 'Subproduct name',
  "title1825": 'Product name cannot be empty',
  "title1826": 'Combination of products cannot be empty',
  "title1827": 'Sales status cannot be empty',
  "title1828": 'Please select at least one product',
  "title1829": 'Settings saved successfully',
  "title1830": 'Please select a product operation',
  "title1831": 'Confirm deletion of the product? Deletion is irreversible and will also delete associated inventory and pairing relationships simultaneously',
  "title1832": 'Confirm export product pairing relation',
  "title1833": 'Add Combination SKU',
  "title1834": 'Modify combination product',
  "title1835": 'Sales status set successfully',
  "title1836": 'Please select subproduct',
  "title1837": 'Please enter subproduct quantity',
  "title1838": 'Confirm deletion of item as',
  "title1839": 'Add inventory',
  "title1840": 'Push Third-party Warehouse',
  "title1841": 'Set warehouse',
  "title1842": 'Set activity level',
  "title1843": 'Import new order',
  "title1844": 'Import update product',
  "title1845": 'Export product',
  "title1846": 'Activity level',
  "title1847": 'Product integrity',
  "title1848": 'Select all',
  "title1849": 'Selected',
  "title1850": 'All',
  "title1851": 'Warehouse',
  "title1852": 'Average profit margin',
  "title1853": 'Forecasted Daily Sales',
  "title1854": 'Inventory data (',
  "title1855": 'Paired stores',
  "title1856": 'Product weight',
  "title1857": 'Product dimensions',
  "title1858": 'Product logs',
  "title1859": 'Online sales data',
  "title1860": 'Select Warehouse',
  "title1861": 'Enter warehouse name',
  "title1862": 'Warehouse name',
  "title1863": 'Product import update',
  "title1864": 'Product update import template',
  "title1865": 'Best Seller',
  "title1866": 'Trending items',
  "title1867": 'Regular items',
  "title1868": 'Unsold items',
  "title1869": 'Please use the main account or admin account for product import and authorization',
  "title1870": 'please Select Warehouse',
  "title1871": 'Warehouse set up successfully',
  "title1872": 'Confirm deletion of item',
  "title1873": 'Deletion is irreversible and will also delete associated inventory and pairing relationships simultaneously',
  "title1874": 'Confirm product push',
  "title1875": 'Numbered as',
  "title1876": 'Push Successfully',
  "title1877": 'Basic Information',
  "title1878": 'Select category',
  "title1879": 'Supplier Source Link',
  "title1880": 'Enter source link',
  "title1881": 'copy link',
  "title1882": 'Product Source Link',
  "title1883": 'Enter source link',
  "title1884": "Product Description",
  "title1885": 'Insert image',
  "title1886": 'Note: Product description can insert up to 12 images',
  "title1887": '0 images inserted',
  "title1888": 'Product attributes',
  "title1889": 'Brand',
  "title1890": 'More Brands',
  "title1891": 'Sales Information',
  "title1892": "Product Type",
  "title1893": 'Single Variation',
  "title1894": 'Multiple Variations',
  "title1895": 'Special Price',
  "title1896": 'Select a promotion ',
  "title1897": ' Refresh ',
  "title1898": 'Enter inventory quantity',
  "title1899": 'Wholesale',
  "title1900": 'Add Wholesale Range',
  "title1901": 'Image media',
  "title1902": 'Product Image',
  "title1903": 'Select images',
  "title1904": 'Image format: Please upload file size not exceeding',
  "title1905": 'with format',
  "title1906": '; Image size must not be less than 100',
  "title1907": 'Add video',
  "title1908": 'Video format: Please upload file size not exceeding',
  "title1909": 'Duration of 10',
  "title1910": 'file',
  "title1911": 'Please publish the product within 7 days after uploading the video, otherwise the video will be automatically deleted.',
  "title1912": 'Packaging logistics',
  "title1913": 'Shipping Fee',
  "title1914": 'Enter shipping cost',
  "title1915": 'Enter product weight',
  "title1916": 'Length',
  "title1917": 'Height',
  "title1918": 'Pre-order',
  "title1919": 'Item condition',
  "title1920": 'New',
  "title1921": 'Second hand',
  "title1922": 'Smart generate product name',
  "title1923": 'Disclaimer: All content generated by this service is automatically generated by the model. We cannot guarantee its accuracy and completeness. The generated content is for reference only and does not represent the views and attitudes of the company. 新舰 will not be liable for any direct or indirect losses arising from the use of this service.',
  "title1924": 'Model generated automatically. We cannot guarantee its accuracy and completeness. The content generated is for reference only, and does not represent the views and attitudes of Xinjian. Xinjian will not be responsible for any direct or indirect losses arising from using this service.',
  "title1925": 'Stylish and generous',
  "title1926": 'Add new keywords',
  "title1927": 'Describe the image in your mind, keywords include brand, material, main features and usage scenarios, etc.',
  "title1928": 'Generated results language',
  "title1929": "Today's free usage times",
  "title1930": 'times, paid available',
  "title1931": 'Please click "Start Generation" on the left first, the generated results will be automatically displayed here.',
  "title1932": 'Serial number',
  "title1933": 'Apply',
  "title1934": 'Smart generate product description',
  "title1935": 'Generated results',
  "title1936": 'Please select a category',
  "title1937": 'Please enter keyword search',
  "title1938": 'Batch edit categories',
  "title1939": 'Set all selected items to the same category and attributes.',
  "title1940": 'Batch edit attributes',
  "title1941": 'A total of 3 products have been selected. Please edit their respective attributes.',
  "title1942": 'Batch edit images',
  "title1943": 'Each product supports up to 9 product images. Drag images to rearrange.',
  "title1944": 'Size chart',
  "title1945": 'Product image',
  "title1946": 'Draggable for sorting',
  "title1947": 'Local add',
  "title1948": 'Select space image',
  "title1949": 'Select collection image',
  "title1950": 'Add collection image',
  "title1951": 'Option 1',
  "title1952": 'Golden cake',
  "title1953": 'Option 2',
  "title1954": 'Double skin milk',
  "title1955": 'Option 3',
  "title1956": 'Oyster omelette',
  "title1957": 'Option 4',
  "title1958": 'Dragon beard noodles',
  "title1959": 'Option 5',
  "title1960": 'Peking duck',
  "title1961": '【Oversize】cat litter box',
  "title1962": 'Box: Exclusive private space for cats, making them healthier and happier!',
  "title1963": 'Other',
  "title1964": 'Select products',
  "title1965": 'Publish',
  "title1966": 'Add Merchant SKU',
  "title1967": 'Edit Categories',
  "title1968": 'Edit Attributes',
  "title1969": 'Add Watermark',
  "title1970": 'Before publishing, please be sure to edit the product information to avoid identical information with the original product, thus avoiding platform penalties.',
  "title1971": 'Child',
  "title1972": 'Expand All Variants',
  "title1973": 'Scheduled Publish" ',
  "title1974": 'Pushed, please check the push status later',
  "title1975": 'The current page only pushes products',
  "title1976": 'To third-party warehouses, only child products are pushed for bundled products',
  "title1977": 'If',
  "title1978": 'If the code is not in the third-party warehouse, this product will be created. If it exists, update this product.',
  "title1979": 'Different service providers, products',
  "title1980": 'There may be differences in naming standards, and naming that does not comply will result in failed pushes.',
  "title1981": 'Update information',
  "title1982": 'Push time',
  "title1983": 'Product information updated',
  "title1984": 'Sync Product',
  "title1985": 'Quickly generate matches',
  "title1986": 'Generate products',
  "title1987": 'Merge products',
  "title1988": 'Auto Mapping',
  "title1989": 'Set Mapping Rules',
  "title1990": 'remove mapping',
  "title1991": 'Is it paired?',
  "title1992": 'Is it on the shelves?',
  "title1993": 'Online product information',
  "title1994": 'Mapped',
  "title1995": 'Unmapped',
  "title1996": 'Informasi Produk Lokal',
  "title1997": 'Pasangkan',
  "title1998": 'Perbaiki Pasangan',
  "title1999": 'Tambahkan Aturan',
  "title2000": 'Urutan eksekusi aturan dari atas ke bawah.',
  "title2001": 'Masukkan Nama Aturan',
  "title2002": 'Ignore Prefix',
  "title2003": 'characters',
  "title2004": 'Number of digits',
  "title2005": 'Separator',
  "title2006": 'When multiple separators are found, use',
  "title2007": 'Take the leftmost',
  "title2008": 'Take the rightmost',
  "title2009": 'Ignore Suffix',
  "title2010": 'Do not ignore',
  "title2011": 'first',
  "title2012": 'last',
  "title2013": 'Replace characters',
  "title2014": 'Enter character',
  "title2015": 'Replace with',
  "title2016": 'Enter replacement character',
  "title2017": 'Ignore characters',
  "title2018": 'Enter ignored characters, one per line',
  "title2019": 'For example',
  "title2020": 'Ignore spaces',
  "title2021": 'Rule test',
  "title2022": 'Please enter product code',
  "title2023": 'Test',
  "title2024": 'Test results',
  "title2025": 'Test results',
  "title2026": 'Translation',
  "title2027": 'Merge and edit',
  "title2028": 'Merge',
  "title2029": 'And',
  "title2030": 'Save',
  "title2031": 'Save',
  "title2032": 'Category',
  "title2033": 'Default Warehouse',
  "title2034": 'Generation completed',
  "title2035": 'Synchronizing online products, please wait',
  "title2036": 'Confirm start synchronizing online products',
  "title2037": 'Estimated completion of online product synchronization in 1-3 minutes. Once synchronization is complete, you can conveniently view the updated product information.',
  "title2038": 'Automatically identify pairs',
  "title2039": 'Pairing rules',
  "title2040": 'Match【Online products',
  "title2041": 'And [Local products',
  "title2042": 'Completely identical',
  "title2043": 'According to online products',
  "title2044": 'Pairing, cannot match if online product is empty',
  "title2045": 'Ignore the prefix and suffix of 【Online products',
  "title2046": 'Identical',
  "title2047": 'Enter ignore content, separate multiple with commas',
  "title2048": 'Truncated 【Online products',
  "title2049": 'Truncate the',
  "title2050": 'Enter the number of characters to truncate',
  "title2051": 'to',
  "title2052": 'characters',
  "title2053": 'Ignore specified characters after 【Online products',
  "title2054": 'Specified character',
  "title2055": 'Enter specified content, separate multiple with commas',
  "title2056": 'Specify character',
  "title2057": 'Enter specified content, separate by comma',
  "title2058": 'Case insensitive',
  "title2059": 'Matching product range',
  "title2060": 'Current page products',
  "title2061": 'Filtered results all products',
  "title2062": 'Selected products',
  "title2063": 'Start',
  "title2064": 'Automatically identify pairing results',
  "title2065": 'Automatic pairing successful',
  "title2066": 'Products, confirm save',
  "title2067": 'Remove in batch',
  "title2068": 'Pairing local product information',
  "title2069": 'Remove',
  "title2070": 'Previous step',
  "title2071": 'Not listed yet',
  "title2072": 'Fixed characters at the beginning',
  "title2073": 'Fixed number of digits at the beginning',
  "title2074": 'Characters before fixed separator',
  "title2075": 'Rule name cannot be empty',
  "title2076": 'Confirm automatic pairing selected products',
  "title2077": 'items (products)',
  "title2078": 'Contains duplicates',
  "title2079": 'Confirm unpairing',
  "title2080": 'Unpairing successful',
  "title2081": 'Synchronizing online products, please check back later',
  "title2082": 'Please configure pairing rules first',
  "title2083": 'Pairing successful',
  "title2084": 'Rule saved successfully',
  "title2085": 'Please use the main account or administrator account to generate products and authorization',
  "title2086": 'Matching successful',
  "title2087": 'Shelf',
  "title2088": 'Add Singly',
  "title2089": 'Add In Bulk',
  "title2090": 'Print Shelf Label',
  "title2091": 'On Hand',
  "title2092": 'Click to collapse',
  "title2093": 'Expand remaining',
  "title2094": 'Warehouse',
  "title2095": 'Shelf Name ',
  "title2096": 'Category:',
  "title2097": 'Shelf number',
  "title2098": 'Full',
  "title2099": 'Stored',
  "title2100": 'Empty',
  "title2101": 'Create',
  "title2102": 'Update',
  "title2103": 'Clean',
  "title2104": 'Shelf Name is required',
  "title2105": 'Maximum capacity',
  "title2106": 'Please enter maximum capacity',
  "title2107": 'Prefix',
  "title2108": 'Column',
  "title2109": 'Layer',
  "title2110": 'Hyphen',
  "title2111": 'Preview',
  "title2112": 'Clearable',
  "title2113": 'Shelf number cannot be empty',
  "title2114": 'Please Enter Column',
  "title2115": 'Please Enter Layer',
  "title2116": 'Add Shelf',
  "title2117": 'Confirm delete shelf position?',
  "title2118": 'Modify shelf information',
  "title2119": 'Confirm cleaning quantity of 0?',
  "title2120": '?',
  "title2121": 'Cleaning successful',
  "title2122": 'Failed',
  "title2123": 'Sync third-party warehouse inventory',
  "title2124": 'Print label',
  "title2125": 'Modify product cost',
  "title2126": 'Modify packaging cost',
  "title2127": 'Import product cost',
  "title2128": 'Import packaging cost',
  "title2129": 'Export product inventory',
  "title2130": 'Import cost',
  "title2131": 'Inventory status',
  "title2132": 'Total Quantity',
  "title2133": 'Total Value ',
  "title2134": 'Frozen quantity:',
  "title2135": 'Frozen value:',
  "title2136": 'In-transit quantity:',
  "title2137": 'In-transit value:',
  "title2138": 'Procurement',
  "title2139": 'Shelves',
  "title2140": 'Inventory value',
  "title2141": 'Avg Daily Sales',
  "title2142": 'Daily Sales(Dynamic)',
  "title2143": 'Safety stock level',
  "title2144": 'Out-of-stock date',
  "title2145": 'Out-of-stock recommended purchase quantity',
  "title2146": 'Shortage recommended purchase quantity',
  "title2147": 'Shortage quantity',
  "title2148": 'Recommended purchase date',
  "title2149": 'Estimated time of arrival for the latest batch',
  "title2150": 'Set Shelf',
  "title2151": 'Please enter product cost',
  "title2152": 'Bulk modify recent sales volume',
  "title2153": 'Recent sales volume',
  "title2154": 'Please enter recent sales volume',
  "title2155": 'Bulk modify inventory parameters',
  "title2156": 'Production days',
  "title2157": 'Please enter production days',
  "title2158": 'Sea freight days',
  "title2159": 'Please enter sea freight days',
  "title2160": 'Extra stock days',
  "title2161": 'Please enter extra stock days',
  "title2162": 'Safety stock calculation formula',
  "title2163": '(Extra stock days)',
  "title2164": 'Average daily sales volume over the past 15 days',
  "title2165": 'Import product inventory',
  "title2166": 'Product inventory import template',
  "title2167": 'Product packaging cost import template',
  "title2168": 'Total inventory of third-party warehouse',
  "title2169": 'Discrepancy in inventory between both parties',
  "title2170": 'Expected updated available inventory',
  "title2171": 'Inventory lock status',
  "title2172": 'Total locked quantity:',
  "title2173": 'Locked',
  "title2174": 'Enter multiple products',
  "title2175": 'Separate with newline',
  "title2176": 'Separate with newline or comma',
  "title2177": 'Bulk modify product cost',
  "title2178": 'Below warning level',
  "title2179": 'Default daily sales volume',
  "title2180": 'Update successful',
  "title2181": 'Please select data operation',
  "title2182": 'Bulk modify packaging cost',
  "title2183": 'Modify safety stock',
  "title2184": 'Inventory of',
  "title2185": 'Confirm deletion of selected product inventory',
  "title2186": 'Confirm deletion of product inventory with ID',
  "title2187": 'Confirm setting shelf location',
  "title2188": 'Operation successful',
  "title2189": 'Product inventory -',
  "title2190": 'Upload completed information table',
  "title2191": 'Please first',
  "title2192": 'Export form data',
  "title2193": 'Header names cannot be changed, header rows cannot be deleted',
  "title2194": 'File extension must be',
  "title2195": 'that is',
  "title2196": 'format), file size not greater than',
  "title2197": 'Supports importing up to',
  "title2198": 'data entries',
  "title2199": 'upload files',
  "title2200": 'Download a Template',
  "title2201": 'Import successful! You have successfully imported',
  "title2202": 'entries.',
  "title2203": 'No data in the file, please check.',
  "title2204": 'Import failed! Please check error report, or check if template is incorrect before reimporting.',
  "title2205": 'Download error report',
  "title2206": 'Reimport',
  "title2207": 'Error file',
  "title2208": 'Please upload file',
  "title2209": 'In-Stock Value',
  "title2210": 'Days Available for Sale',
  "title2211": 'Value in Transit',
  "title2212": 'Data preview',
  "title2213": 'Import data',
  "title2214": 'Import completed',
  "title2215": 'Inventory age setting',
  "title2216": 'Total inventory',
  "title2217": 'Total inventory cost',
  "title2218": 'Average inventory age',
  "title2219": 'In-stock Cost',
  "title2220": 'Number of groups',
  "title2221": 'Minimum days',
  "title2222": 'Maximum days',
  "title2223": 'First group',
  "title2224": 'Second group',
  "title2225": 'Third group',
  "title2226": 'Fourth group',
  "title2227": 'Fifth group',
  "title2228": 'days or more',
  "title2229": 'Number of days is an integer greater than 0',
  "title2230": 'First group maximum days cannot be empty',
  "title2231": 'Second group maximum days cannot be empty',
  "title2232": 'Third group maximum days cannot be empty',
  "title2233": 'Fourth group maximum days cannot be empty',
  "title2234": 'Purchase Days Setting',
  "title2235": 'Suggested purchase day',
  "title2236": 'Estimated out-of-stock day',
  "title2237": 'Recent arrival time',
  "title2238": 'Main image',
  "title2239": 'Purchase specification',
  "title2240": 'Overseas warehouse inventory',
  "title2241": 'Daily average',
  "title2242": 'Turnover days',
  "title2243": 'Estimated out-of-stock date',
  "title2244": 'Recommended purchase quantity',
  "title2245": 'In-transit Quantity',
  "title2246": 'Recently estimated arrival quantity',
  "title2247": 'Product cost cannot be empty',
  "title2248": 'Sales volume in the past 15 days',
  "title2249": 'Actual inventory',
  "title2250": 'Recommended purchase quantity',
  "title2251": 'Safety stock - In-transit stock - Actual inventory',
  "title2252": 'purchase on the way',
  "title2253": 'Transfer on the way',
  "title2254": 'Confirm exporting all product inventory data items',
  "title2255": 'Product inventory',
  "title2256": 'Inventory on platform',
  "title2257": 'Open page',
  "title2258": 'Processed',
  "title2259": 'Confirm operation has been processed',
  "title2260": 'Store profit monthly report',
  "title2261": 'Operational profit monthly report',
  "title2262": 'Receiving Management',
  "title2263": 'Warehouse for third-party warehouse purchase orders does not support manual receipt, the system will automatically synchronize the receipt results of third-party warehouses.',
  "title2264": 'Shipping Warehouse',
  "title2265": 'Receiving Warehouse',
  "title2266": 'Time of Delivery',
  "title2267": 'Receiving Time',
  "title2268": 'Transfer Qty',
  "title2269": 'Receive Qty',
  "title2270": 'Transfer Order No',
  "title2271": 'Transfer remarks',
  "title2272": 'Tracking No',
  "title2273": 'Transfer Total Qty',
  "title2274": 'Receiving Status ',
  "title2275": 'to receive',
  "title2276": 'Partial Reception',
  "title2277": 'Received',
  "title2278": 'confirm receiving',
  "title2279": 'Purchase Qty',
  "title2280": 'Purchase order name',
  "title2281": 'Purchase remarks',
  "title2282": 'Purchase No',
  "title2283": 'Purchase Qty',
  "title2284": 'Edit remarks',
  "title2285": 'Please fill in the remarks',
  "title2286": 'Transfer Receiving',
  "title2287": 'Transfer Order No：',
  "title2288": 'Received Qty',
  "title2289": 'No Shelf ',
  "title2290": 'Purchase Receiving',
  "title2291": 'Receiving Warehouse',
  "title2292": 'Shipping date',
  "title2293": 'First-mile Ship Fee',
  "title2294": 'Tax Fee',
  "title2295": 'Other fee',
  "title2296": 'Product quantity:',
  "title2297": 'Total:',
  "title2298": 'Total Amount',
  "title2299": 'Volume',
  "title2300": 'Weight ',
  "title2301": 'Shipping warehouse cannot be empty',
  "title2302": 'Receiving warehouse cannot be empty',
  "title2303": 'Please select a project',
  "title2304": 'Changing the shipping warehouse will clear the added items, are you sure you want to update?',
  "title2305": 'Please enter the receiving quantity',
  "title2306": 'Received successfully',
  "title2307": 'Transfer',
  "title2308": 'Report',
  "title2309": 'Please enter the purchase order name',
  "title2310": 'Created Date',
  "title2311": 'unit price',
  "title2312": 'Total purchase amount',
  "title2313": 'Stock-In Qty',
  "title2314": 'Select purchase order',
  "title2315": 'Out of stock advice ',
  "title2316": 'Purchase Order',
  "title2317": 'Add Purchase Order',
  "title2318": 'Push purchase order',
  "title2319": 'Batch submit',
  "title2320": 'No purchase now',
  "title2321": 'Revert',
  "title2322": 'set rules',
  "title2323": 'Out-of-stock suggestion',
  "title2324": 'Purchase Days',
  "title2325": 'Estimated time from purchase order to purchase storage, days',
  "title2326": 'Transportation days',
  "title2327": 'Safety Days',
  "title2328": 'Buffer days set to prevent purchased products from arriving late',
  "title2329": 'Total sales in 3 days',
  "title2330": 'Average daily sales over 3 days',
  "title2331": 'Proposed Qty',
  "title2332": 'The greater of the out-of-stock suggestion and the stock-out suggestion',
  "title2333": 'Stocking time',
  "title2334": 'Stocking time - Available inventory - In-transit quantity',
  "title2335": 'Planned Qty ',
  "title2336": 'Total procurement quantity',
  "title2337": 'Estimated Arrival ',
  "title2338": 'Product list',
  "title2339": 'Please enter product list',
  "title2340": 'Estimated Arrival Time',
  "title2341": 'Please select date',
  "title2342": 'Please enter waybill number',
  "title2343": 'Please enter shipping fee',
  "title2344": 'Select cost allocation method',
  "title2345": 'please enter First-mile Ship Fee',
  "title2346": 'please enter Tax Fee',
  "title2347": 'please enter other Fee',
  "title2348": 'Review comments',
  "title2349": 'Comments required when rejecting',
  "title2350": 'Please enter review comments',
  "title2351": 'Please enter remarks',
  "title2352": 'Allocated Expense',
  "title2353": 'Stock-in Cost Price',
  "title2354": 'Purchase order import',
  "title2355": 'Purchase order import template',
  "title2356": 'Warehouse cannot be empty',
  "title2357": 'Purchase order name cannot be empty',
  "title2358": 'Product list cannot be empty',
  "title2359": 'Add Purchase Order',
  "title2360": 'View Purchase Order',
  "title2361": 'Edit Purchase Order',
  "title2362": 'Submission Successful',
  "title2363": 'Are you sure you want to cancel the purchase order?',
  "title2364": 'Are you sure you want to receive and put into stock?',
  "title2365": 'Are you sure you want to delete the purchase order with the name',
  "title2366": 'of the purchase order?',
  "title2367": 'Are you sure you want to export all purchase order data items?',
  "title2368": 'Are you sure you want to export purchase suggestions?',
  "title2369": 'Return Overview',
  "title2370": 'Return Report',
  "title2371": 'Return Details',
  "title2372": 'Store Profit Data Statistics',
  "title2373": 'Easily analyze and statistics the operation status of the store, help the store owner understand the profit situation of the store, and optimize business strategies to increase profit.',
  "title2374": 'Select all',
  "title2375": 'Please select operation personnel',
  "title2376": 'Select date',
  "title2377": 'Order income',
  "title2378": 'Income details',
  "title2379": 'Data',
  "title2380": 'Product amount',
  "title2382": 'Platform Subsidy',
  "title2383": 'Shopee Shipping Fee Subsidy',
  "title2384": 'Cost',
  "title2385": 'Expense Details',
  "title2386": 'Advertising Fee',
  "title2387": 'Promotion Details',
  "title2388": 'On-Site Advertising Fee',
  "title2389": 'Off-Site Advertising Fee',
  "title2390": 'Evaluation Payment Amount',
  "title2391": 'Evaluation Refund Amount',
  "title2392": 'Operation Fee',
  "title2393": 'Empty Package Fee',
  "title2394": 'Evaluation Payment Amount - Evaluation Refund Amount',
  "title2395": 'Other Details',
  "title2396": 'Cross-Month Adjustment',
  "title2397": 'Total Adjustment for Orders with Cross-Month Settlement Situations',
  "title2398": 'Platform Adjustment',
  "title2399": "Total fees for platform adjustments",
  "title2400": 'Store Expenses',
  "title2401": 'Profit Details',
  "title2402": 'Profit Rate',
  "title2403": 'Distribution of Expenditure Proportions',
  "title2404": 'Distribution of Expenditure as a Percentage of Sales',
  "title2405": 'Store Profit Data',
  "title2406": 'By Payment Time',
  "title2407": 'By Order Time',
  "title2408": 'Please Select the Month to Update',
  "title2409": 'Profit Monthly Report -',
  "title2410": 'Store Authorization Successful',
  "title2411": 'Congratulations, You Have Successfully Authorized the Store',
  "title2412": 'Before Buyer Receipt',
  "title2413": 'For orders where the shipping warehouse is specified as a third-party warehouse, the receiving warehouse will default to the shipping warehouse when returning goods. After the third-party warehouse successfully receives the return, the system will automatically synchronize the receiving results of the third-party warehouse.',
  "title2414": 'sync order',
  "title2415": 'Push return order',
  "title2416": 'Stock-in Status',
  "title2417": 'Due Time',
  "title2418": 'Product order number:',
  "title2419": 'Return Reason',
  "title2420": 'Order time',
  "title2421": 'After-Sales Time',
  "title2422": 'Marketplace Status',
  "title2423": 'In',
  "title2424": 'Respond Within',
  "title2425": 'Please In',
  "title2426": 'Respond Before',
  "title2427": 'Return No',
  "title2428": 'Return No',
  "title2429": 'Please enter return order number',
  "title2430": 'Please enter reason',
  "title2431": 'Return Status',
  "title2432": 'Please Select Dictionary Generation',
  "title2433": 'Return to Local Status',
  "title2434": 'Return Order Creation Time',
  "title2435": 'Select Return Order Creation Time',
  "title2436": 'Return Order Update Time',
  "title2437": 'Select Return Order Update Time',
  "title2438": 'Return order number cannot be empty',
  "title2439": 'Reason cannot be empty',
  "title2440": 'Return status cannot be empty',
  "title2441": 'Return status cannot be empty',
  "title2442": 'Return local status cannot be empty',
  "title2443": 'Return order creation time cannot be empty',
  "title2444": 'Return order update time cannot be empty',
  "title2445": 'Return order creation time cannot be empty',
  "title2446": 'Return order update time cannot be empty',
  "title2447": 'Add return order',
  "title2448": 'Modify return order',
  "title2449": 'Are you sure to delete the return order number as',
  "title2450": 'Are you sure to export all return order data items',
  "title2451": 'Return order',
  "title2452": 'All Sites',
  "title2453": 'Whether',
  "title2454": 'Handler',
  "title2455": 'Process for storage',
  "title2456": 'View details',
  "title2457": 'Add remarks',
  "title2458": 'Return Reason',
  "title2459": 'Details of items in storage',
  "title2460": 'Storage processing',
  "title2461": 'Whether to store',
  "title2462": 'Stock-In',
  "title2463": 'Do not store all',
  "title2464": 'Once processed into storage, return orders cannot be cancelled for storage.',
  "title2465": 'Please handle with care',
  "title2466": 'package NO',
  "title2467": 'Close',
  "title2468": "闭",
  "title2469": 'Compensation amount',
  "title2470": 'Enter remarks',
  "title2471": 'Remark date',
  "title2472": 'Remark information',
  "title2473": 'Return Reason',
  "title2474": 'Please enter positive integer',
  "title2475": 'After-sales order number',
  "title2476": 'Product order number',
  "title2477": 'Please select whether to store',
  "title2478": 'Return Manage',
  "title2479": 'Enter role name',
  "title2480": 'Role status',
  "title2481": 'Role name',
  "title2482": 'Functional permissions',
  "title2483": 'Authority of Distribution',
  "title2484": 'Data permissions',
  "title2485": 'Please input role name',
  "title2486": 'Role Identifier',
  "title2487": 'Enter Role Identifier',
  "title2488": 'Role Order',
  "title2489": 'Enter Remarks',
  "title2490": 'Menu Permissions',
  "title2491": 'Loading, please wait',
  "title2492": 'Assign Data Permissions',
  "title2493": 'Permission Range',
  "title2494": 'Parent-Child Linkage',
  "title2495": 'Select parent node, automatically select child node',
  "title2496": '角色名称不能为空',
  "title2497": '角色标识不能为空',
  "title2498": '角色顺序不能为空',
  "title2499": 'Enable',
  "title2500": 'Disable',
  "title2501": 'Confirm to role?',
  "title2502": 'Shop alias',
  "title2503": 'Add Role',
  "title2504": 'Edit Role',
  "title2505": 'Edit Successful',
  "title2506": 'Confirm deletion of role:',
  "title2507": 'Confirm exporting all role data items',
  "title2508": 'Role Data',
  "title2509": 'Enter Keyword',
  "title2510": 'Select Platform',
  "title2511": 'Expired',
  "title2512": 'Authorized',
  "title2513": 'Store Nickname',
  "title2514": 'Authorized Time',
  "title2515": "Refresh Time",
  "title2516": 'Status',
  "title2517": 'Will open the authorization page for you to authorize',
  "title2518": 'Will open for you',
  "title2519": 'Authorize on authorization page',
  "title2520": 'Please enter shop alias',
  "title2521": 'Connect',
  "title2522": 'Shop alias cannot be empty',
  "title2523": 'Add Shop Authorization',
  "title2524": 'Edit Shop',
  "title2525": 'Refresh Authorization Successful',
  "title2526": 'Refresh authorization failed, please reauthorize',
  "title2527": 'New Shop Authorization Added Successfully',
  "title2528": 'Please be cautious, deleting the store will delete the following data: Shop product information will be completely deleted and cannot be recovered. Shop order information will be cleared and cannot be recovered. Shop related settings information will be completely deleted and cannot be recovered.',
  "title2529": 'Confirm exporting all shop data items',
  "title2530": 'Created Successfully',
  "title2531": 'Turnover Rate',
  "title2532": 'New Reviews',
  "title2533": 'Enter Shop Rating',
  "title2534": 'Select Delivery Time',
  "title2535": 'Enter Overdue Delivery Rate',
  "title2536": 'Enter Incomplete Rate',
  "title2537": 'Enter Reply Rate',
  "title2538": 'Select Response Time',
  "title2539": 'Enter Turnover Rate',
  "title2540": 'Enter New Reviews',
  "title2541": 'Add Shop Data',
  "title2542": 'Edit Shop Data',
  "title2543": 'Confirm deletion of shop data with ID',
  "title2544": 'Confirm exporting all shop data items',
  "title2545": 'Select Expense Month',
  "title2546": 'Please select expense type',
  "title2547": 'Expense month',
  "title2548": 'Please enter amount (positive for expenses, negative for income)',
  "title2549": 'Expense month cannot be empty',
  "title2550": 'Expense type cannot be empty',
  "title2551": 'Amount cannot be empty',
  "title2552": 'Add shop expense',
  "title2553": 'Edit shop expense',
  "title2554": 'Confirm deletion of shop expense:',
  "title2555": ' All Logistics ',
  "title2556": 'Logistics Name',
  "title2557": 'Package Type',
  "title2558": 'Amount for shipping fee Negative & Unprocessed',
  "title2559": 'Loss in shipping costs processed amount:',
  "title2561": 'Shipping Subtotal',
  "title2562": 'Seller Shipping Fee Discount',
  "title2563": 'Return Shipping Fee',
  "title2564": 'Shipping Fee Adjustment',
  "title2565": 'Shipping Fee Status',
  "title2566": 'Normal',
  "title2567": 'Loss',
  "title2568": 'Surplus',
  "title2569": 'Logistics',
  "title2570": 'Order number',
  "title2571": 'Buyer-designated Logistics',
  "title2572": 'Process',
  "title2573": 'Please enter multiple order numbers, separated by line breaks',
  "title2574": 'Mark as Processed',
  "title2575": 'After confirmation, shipping status will be changed to [Loss Processed], cannot be undone. Are you sure to continue?',
  "title2576": 'Platform orders',
  "title2577": 'Logistics Company',
  "title2578": 'Single Item(1 qty)',
  "title2579": 'multiple Item(multiple qty)',
  "title2580": ' Noted',
  "title2581": 'No Notes',
  "title2582": 'Are you sure to ship the selected orders?',
  "title2583": 'Shipped Successfully',
  "title2584": 'Fill in remarks',
  "title2585": 'Remarks added successfully',
  "title2586": 'No permission',
  "title2587": 'Are you sure to export order information?',
  "title2588": 'All Orders',
  "title2589": 'Test Order',
  "title2590": 'Voided Orders',
  "title2591": 'Review',
  "title2592": 'Ship',
  "title2593": 'Recall',
  "title2594": 'Create Manual Order',
  "title2595": 'Push orders',
  "title2596": 'Change Cargo Carry Method',
  "title2597": 'Refresh latest cost',
  "title2598": 'Void Order',
  "title2599": 'Cancel hold',
  "title2600": 'Mark evaluation',
  "title2601": ' cancel Mark evaluation',
  "title2602": 'Mapping',
  "title2603": 'Move to pending review',
  "title2604": 'Add  Products',
  "title2605": 'Bulk Print',
  "title2606": 'Print Pick List',
  "title2607": 'Print Shipping label',
  "title2608": 'Print Pick List&Shipping label',
  "title2609": 'Mark as printed waybill',
  "title2610": 'Mark as not printed waybill',
  "title2611": 'Mark as printed picking list',
  "title2612": 'Mark as not printed picking list',
  "title2613": 'Refresh Inventory',
  "title2614": 'Print Status',
  "title2615": 'Integrity',
  "title2616": 'All Status',
  "title2617": 'Mark',
  "title2618": 'Export order list',
  "title2619": 'Export buyer information',
  "title2620": 'The failed order has actually been handled by the third-party warehouse. Please contact the third-party warehousing service provider as soon as possible to verify the order status to avoid economic losses due to shipping.',
  "title2621": 'Buyer notes',
  "title2622": 'Add Mark',
  "title2623": 'Payment Amount',
  "title2624": 'Revenue',
  "title2625": 'Total',
  "title2626": 'Actual Income',
  "title2627": 'Expenses',
  "title2628": 'Calculation',
  "title2629": 'Packaging Fee',
  "title2630": 'Platform Discount',
  "title2631": 'Recipient',
  "title2632": 'Unknown status',
  "title2633": 'Pre-order',
  "title2634": 'Evaluation',
  "title2635": 'Expired Time',
  "title2636": "According to different platform policies, the expiration time does not necessarily mean the order has been canceled by the platform. Whether the order is canceled by the platform depends on the platform's status ",
  "title2637": "For reference",
  "title2638": 'Shipping Settings',
  "title2639": 'Removal explanation:',
  "title2640": 'Order removal and added to the successful push list.',
  "title2641": 'Not Processed ',
  "title2642": 'Marked time:',
  "title2643": 'Picked list printed',
  "title2644": 'Printed invoice',
  "title2645": 'Unverified',
  "title2646": 'Under review',
  "title2647": 'Approved',
  "title2648": 'Change Mapping',
  "title2649": 'Change Current Order',
  "title2650": 'Change All',
  "title2651": 'Matching relationship (effective only for pending orders under this store, and updating the matching relationship simultaneously)',
  "title2652": 'Select type',
  "title2653": 'Total',
  "title2654": 'Order Synchronization Time Setting',
  "title2655": 'Start Synchronization',
  "title2656": 'Sync Orders',
  "title2657": 'Sync Orders',
  "title2658": 'all marketplaces',
  "title2659": 'Please enter the shop name',
  "title2660": 'Synchronizing',
  "title2661": 'Synchronizing Order Data',
  "title2662": 'Progress',
  "title2663": 'View later',
  "title2664": 'Create Manual Order',
  "title2665": 'Payment method',
  "title2666": 'Online Manual Order ',
  "title2667": 'offline Manual Order ',
  "title2668": 'Order Information',
  "title2669": 'Select payment method',
  "title2670": 'Logistics name',
  "title2671": 'Enter logistics name',
  "title2672": 'Enter logistics name',
  "title2673": 'Enter buyer-specified logistics',
  "title2674": 'Other attachments',
  "title2675": 'Upload attachment',
  "title2676": 'Receiver',
  "title2677": 'Enter recipient',
  "title2678": 'Mobile number',
  "title2679": 'Enter mobile number',
  "title2680": 'Country',
  "title2681": 'Select region',
  "title2682": 'Province',
  "title2683": 'State',
  "title2684": 'Enter province',
  "title2685": 'Enter city',
  "title2686": 'Region',
  "title2687": 'County',
  "title2688": 'Enter district',
  "title2689": 'Postal Code',
  "title2690": 'please enter Postal Code',
  "title2691": 'Detailed Address',
  "title2692": 'please enter detail address',
  "title2693": 'Buyer Message',
  "title2694": 'please enter Buyer Message',
  "title2695": 'Select currency',
  "title2696": 'Add products',
  "title2697": 'Total Price',
  "title2698": 'Enter a positive integer greater than 0',
  "title2699": 'No print',
  "title2700": 'Shipping Label',
  "title2701": ' printed',
  "title2702": 'packlist',
  "title2703": 'Non pre-order',
  "title2704": 'Single Product (Multi-quantity)',
  "title2705": 'Multi-Product Mixed Package',
  "title2706": 'Buyer pays',
  "title2707": 'Paid By Seller',
  "title2708": 'Last 2 months',
  "title2709": ' Custom Dates',
  "title2710": 'System order number cannot be empty',
  "title2711": 'Platform order number cannot be empty',
  "title2712": 'Area cannot be empty',
  "title2713": 'Buyer information cannot be empty',
  "title2714": 'Logistics tracking information cannot be empty',
  "title2715": 'Whether pre-order cannot be empty',
  "title2716": 'Whether out of stock cannot be empty',
  "title2717": 'System status cannot be empty',
  "title2718": 'Mark cannot be empty',
  "title2719": 'Payment method cannot be empty',
  "title2720": 'Please select a country',
  "title2721": 'Please enter province',
  "title2722": 'Timeout',
  "title2723": 'Canceled',
  "title2724": 'To Ship',
  "title2725": 'In transit',
  "title2726": 'Delivered',
  "title2727": 'Delivery failed',
  "title2728": 'Return order',
  "title2729": 'Return and refund in progress',
  "title2730": 'Please select order operation',
  "title2731": 'Are you sure to synchronize orders?',
  "title2732": 'Synchronization request successful, please refresh to view',
  "title2733": 'Are you sure to refresh the latest cost?',
  "title2734": 'Cost refresh successful',
  "title2735": 'Marking evaluation successful',
  "title2736": 'Canceling evaluation marking successful',
  "title2737": 'Warehouse save successful',
  "title2738": 'Are you sure to mark the selected orders for evaluation?',
  "title2739": 'Are you sure to review the selected orders?',
  "title2740": 'Are you sure to ship the selected orders?',
  "title2741": 'Selected orders?',
  "title2742": 'Are you sure to push the selected orders?',
  "title2743": 'Being pushed, please check later',
  "title2744": 'Are you sure to cancel the evaluation marking for the selected orders?',
  "title2745": 'Are you sure to put the selected orders on hold?',
  "title2746": 'Hold successful',
  "title2747": 'Are you sure to cancel the hold for the selected orders?',
  "title2748": 'Cancel hold successful',
  "title2749": 'Marked successfully',
  "title2750": 'Move selected orders to pending review?',
  "title2751": 'Successfully added marker',
  "title2752": 'Confirm deletion of marker?',
  "title2753": 'Marker deleted successfully',
  "title2754": 'Synchronization successful',
  "title2755": 'After canceling the split, all sub-packages will roll back to the main package. Are you sure you want to cancel the split?',
  "title2756": 'Split canceled successfully',
  "title2757": 'Confirm audit number for',
  "title2758": 'order',
  "title2759": 'Confirm withdrawal of selected orders',
  "title2760": 'Withdrawal successful',
  "title2761": 'Confirm removal of selected orders',
  "title2762": 'Removal successful',
  "title2763": 'Confirm suspension number for',
  "title2764": 'Confirm cancellation of suspension number',
  "title2765": 'Confirm marking number as',
  "title2766": 'evaluation order',
  "title2767": 'System prompt',
  "title2768": 'Successfully marked evaluation order',
  "title2769": 'Confirm canceling marking number as',
  "title2770": 'Successfully canceled marked evaluation order',
  "title2771": 'Note for Pick Orders',
  "title2772": 'Confirm deletion of order number as',
  "title2773": 'Confirm deletion of added items',
  "title2774": 'Please select pick-up method',
  "title2775": 'Pick-up method saved successfully',
  "title2776": 'Please select products or cancel',
  "title2777": 'Addition successful',
  "title2778": 'Confirm exporting buyer information',
  "title2779": 'Please enter purchase order attribute',
  "title2780": 'Please select if presale',
  "title2781": 'Please select product condition',
  "title2782": 'Product video',
  "title2783": 'Size chart',
  "title2784": 'Presale',
  "title2785": 'Product condition',
  "title2786": 'Brief description',
  "title2787": 'Detailed description',
  "title2788": 'Selling price',
  "title2789": 'Ad views',
  "title2790": 'Number of favorites',
  "title2791": 'Please enter category',
  "title2792": 'Please enter brand',
  "title2793": 'Please enter product image',
  "title2794": 'Please enter product video',
  "title2795": 'Please enter attributes',
  "title2796": 'Please enter product weight',
  "title2797": 'Please enter product size',
  "title2798": 'Please enter size chart',
  "title2799": 'Please enter brief description',
  "title2800": 'Please enter detailed description',
  "title2801": 'Please enter selling price',
  "title2802": 'Please enter ad views',
  "title2803": 'Please enter number of favorites',
  "title2805": 'Please enter number of comments',
  "title2806": 'Category cannot be empty',
  "title2807": 'Brand cannot be empty',
  "title2808": 'Presale status cannot be empty',
  "title2809": 'Product condition cannot be empty',
  "title2810": 'Type cannot be empty',
  "title2811": 'Selling price cannot be empty',
  "title2812": 'Ad views cannot be empty',
  "title2813": 'Number of favorites cannot be empty',
  "title2815": 'Rating cannot be empty',
  "title2816": 'Number of comments cannot be empty',
  "title2817": 'Add online product',
  "title2818": 'Modify online product',
  "title2819": 'Confirm deleting online product',
  "title2820": 'Confirm exporting all online product data',
  "title2821": 'Online product',
  "title2823": "Buyer's shipping cost ",
  "title2824": "Seller's shipping cost ",
  "title2825": 'Gross profit margin',
  "title2826": 'Order list',
  "title2827": 'Logistics seller info',
  "title2828": 'Actual income:',
  "title2829": 'Actual profit:',
  "title2830": 'Estimated income:',
  "title2832": 'Total',
  "title2833": 'Confirm exporting profit table',
  "title2834": 'Product profit',
  "title2835": 'Please enter online product',
  "title2836": "Father",
  "title2837": "Enter Father",
  "title2838": 'Please enter inventory',
  "title2839": 'Please select platform status',
  "title2840": 'Local status',
  "title2841": 'Please enter local status',
  "title2842": 'Inventory cannot be empty',
  "title2843": 'Platform status cannot be empty',
  "title2844": 'Local status cannot be empty',
  "title2845": 'Confirm exporting all online products',
  "title2846": 'Update Cost',
  "title2847": 'Update SKU Cost',
  "title2848": 'Add tag to order',
  "title2849": 'Order payment verification',
  "title2850": 'Select time type',
  "title2851": 'Payment status',
  "title2852": 'Order integrity',
  "title2853": 'Normal order',
  "title2854": 'Shipping cost not updated',
  "title2855": 'Cost not updated',
  "title2856": 'Currently in search results',
  "title2857": 'Unpaid orders',
  "title2858": 'Placed order:',
  "title2859": 'Payment received:',
  "title2860": 'tag',
  "title2861": 'Release Amount',
  "title2862": 'Paid',
  "title2863": 'Unpaid',
  "title2864": 'Payment reconciliation',
  "title2865": 'Upload payment table',
  "title2866": 'Orders Quantity',
  "title2867": 'Release Amount:',
  "title2868": 'Platform order count:',
  "title2869": 'Copy order number',
  "title2870": 'Extra orders',
  "title2871": 'Extra orders in store backend',
  "title2872": 'Store payment amount',
  "title2873": 'All types',
  "title2874": 'Inconsistent payment amounts',
  "title2875": 'Start reconciliation',
  "title2876": 'Upload product cost',
  "title2877": 'Please prepare data for import following the template format',
  "title2878": 'Format',
  "title2879": 'File size must not exceed',
  "title2880": 'Through product',
  "title2881": "Query order information, can modify the cost price of items.",
  "title2882": "Please note, it is not supported to modify orders without items.",
  "title2883": 'According to',
  "title2884": 'Double-click for batch input',
  "title2885": 'Single product cost',
  "title2886": "Batch Edit",
  "title2887": 'Payment time',
  "title2888": ' Bulk Edit On Draft ',
  "title2889": 'All orders',
  "title2890": 'Void orders',
  "title2891": 'Missing orders',
  "title2892": 'Cross-month adjustment orders',
  "title2893": 'Confirm cancel',
  "title2894": 'Please enter cost',
  "title2895": 'No modified product cost detected, please modify the product cost before proceeding',
  "title2896": 'Cost update successful',
  "title2897": 'Reconciliation template',
  "title2898": 'Update product cost import template',
  "title2899": 'Select month',
  "title2900": 'Select order time',
  "title2901": 'Order profit details',
  "title2902": 'Select product',
  "title2903": 'Select product',
  "title2904": 'Please enter receipt number',
  "title2905": 'Receipt unit price',
  "title2906": 'Stock-In No',
  "title2907": 'Rejected',
  "title2908": 'Pending submission',
  "title2909": 'Stock-In Warehouse',
  "title2910": 'Unit Price',
  "title2911": 'Import receipt',
  "title2912": 'Import receipt template',
  "title2913": 'Please select warehouse',
  "title2914": 'Confirm delete selected item?',
  "title2915": 'Add Stock-In List',
  "title2916": 'Modify receipt',
  "title2917": 'View receipt',
  "title2918": 'Confirm receipt',
  "title2919": 'Receipt successful',
  "title2920": 'Please enter receipt quantity',
  "title2921": 'Confirm deletion of inventory record as:',
  "title2922": 'Confirm exporting all inventory records data items',
  "title2923": 'Inventory record',
  "title2924": 'Operation Time ',
  "title2925": 'Inventory changes',
  "title2926": 'Original frozen inventory',
  "title2927": 'New frozen inventory',
  "title2928": 'Original available inventory',
  "title2929": 'New available inventory',
  "title2930": 'In and out information',
  "title2932": 'Please select time range',
  "title2933": 'Upload inventory check',
  "title2934": 'Import successful! You have successfully imported 1000 data entries.',
  "title2935": 'Please enter outbound number',
  "title2936": 'outbound quantity',
  "title2937": 'Stock-Out No',
  "title2938": 'Stock-Out',
  "title2939": 'Stock-Out Warehouse',
  "title2940": 'Import outbound receipt',
  "title2941": 'Import outbound receipt template',
  "title2942": 'Add Stock-Out List',
  "title2943": 'Modify outbound receipt',
  "title2944": 'View outbound receipt',
  "title2945": 'Confirm outbound',
  "title2946": 'Outbound successful',
  "title2947": 'Please enter outbound quantity',
  "title2948": 'Area',
  "title2949": 'Please enter area',
  "title2950": 'Please enter return rate',
  "title2951": 'Return rate cannot be empty',
  "title2952": 'Daily store profit',
  "title2953": 'Daily store profit number is',
  "title2954": 'Daily store profit data item',
  "title2955": 'Add Count Task',
  "title2956": 'Count Type',
  "title2957": 'Count No.',
  "title2958": 'Count',
  "title2959": 'Number',
  "title2960": ' Count Progress (SKU)',
  "title2961": 'End',
  "title2962": 'Start Counting',
  "title2963": 'Finish Counting',
  "title2964": 'Please select count type',
  "title2965": 'Inventory available for inventory check',
  "title2966": 'Import products',
  "title2967": 'Counting',
  "title2968": 'Export inventory count',
  "title2969": 'Import Counting',
  "title2970": 'Count',
  "title2971": 'Count Results',
  "title2972": 'Import template',
  "title2973": 'Inventory check import',
  "title2974": 'Inventory check import template',
  "title2975": 'Inventory type cannot be empty',
  "title2976": 'Inventory Gain',
  "title2977": 'Normal',
  "title2978": 'Inventory Loss',
  "title2979": 'Create new inventory task',
  "title2980": 'Modify inventory check',
  "title2981": 'View inventory check',
  "title2982": 'Stock Count',
  "title2983": 'Confirm end of inventory check',
  "title2984": 'Inventory check completed successfully',
  "title2985": 'New inventory check created successfully',
  "title2986": 'Confirm deletion of number',
  "title2987": 'inventory check',
  "title2988": 'Inventory check deleted successfully',
  "title2989": 'Confirm export inventory list',
  "title2990": 'Inventory check list',
  "title2991": 'Confirm export all warehouse inventory data items',
  "title2992": 'Create new transfer order',
  "title2993": 'Import transfer order',
  "title2994": 'Export transfer order',
  "title2995": 'Receive',
  "title2996": 'Confirm shipment',
  "title2997": 'please Select the warehouse for shipping',
  "title2998": 'Please choose receiving warehouse',
  "title2999": 'Cost Allocation Method',
  "title3000": 'Please select cost allocation method',
  "title3001": 'Batch edit',
  "title3002": 'Quantity:',
  "title3003": 'Transfer Price',
  "title3004": 'Final Transfer Price',
  "title3005": 'Batch edit transfer quantity',
  "title3006": 'Equal to available inventory',
  "title3007": 'Set uniform inventory value',
  "title3008": 'Enter uniform inventory value',
  "title3009": 'Transfer order import',
  "title3010": 'Transfer order import template',
  "title3011": 'Please select shipping warehouse',
  "title3012": 'Confirm shipment',
  "title3013": 'Inventory not received will be restored to the shipping warehouse, are you sure to cancel?',
  "title3014": 'Modify transfer order',
  "title3015": 'Enter transfer quantity',
  "title3016": 'Insufficient available inventory for transfer',
  "title3017": 'Transfer order',
  "title3018": 'Confirm export all transfer data',
  "title3019": 'Transfer order',
  "title3020": 'Please select payment method',
  "title3021": 'Supplier Name',
  "title3022": 'Contacts',
  "title3023": 'Phone',
  "title3024": 'Settlement Method',
  "title3025": 'Please enter the supplier name',
  "title3026": 'Purchase channel',
  "title3027": 'Enter purchase channel',
  "title3028": 'Company Name',
  "title3029": 'Enter company name',
  "title3030": 'Company address',
  "title3031": 'Enter company address',
  "title3032": 'Supplier Website',
  "title3033": 'Enter supplier website',
  "title3034": 'open account bank',
  "title3035": 'Enter open account bank',
  "title3036": 'Bank account number',
  "title3037": 'Enter payment account',
  "title3038": 'Enter contact person',
  "title3039": 'Enter contact number',
  "title3040": 'Enter WeChat',
  "title3041": 'Email',
  "title3042": 'Enter email address',
  "title3043": 'Supplier name cannot be empty',
  "title3044": 'Purchase channel cannot be empty',
  "title3045": 'Add Supplier',
  "title3046": 'Edit supplier',
  "title3047": 'Confirm delete supplier',
  "title3048": 'Confirm export all supplier data items',
  "title3049": 'Supplier',
  "title3050": 'Enter authorization',
  "title3051": 'Site number',
  "title3052": 'Enter site number',
  "title3053": 'Warehouse number',
  "title3054": 'Enter warehouse number',
  "title3055": 'Is enabled',
  "title3056": 'Warehouse address',
  "title3057": 'Enter warehouse address',
  "title3058": 'Warehouse name cannot be empty',
  "title3059": 'Enable status cannot be empty',
  "title3060": 'Site number cannot be empty',
  "title3061": 'Warehouse number cannot be empty',
  "title3062": 'Add third-party authorized warehouse',
  "title3063": 'Modify third-party authorized warehouse',
  "title3064": 'Confirm deletion of third-party authorized warehouse with number',
  "title3065": 'Confirm export of all third-party authorized warehouse data items',
  "title3066": 'Third-party authorized warehouse',
  "title3067": 'Payment exchange rate',
  "title3068": 'Modify evaluation parameters',
  "title3069": 'Add evaluation parameters',
  "title3070": 'Enter operating fee',
  "title3071": 'Enter empty package fee',
  "title3072": 'Please select a store',
  "title3073": 'Please enter payment exchange rate',
  "title3074": 'The fluctuation of the set payment exchange rate is significant, please verify before submitting',
  "title3075": 'Operating fee cannot be empty',
  "title3076": 'Empty package fee cannot be empty',
  "title3077": 'Confirm deletion of evaluation parameters',
  "title3078": 'Confirm export of all evaluation parameters data items',
  "title3079": 'Evaluation parameters',
  "title3080": 'Please search for warehouse name',
  "title3081": 'Key',
  "title3082": 'Key corresponding to the key',
  "title3083": 'Account',
  "title3084": 'Please enter account',
  "title3085": 'Authorization information',
  "title3086": 'Unauthorized',
  "title3087": 'Add Warehouse',
  "title3088": 'Please obtain authorization information from the third-party service provider, bind multiple',
  "title3089": 'It will result in multiple order pushes, causing duplicate and misdirected orders, it is recommended to only bind the current account',
  "title3090": 'Custom name',
  "title3091": 'Enter warehouse custom name',
  "title3092": 'Third-party service provider name',
  "title3093": 'Enter',
  "title3094": 'Customer code',
  "title3095": 'Please enter customer code',
  "title3096": 'Custom name cannot be empty',
  "title3097": 'Authorize third-party warehouse service provider',
  "title3098": 'Authorization successful',
  "title3099": 'Confirm deletion of third-party warehouse configuration information with ID',
  "title3100": 'Confirm export of all third-party warehouse configuration information data items',
  "title3101": 'Third-party warehouse configuration information',
  "title3102": 'Manufacturer Name',
  "title3103": 'Please enter manufacturer name',
  "title3104": 'Manufacturer Number',
  "title3105": 'Please enter manufacturer number',
  "title3106": 'Manufacturer',
  "title3107": 'Not activated',
  "title3108": 'Activated',
  "title3109": 'Official website',
  "title3110": 'Trademark',
  "title3111": 'Not activated',
  "title3112": 'Manufacturer name cannot be empty',
  "title3113": 'Manufacturer number cannot be empty',
  "title3114": 'Add third-party warehouse manufacturer',
  "title3115": 'Modify third-party warehouse manufacturer',
  "title3116": 'Confirm switching third-party warehouse manufacturer status, with ID',
  "title3117": 'Switch status successful',
  "title3118": 'Confirm deletion of third-party warehouse manufacturer with ID',
  "title3119": 'Confirm export of all third-party warehouse manufacturer data items',
  "title3120": 'Third-party Warehouse Service Provider',
  "title3121": 'Roles ',
  "title3122": 'Enter account information',
  "title3123": 'All roles',
  "title3124": 'Name',
  "title3125": 'Roles',
  "title3126": 'Assign store',
  "title3127": 'Assign warehouse',
  "title3128": 'Assign role',
  "title3129": 'Role-play',
  "title3130": 'Enter username',
  "title3131": 'Enter name',
  "title3132": 'Add user',
  "title3133": 'Login password',
  "title3134": 'Enter 6-digit password',
  "title3135": 'Create sub-account',
  "title3136": 'Enter login user',
  "title3137": 'Real name',
  "title3138": 'Confirm password',
  "title3139": 'Role permissions',
  "title3140": 'Create role',
  "title3141": 'Store permissions',
  "title3142": 'Warehouse permissions',
  "title3143": 'Warehouse permissions',
  "title3144": 'Confirm update of existing user data',
  "title3145": 'Allow import only',
  "title3146": 'File format.',
  "title3147": 'Unauthorized',
  "title3148": 'Service Provider Authorization',
  "title3149": 'Unauthorized',
  "title3150": 'New login password',
  "title3151": 'Confirm new password',
  "title3152": 'Name cannot be empty',
  "title3153": 'Login password cannot be empty',
  "title3154": 'Role cannot be empty',
  "title3155": 'Please enter a valid phone number',
  "title3156": 'User ID',
  "title3157": 'Modify user',
  "title3158": 'Modification successful, new password is:',
  "title3159": 'Confirm role-play for user',
  "title3160": 'Confirm modifying login suffix',
  "title3161": 'Role assignment successful',
  "title3162": 'Confirm deletion of user:',
  "title3163": 'Confirm exporting all user data items',
  "title3164": 'User data',
  "title3165": 'User import',
  "title3166": 'User import template',
  "title3167": 'Number of successful creations:',
  "title3168": 'Number of successful updates:',
  "title3169": 'Number of failed updates:',
  "title3170": 'Import results',
  "title3171": 'Warehouse Attribute',
  "title3172": 'Own',
  "title3173": 'Service warehouse',
  "title3174": 'Third-Party  Warehouses',
  "title3175": 'Premium Service Provider',
  "title3176": 'Warehouse type cannot be empty',
  "title3177": 'Service provider cannot be empty',
  "title3178": 'Third-party warehouse cannot be empty',
  "title3179": 'Modify warehouse',
  "title3180": 'Confirm deletion of warehouse with ID',
  "title3181": 'Confirm export of all warehouse data items',
  "title3182": 'System log',
  "title3183": 'User ID',
  "title3184": 'Please enter user ID',
  "title3185": 'User type',
  "title3186": 'Please select user type',
  "title3187": "应用名",
  "title3188": "请输入应用名",
  "title3189": "请求地址",
  "title3190": "请输入请求地址",
  "title3191": "请求时间",
  "title3192": "执行时长",
  "title3193": "请输入执行时长",
  "title3194": "结果码",
  "title3195": "请输入结果码",
  "title3196": "日志编号",
  "title3197": "请求方法名",
  "title3198": "操作结果",
  "title3199": "详细",
  "title3200": "访问日志详细",
  "title3201": "日志主键：",
  "title3202": "链路追踪：",
  "title3203": "应用名：",
  "title3204": "用户信息：",
  "title3205": "请求信息：",
  "title3206": "请求参数：",
  "title3207": "开始时间：",
  "title3208": "操作结果：",
  "title3209": "访问日志数据项",
  "title3210": "访问日志",
  "title3211": "生成类型",
  "title3212": "文件名",
  "title3213": "请输入文件名",
  "title3214": "生成类型不能为空",
  "title3215": "页面",
  "title3216": "弹窗",
  "title3217": "异常时间",
  "title3218": "处理状态",
  "title3219": "请选择处理状态",
  "title3220": "异常发生时间",
  "title3221": "异常名",
  "title3222": "已忽略",
  "title3223": "异常日志详细",
  "title3224": "异常时间：",
  "title3225": "处理时间",
  "title3226": "确认标记为",
  "title3227": "错误日志数据项",
  "title3228": "错误日志",
  "title3229": "没有与",
  "title3230": "匹配的",
  "title3231": "选择图标",
  "title3232": "请输入图标名称",
  "title3233": "文件",
  "title3234": "复制代码",
  "title3235": "选择生成类型",
  "title3236": "资源引用",
  "title3237": "格式不能识别，仅支持修改",
  "title3238": "的对象内容",
  "title3239": "请使用",
  "title3240": "错误：",
  "title3241": "导出文件",
  "title3242": "格式错误，请检查",
  "title3243": "外部资源引用",
  "title3244": "资源路径",
  "title3245": "添加其他",
  "title3246": "资源已存在",
  "title3247": "表名称",
  "title3248": "表描述",
  "title3249": "实体类名称",
  "title3250": "默认去除表名的前缀。如果存在重复，则需要手动添加前缀，避免",
  "title3251": "报",
  "title3252": "重复的问题。",
  "title3253": "作者",
  "title3254": "请输入表名称",
  "title3255": "请输入表描述",
  "title3256": "请输入实体类名称",
  "title3257": "请输入作者",
  "title3258": "字段信息",
  "title3259": "字段列名",
  "title3260": "字段描述",
  "title3261": "物理类型",
  "title3262": "插入",
  "title3263": "列表",
  "title3264": "查询",
  "title3265": "查询方式",
  "title3266": "允许空",
  "title3267": "显示类型",
  "title3268": "文本框",
  "title3269": "文本域",
  "title3270": "下拉框",
  "title3271": "单选框",
  "title3272": "复选框",
  "title3273": "日期控件",
  "title3274": "图片上传",
  "title3275": "文件上传",
  "title3276": "富文本控件",
  "title3277": "示例",
  "title3278": "生成信息",
  "title3279": "表单校验未通过，请重新检查提交内容",
  "title3280": "选项名",
  "title3281": "请输入选项名",
  "title3282": "选项值",
  "title3283": "请输入选项值",
  "title3284": "字符串",
  "title3285": "数字",
  "title3286": "生成模板",
  "title3287": "生成场景",
  "title3288": "模块名",
  "title3289": "模块名，即一级目录，例如",
  "title3290": "等等",
  "title3291": "业务名",
  "title3292": "业务名，即二级目录，例如",
  "title3293": "类名称",
  "title3294": "类名称（首字母大写），例如",
  "title3295": "类描述",
  "title3296": "用作类描述，例如",
  "title3297": "上级菜单",
  "title3298": "分配到指定菜单下，例如",
  "title3299": "系统管理",
  "title3300": "请选择系统菜单",
  "title3301": "自定义路径",
  "title3302": "填写磁盘绝对路径，若不填写，则生成到当前",
  "title3303": "项目下",
  "title3304": "最近路径快速选择",
  "title3305": "恢复默认的生成基础路径",
  "title3306": "其他信息",
  "title3307": "树编码字段",
  "title3308": "树显示的编码字段名，",
  "title3309": "树父编码字段",
  "title3310": "树显示的父编码字段名，",
  "title3311": "树名称字段",
  "title3312": "树节点的显示名称字段名，",
  "title3313": "关联信息",
  "title3314": "关联子表的表名",
  "title3315": "关联子表的表名，",
  "title3316": "子表关联的外键名",
  "title3317": "子表关联的外键名，",
  "title3318": "请选择生成模板",
  "title3319": "请选择生成场景",
  "title3320": "请输入生成模块名",
  "title3321": "请输入生成业务名",
  "title3322": "请输入生成业务包",
  "title3323": "请输入生成类名称",
  "title3324": "请输入生成类描述",
  "title3325": "暂时不考虑支持【树形】和【主子表】的代码生成。原因是：导致",
  "title3326": "模板过于复杂，不利于胖友二次开发",
  "title3327": "导入表",
  "title3328": "数据源",
  "title3329": "请选择数据源",
  "title3330": "导入成功",
  "title3331": "单元测试",
  "title3332": "实体",
  "title3333": "预览",
  "title3334": "生成代码",
  "title3335": "代码预览",
  "title3336": "确认要强制同步",
  "title3337": "表结构吗？",
  "title3338": "生成配置",
  "title3339": "是否确认删除表名称为",
  "title3340": "未知【",
  "title3341": "配置中心",
  "title3342": "参数名称",
  "title3343": "请输入参数名称",
  "title3344": "参数键名",
  "title3345": "请输入参数键名",
  "title3346": "系统内置",
  "title3347": "参数主键",
  "title3348": "参数分类",
  "title3349": "参数键值",
  "title3350": "是否可见",
  "title3351": "请输入参数分类",
  "title3352": "请输入参数键值",
  "title3353": "参数分类不能为空",
  "title3354": "参数名称不能为空",
  "title3355": "参数键名不能为空",
  "title3356": "参数键值不能为空",
  "title3357": "添加参数",
  "title3358": "修改参数",
  "title3359": "是否确认删除参数编号为",
  "title3360": "是否确认导出所有参数数据项",
  "title3361": "参数配置",
  "title3362": "组件属性",
  "title3363": "表单属性",
  "title3364": "查看组件文档",
  "title3365": "组件类型",
  "title3366": "请选择组件类型",
  "title3367": "字段名",
  "title3368": "请输入字段名（",
  "title3369": "组件名",
  "title3370": "标题",
  "title3371": "请输入标题",
  "title3372": "占位提示",
  "title3373": "请输入占位提示",
  "title3374": "开始占位",
  "title3375": "结束占位",
  "title3376": "表单栅格",
  "title3377": "栅格间隔",
  "title3378": "布局模式",
  "title3379": "水平排列",
  "title3380": "请选择水平排列",
  "title3381": "垂直排列",
  "title3382": "标签宽度",
  "title3383": "请输入标签宽度",
  "title3384": "组件宽度",
  "title3385": "请输入组件宽度",
  "title3386": "请输入默认值",
  "title3387": "至少应选",
  "title3388": "最多可选",
  "title3389": "请输入前缀",
  "title3390": "后缀",
  "title3391": "请输入后缀",
  "title3392": "前图标",
  "title3393": "请输入前图标名称",
  "title3394": "后图标",
  "title3395": "请输入后图标名称",
  "title3396": "按钮图标",
  "title3397": "请输入按钮图标名称",
  "title3398": "选项分隔符",
  "title3399": "请输入选项分隔符",
  "title3400": "最小行数",
  "title3401": "最大行数",
  "title3402": "最小值",
  "title3403": "最大值",
  "title3404": "组件高度",
  "title3405": "步长",
  "title3406": "步数",
  "title3407": "按钮位置",
  "title3408": "默认",
  "title3409": "右侧",
  "title3410": "最多输入",
  "title3411": "请输入字符长度",
  "title3412": "开启提示",
  "title3413": "请输入开启提示",
  "title3414": "关闭提示",
  "title3415": "请输入关闭提示",
  "title3416": "开启值",
  "title3417": "请输入开启值",
  "title3418": "关闭值",
  "title3419": "请输入关闭值",
  "title3420": "时间类型",
  "title3421": "请选择时间类型",
  "title3422": "文件字段名",
  "title3423": "请输入上传文件字段名",
  "title3424": "文件类型",
  "title3425": "请选择文件类型",
  "title3426": "主键编号",
  "title3427": "数据源名称",
  "title3428": "数据源连接",
  "title3429": "请输入数据源连接",
  "title3430": "数据源名称不能为空",
  "title3431": "数据源连接不能为空",
  "title3432": "添加数据源配置",
  "title3433": "修改数据源配置",
  "title3434": "是否确认删除数据源配置编号为",
  "title3435": "数据库文档",
  "title3436": "上传下载",
  "title3437": "文件路径",
  "title3438": "请输入文件路径",
  "title3439": "文件大小",
  "title3440": "文件内容",
  "title3441": "无法预览，点击",
  "title3442": "上传时间",
  "title3443": "提示：仅允许导入",
  "title3444": "是否确认删除文件编号为",
  "title3445": "配置名",
  "title3446": "请输入配置名",
  "title3447": "存储器",
  "title3448": "请选择存储器",
  "title3449": "主配置",
  "title3450": "基础路径",
  "title3451": "请输入基础路径",
  "title3452": "主机地址",
  "title3453": "请输入主机地址",
  "title3454": "主机端口",
  "title3455": "请输入主机端口",
  "title3456": "连接模式",
  "title3457": "主动模式",
  "title3458": "节点地址",
  "title3459": "请输入节点地址",
  "title3460": "存储",
  "title3461": "自定义域名",
  "title3462": "请输入自定义域名",
  "title3463": "配置名不能为空",
  "title3464": "存储器不能为空",
  "title3465": "基础路径不能为空",
  "title3466": "主机地址不能为空",
  "title3467": "主机端口不能为空",
  "title3468": "连接模式不能为空",
  "title3469": "节点地址不能为空",
  "title3470": "自定义域名不能为空",
  "title3471": "添加文件配置",
  "title3472": "修改文件配置",
  "title3473": "是否确认删除文件配置编号为",
  "title3474": "是否确认修改配置编号为",
  "title3475": "的数据项为主配置",
  "title3476": "测试通过，上传文件成功！访问地址：",
  "title3477": "数据库",
  "title3478": "多数据源（读写分离）",
  "title3479": "异步任务",
  "title3480": "消息队列",
  "title3481": "处理器的名字",
  "title3482": "请输入处理器的名字",
  "title3483": "开始执行时间",
  "title3484": "选择开始执行时间",
  "title3485": "结束执行时间",
  "title3486": "选择结束执行时间",
  "title3487": "任务状态",
  "title3488": "请选择任务状态",
  "title3489": "处理器的参数",
  "title3490": "第几次执行",
  "title3491": "执行时间",
  "title3492": "毫秒",
  "title3493": "调度日志详细",
  "title3494": "日志编号：",
  "title3495": "任务编号：",
  "title3496": "处理器的名字：",
  "title3497": "处理器的参数：",
  "title3498": "第几次执行：",
  "title3499": "执行时间：",
  "title3500": "执行时长：",
  "title3501": "任务状态：",
  "title3502": "执行结果：",
  "title3503": "是否确认导出所有定时任务日志数据项",
  "title3504": "定时任务日志",
  "title3505": "请输入任务名称",
  "title3506": "执行日志",
  "title3507": "表达式",
  "title3508": "暂停",
  "title3509": "更多",
  "title3510": "执行一次",
  "title3511": "任务详细",
  "title3512": "调度日志",
  "title3513": "请输入处理器的参数",
  "title3514": "生成表达式",
  "title3515": "重试次数",
  "title3516": "请输入重试次数。设置为",
  "title3517": "时，不进行重试",
  "title3518": "重试间隔",
  "title3519": "请输入重试间隔，单位：毫秒。设置为",
  "title3520": "时，无需间隔",
  "title3521": "监控超时时间",
  "title3522": "请输入监控超时时间，单位：毫秒",
  "title3523": "表达式生成器",
  "title3524": "任务名称：",
  "title3525": "表达式：",
  "title3526": "重试次数：",
  "title3527": "重试间隔：",
  "title3528": "监控超时时间：",
  "title3529": "后续执行时间：",
  "title3530": "任务名称不能为空",
  "title3531": "处理器的名字不能为空",
  "title3532": "表达式不能为空",
  "title3533": "重试次数不能为空",
  "title3534": "重试间隔不能为空",
  "title3535": "确认要立即执行一次",
  "title3536": "任务吗",
  "title3537": "执行成功",
  "title3538": "添加任务",
  "title3539": "修改任务",
  "title3540": "是否确认删除定时任务编号为",
  "title3541": "定时任务编号为",
  "title3542": "是否确认导出所有定时任务数据项",
  "title3543": "服务监控",
  "title3544": "接口文档",
  "title3545": "参数分组",
  "title3546": "是否敏感",
  "title3547": "请输入参数分组",
  "title3548": "参数分组不能为空",
  "title3549": "缓存",
  "title3550": "本地缓存",
  "title3551": "版本",
  "title3552": "运行模式",
  "title3553": "单机",
  "title3554": "集群",
  "title3555": "端口",
  "title3556": "客户端数",
  "title3557": "运行时间",
  "title3558": "使用内存",
  "title3559": "内存配置",
  "title3560": "是否开启",
  "title3561": "是否成功",
  "title3562": "网络入口",
  "title3563": "出口",
  "title3564": "命令统计",
  "title3565": "内存信息",
  "title3566": "超时时间",
  "title3567": "秒",
  "title3568": "键名列表",
  "title3569": "缓存键名",
  "title3570": "缓存内容",
  "title3571": "清理全部",
  "title3572": "命令",
  "title3573": "峰值",
  "title3574": "内存消耗",
  "title3575": "正在加载缓存监控数据，请稍后！",
  "title3576": "刷新键名列表成功",
  "title3577": "清理缓存键名",
  "title3578": "名字",
  "title3579": "请输入名字",
  "title3580": "名字不能为空",
  "title3581": "分类不能为空",
  "title3582": "添加字典类型",
  "title3583": "修改字典类型",
  "title3584": "是否确认删除字典类型编号为",
  "title3585": "是否确认导出所有字典类型数据项",
  "title3586": "第三方服务类型",
  "title3587": "请选择第三方服务类型",
  "title3588": "配置编码",
  "title3589": "请输入配置编码",
  "title3590": "请选择开启状态",
  "title3591": "配置内容",
  "title3592": "第三方服务类型不能为空",
  "title3593": "配置编码不能为空",
  "title3594": "配置内容不能为空",
  "title3595": "添加第三方服务配置",
  "title3596": "修改第三方服务配置",
  "title3597": "是否确认删除第三方服务配置编号为",
  "title3598": "是否确认导出所有第三方服务配置数据项",
  "title3599": "优惠券类",
  "title3600": "请选择优惠券类型",
  "title3601": "满减",
  "title3602": "折扣",
  "title3603": "随机",
  "title3604": "优惠券名称",
  "title3605": "请输入优惠券名称",
  "title3606": "请选择状态（1进行中2已结束-1已关闭）",
  "title3607": "有效日期结束时间",
  "title3608": "优惠券类型",
  "title3609": "优惠券图片",
  "title3610": "发放数量",
  "title3611": "已领取数量",
  "title3612": "已使用数量",
  "title3613": "使用门槛",
  "title3614": "优惠金额",
  "title3615": "有效期限",
  "title3616": "领取上限",
  "title3617": "优惠叠加",
  "title3618": "是否显示",
  "title3619": "订单的优惠总金额",
  "title3620": "用券总成交额",
  "title3621": "是否禁止发放",
  "title3622": "使用优惠券购买的商品数量",
  "title3623": "名称备注",
  "title3624": "请输入名称备注",
  "title3625": "请输入发放数量",
  "title3626": "适用商品类型",
  "title3627": "适用商品",
  "title3628": "请输入适用商品",
  "title3629": "满多少元使用",
  "title3630": "请输入满多少元使用",
  "title3631": "代表无限制",
  "title3632": "发放面额",
  "title3633": "请输入发放面额",
  "title3634": "时需要添加",
  "title3635": "请输入1",
  "title3636": "最多折扣金额",
  "title3637": "请输入最多折扣金额",
  "title3638": "时可选择性添加",
  "title3639": "最低金额",
  "title3640": "请输入最低金额",
  "title3641": "最大金额",
  "title3642": "请输入最大金额",
  "title3643": "过期类型",
  "title3644": "使用开始日期",
  "title3645": "过期类型1时必填",
  "title3646": "选择使用开始日期",
  "title3647": "使用结束日期",
  "title3648": "选择使用结束日期",
  "title3649": "为2或者3时需要添加",
  "title3650": "领取之日起或者次日",
  "title3651": "天内有效",
  "title3652": "请输入当",
  "title3653": "是否无限制0-否",
  "title3654": "每人最大领取个数",
  "title3655": "请输入每人最大领取个数",
  "title3656": "是否开启过期提醒0-不开启",
  "title3657": "过期前",
  "title3658": "天提醒",
  "title3659": "请输入过期前",
  "title3660": "不限制",
  "title3661": "优惠券仅原价购买商品时可用",
  "title3662": "请输入是否显示",
  "title3663": "请输入订单的优惠总金额",
  "title3664": "请输入用券总成交额",
  "title3665": "是否禁止发放0-否",
  "title3666": "请输入使用优惠券购买的商品数量",
  "title3667": "选择有效日期结束时间",
  "title3668": "随机不能为空",
  "title3669": "优惠券名称不能为空",
  "title3670": "发放数量不能为空",
  "title3671": "已领取数量不能为空",
  "title3672": "已使用数量不能为空",
  "title3673": "适用商品类型1-全部商品可用；2-指定商品可用；3-指定商品不可用不能为空",
  "title3674": "使用门槛0-无门槛",
  "title3675": "有门槛不能为空",
  "title3676": "代表无限制不能为空",
  "title3677": "时需要添加不能为空",
  "title3678": "时可选择性添加不能为空",
  "title3679": "过期类型1-时间范围过期",
  "title3680": "领取之日固定日期后过期",
  "title3681": "领取次日固定日期后过期不能为空",
  "title3682": "天内有效不能为空",
  "title3683": "是不能为空",
  "title3684": "每人最大领取个数不能为空",
  "title3685": "开启不能为空",
  "title3686": "天提醒不能为空",
  "title3687": "优惠券仅原价购买商品时可用不能为空",
  "title3688": "是否显示不能为空",
  "title3689": "订单的优惠总金额不能为空",
  "title3690": "用券总成交额不能为空",
  "title3691": "使用优惠券购买的商品数量不能为空",
  "title3692": "状态（1进行中2已结束-1已关闭）不能为空",
  "title3693": "添加优惠券模板",
  "title3694": "修改优惠券模板",
  "title3695": "是否确认删除优惠券模板编号为",
  "title3696": "是否确认导出所有优惠券模板数据项",
  "title3697": "优惠券模板",
  "title3698": "身份证号",
  "title3699": "请输入身份证号",
  "title3700": "审核状态",
  "title3701": "请选择审核状态",
  "title3702": "审核时间",
  "title3703": "选择审核时间",
  "title3704": "关联的用户编号不能为空",
  "title3705": "身份证号不能为空",
  "title3706": "审核状态不能为空",
  "title3707": "审核时间不能为空",
  "title3708": "添加会员实名认证",
  "title3709": "修改会员实名认证",
  "title3710": "是否确认删除会员实名认证编号为",
  "title3711": "是否确认导出所有会员实名认证数据项",
  "title3712": "请输入推荐人编号",
  "title3713": "邀请人手机号",
  "title3714": "请输入邀请人手机号",
  "title3715": "会员编号",
  "title3716": "邀请人编号",
  "title3717": "关系路径",
  "title3718": "请输入邀请人编号",
  "title3719": "请输入关系路径",
  "title3720": "邀请人编号不能为空",
  "title3721": "关系路径不能为空",
  "title3722": "添加用户邀请关系",
  "title3723": "修改用户邀请关系",
  "title3724": "是否确认删除用户邀请关系编号为",
  "title3725": "是否确认导出所有用户邀请关系数据项",
  "title3726": "会员手机号",
  "title3727": "请输入会员手机号",
  "title3728": "收货号码",
  "title3729": "请输入收货号码",
  "title3730": "请选择是否默认",
  "title3731": "市",
  "title3732": "地址",
  "title3733": "请输入市",
  "title3734": "请输入地址",
  "title3735": "用户编号不能为空",
  "title3736": "地址不能为空",
  "title3737": "添加收货地址",
  "title3738": "修改收货地址",
  "title3739": "是否确认删除收货地址编号为",
  "title3740": "是否确认导出所有收货地址数据项",
  "title3741": "用户昵称",
  "title3742": "请输入用户昵称",
  "title3743": "用户状态",
  "title3744": "是否实名",
  "title3745": "请输入注册",
  "title3746": "最后登录",
  "title3747": "最后登录时间",
  "title3748": "重置短信",
  "title3749": "重置实名",
  "title3750": "重置锁单",
  "title3751": "重置成功",
  "title3752": "添加会员",
  "title3753": "修改会员",
  "title3754": "是否确认导出所有会员数据项",
  "title3755": "商户名称",
  "title3756": "请输入商户名称",
  "title3757": "应用编号",
  "title3758": "支付宝配置",
  "title3759": "微信配置",
  "title3760": "所属商户",
  "title3761": "请选择所属商户",
  "title3762": "支付结果的回调地址",
  "title3763": "请输入支付结果的回调地址",
  "title3764": "退款结果的回调地址",
  "title3765": "请输入退款结果的回调地址",
  "title3766": "应用名不能为空",
  "title3767": "支付结果的回调地址不能为空",
  "title3768": "退款结果的回调地址不能为空",
  "title3769": "商户编号不能为空",
  "title3770": "添加支付应用信息",
  "title3771": "修改支付应用信息",
  "title3772": "应用吗",
  "title3773": "是否确认删除支付应用信息编号为",
  "title3774": "已取消删除",
  "title3775": "是否确认导出所有支付应用信息数据项",
  "title3776": "支付应用信息",
  "title3777": "商户号",
  "title3778": "请输入商户号",
  "title3779": "商户全称",
  "title3780": "请输入商户全称",
  "title3781": "商户简称",
  "title3782": "请输入商户简称",
  "title3783": "商户编号",
  "title3784": "商户号不能为空",
  "title3785": "商户全称不能为空",
  "title3786": "商户简称不能为空",
  "title3787": "添加支付商户信息",
  "title3788": "修改支付商户信息",
  "title3789": "商户吗",
  "title3790": "是否确认删除支付商户信息编号为",
  "title3791": "是否确认导出所有支付商户信息数据项",
  "title3792": "支付商户信息",
  "title3793": "请选择应用信息",
  "title3794": "渠道编码",
  "title3795": "请输入渠道编码",
  "title3796": "商户订单编号",
  "title3797": "请输入商户订单编号",
  "title3798": "渠道订单号",
  "title3799": "请输入渠道订单号",
  "title3800": "请选择支付状态",
  "title3801": "退款状态",
  "title3802": "请选择退款状态",
  "title3803": "回调商户状态",
  "title3804": "请选择订单回调商户状态",
  "title3805": "支付渠道",
  "title3806": "应用名称",
  "title3807": "渠道名称",
  "title3808": "支付订单",
  "title3809": "商户",
  "title3810": "支付",
  "title3811": "商品标题",
  "title3812": "手续金额",
  "title3813": "回调状态",
  "title3814": "商户订单号",
  "title3815": "支付订单号",
  "title3816": "手续费",
  "title3817": "手续费比例",
  "title3818": "回调地址",
  "title3819": "失效时间",
  "title3820": "通知时间",
  "title3821": "退款次数",
  "title3822": "支付通道异步回调内容",
  "title3823": "时间范围最大为",
  "title3824": "天！",
  "title3825": "是否确认导出所有支付订单数据项",
  "title3826": "退款类型",
  "title3827": "请选择退款类型",
  "title3828": "商户退款订单号",
  "title3829": "请输入商户退款订单号",
  "title3830": "退款回调状态",
  "title3831": "请选择通知商户退款结果的回调状态",
  "title3832": "退款",
  "title3833": "交易",
  "title3834": "渠道",
  "title3835": "元",
  "title3836": "退款原因",
  "title3837": "退款成功时间",
  "title3838": "退款订单详情",
  "title3839": "商户退款单号",
  "title3840": "交易订单号",
  "title3841": "退款失效时间",
  "title3842": "回调时间",
  "title3843": "渠道退款单号",
  "title3844": "渠道错误码",
  "title3845": "渠道错误码描述",
  "title3846": "渠道额外参数",
  "title3847": "是否确认导出所有退款订单量据项",
  "title3848": "字典名称",
  "title3849": "字典标签",
  "title3850": "请输入字典标签",
  "title3851": "数据状态",
  "title3852": "字典编码",
  "title3853": "字典键值",
  "title3854": "字典排序",
  "title3855": "颜色类型",
  "title3856": "数据标签",
  "title3857": "请输入数据标签",
  "title3858": "数据键值",
  "title3859": "请输入数据键值",
  "title3860": "显示排序",
  "title3861": "数据标签不能为空",
  "title3862": "数据键值不能为空",
  "title3863": "数据顺序不能为空",
  "title3864": "主要",
  "title3865": "危险",
  "title3866": "添加字典数据",
  "title3867": "修改字典数据",
  "title3868": "是否确认删除字典编码为",
  "title3869": "字典数据",
  "title3870": "请输入字典名称",
  "title3871": "请输入字典类型",
  "title3872": "字典状态",
  "title3873": "字典编号",
  "title3874": "字典名称不能为空",
  "title3875": "字典类型不能为空",
  "title3876": "是否确认删除字典编号为",
  "title3877": "部门名称",
  "title3878": "请输入部门名称",
  "title3879": "菜单状态",
  "title3880": "负责人",
  "title3881": "上级部门",
  "title3882": "选择上级部门",
  "title3883": "请输入负责人",
  "title3884": "部门状态",
  "title3885": "部门名称不能为空",
  "title3886": "显示排序不能为空",
  "title3887": "请输入正确的邮箱地址",
  "title3888": "未设置",
  "title3889": "添加部门",
  "title3890": "修改部门",
  "title3891": "异常处理（错误码）",
  "title3892": "错误码类型",
  "title3893": "请选择错误码类型",
  "title3894": "错误码编码",
  "title3895": "请输入错误码编码",
  "title3896": "错误码提示",
  "title3897": "请输入错误码提示",
  "title3898": "错误码编码不能为空",
  "title3899": "错误码提示不能为空",
  "title3900": "添加错误码",
  "title3901": "修改错误码",
  "title3902": "是否确认删除错误码编号为",
  "title3903": "是否确认导出所有错误码数据项",
  "title3904": "错误码",
  "title3905": "文章类型",
  "title3906": "请选择文章类型",
  "title3907": "文章标题",
  "title3908": "请输入文章标题",
  "title3909": "文章内容",
  "title3910": "文章类型不能为空",
  "title3911": "文章标题不能为空",
  "title3912": "文章内容不能为空",
  "title3913": "添加文章",
  "title3914": "修改文章",
  "title3915": "是否确认删除文章编号为",
  "title3916": "是否确认导出所有文章数据项",
  "title3917": 'Please enter currency symbol',
  "title3918": 'Currency name',
  "title3919": 'The current page provides a "Historical Exchange Rate" modification function, which will affect all "unpaid" orders within the modified date and have an impact on financial statements and profit calculations. If you need to modify the exchange rate of "paid" orders, please go to Profit Analysis -',
  "title3920": 'Profit monthly report',
  "title3921": 'for modifications on the page',
  "title3922": 'Currency symbol',
  "title3923": 'Official exchange rate',
  "title3924": 'The exchange rate is taken from the official rate, 0 every day',
  "title3925": 'Update exchange rate',
  "title3926": 'Execution rate type',
  "title3927": 'Fixed exchange rate: User-defined fixed rate.',
  "title3928": 'Discounted exchange rate: Rate calculated by multiplying the official rate by a discount percentage on that day.',
  "title3929": 'For example, US Dollar',
  "title3930": 'Exchange rate is 7',
  "title3931": 'And you have set a discount rate of 98',
  "title3932": 'Then the actual execution rate',
  "title3933": 'Exchange rate discount percentage:',
  "title3934": 'Fixed exchange rate:',
  "title3935": 'Execution rate',
  "title3936": 'Historical rate',
  "title3937": 'Log',
  "title3938": 'Add template',
  "title3939": 'Enter template name',
  "title3940": 'Template name',
  "title3941": 'Select at least one template',
  "title3942": 'Delete selected template?',
  "title3943": 'Delete?',
  "title3944": "请输入登录地址",
  "title3945": "请输入用户名称",
  "title3946": "访问编号",
  "title3947": "日志类型",
  "title3948": "登录日期",
  "title3949": "是否确认导出所有操作日志数据项",
  "title3950": "菜单路由",
  "title3951": "请输入菜单名称",
  "title3952": "菜单名称",
  "title3953": "分组名称",
  "title3954": "图标",
  "title3955": "权限标识",
  "title3956": "组件路径",
  "title3957": "选择上级菜单",
  "title3958": "菜单类型",
  "title3959": "菜单图标",
  "title3960": "点击选择图标",
  "title3961": "请输入分组名称",
  "title3962": "路由地址",
  "title3963": "访问的路由地址，如：",
  "title3964": "如需外网地址时，则以",
  "title3965": "请输入路由地址",
  "title3966": "请输入组件路径",
  "title3967": "方法上的权限字符，如：",
  "title3968": "权限字符",
  "title3969": "请权限标识",
  "title3970": "选择停用时，路由将不会出现在侧边栏，也不能被访问",
  "title3971": "显示状态",
  "title3972": "选择隐藏时，路由将不会出现在侧边栏，但仍然可以访问",
  "title3973": "显示",
  "title3974": "隐藏",
  "title3975": "选择缓存时，则会被",
  "title3976": "缓存，需要匹配组件的",
  "title3977": "和路由地址保持一致",
  "title3978": "是否缓存",
  "title3979": "不缓存",
  "title3980": "菜单名称不能为空",
  "title3981": "菜单顺序不能为空",
  "title3982": "路由地址不能为空",
  "title3983": "主类目",
  "title3984": "添加菜单",
  "title3985": "修改菜单",
  "title3986": "前端必须以",
  "title3987": "前端不能以",
  "title3988": "公告标题",
  "title3989": "请输入公告标题",
  "title3990": "操作人员",
  "title3991": "请输入操作人员",
  "title3992": "公告类型",
  "title3993": "内容类型",
  "title3994": "创建者",
  "title3995": "内容",
  "title3996": "公告标题不能为空",
  "title3997": "公告类型不能为空",
  "title3998": "添加公告",
  "title3999": "修改公告",
  "title4000": "是否确认删除公告编号为",
  "title4001": "系统公告",
  "title4002": "平台公告",
  "title4003": "东南亚资讯",
  "title4004": "全部已读",
  "title4005": "全部标记已读成功",
  "title4006": "发布时间：",
  "title4007": "确认支付",
  "title4008": "确认该订单完成支付？",
  "title4009": "岗位编码",
  "title4010": "请输入岗位编码",
  "title4011": "岗位名称",
  "title4012": "请输入岗位名称",
  "title4013": "岗位状态",
  "title4014": "岗位编号",
  "title4015": "岗位排序",
  "title4016": "请输入编码名称",
  "title4017": "岗位顺序",
  "title4018": "岗位名称不能为空",
  "title4019": "岗位编码不能为空",
  "title4020": "岗位顺序不能为空",
  "title4021": "添加岗位",
  "title4022": "修改岗位",
  "title4023": "是否确认删除岗位编号为",
  "title4024": "是否确认导出所有岗位数据项",
  "title4025": "岗位数据",
  "title4026": "操作模块",
  "title4027": "请输入操作模块",
  "title4028": "操作名",
  "title4029": "请输入操作名",
  "title4030": "操作类型",
  "title4031": "操作状态",
  "title4032": "操作人",
  "title4033": "操作日期",
  "title4034": "操作信息：",
  "title4035": "方法名：",
  "title4036": "方法参数：",
  "title4037": "操作日志",
  "title4038": "请输入地区父",
  "title4039": "地区名称",
  "title4040": "请输入地区名称",
  "title4041": "地区类型",
  "title4042": "请选择地区类型",
  "title4043": "地区父",
  "title4044": "地区名称不能为空",
  "title4045": "地区类型不能为空",
  "title4046": "添加地区",
  "title4047": "修改地区",
  "title4048": "是否确认删除地区编号为",
  "title4049": "是否确认导出所有地区数据项",
  "title4050": "角色编号",
  "title4051": 'Role type',
  "title4052": 'Modify role',
  "title4053": 'Due to',
  "title4054": 'Platform commission is settled only when the order is completed, here you need to set an estimated commission rate for profit estimation on unpaid orders',
  "title4055": 'Commission calculation formula: (Product amount - Platform discount - Seller discount)',
  "title4056": 'Shop commission percentage',
  "title4057": 'Note: Each time the commission rate is modified, it will be recalculated when there are synchronized unpaid orders; the default commission rate is 3',
  "title4058": 'Platform commission rate',
  "title4059": "敏感词",
  "title4060": "请输入敏感词",
  "title4061": "请选择标签",
  "title4062": "请选择启用状态",
  "title4063": "请选择文章标签",
  "title4064": "检测敏感词",
  "title4065": "文本",
  "title4066": "请输入测试文本",
  "title4067": "检",
  "title4068": "测",
  "title4069": "敏感词不能为空",
  "title4070": "标签不能为空",
  "title4071": "测试文本不能为空",
  "title4072": "添加敏感词",
  "title4073": "检测文本是否含有敏感词",
  "title4074": "修改敏感词",
  "title4075": "不包含敏感词！",
  "title4076": "包含敏感词：",
  "title4077": "是否确认删除敏感词编号为",
  "title4078": "是否确认导出所有敏感词数据项",
  "title4079": "短信配置",
  "title4080": "短信签名",
  "title4081": "请输入短信签名",
  "title4082": "启用状态",
  "title4083": "短信",
  "title4084": "的账号",
  "title4085": "的密钥",
  "title4086": "短信发送回调",
  "title4087": "请选择渠道编码",
  "title4088": "请输入短信",
  "title4089": "请输入短信发送回调",
  "title4090": "短信签名不能为空",
  "title4091": "渠道编码不能为空",
  "title4092": "启用状态不能为空",
  "title4093": "的账号不能为空",
  "title4094": "添加短信渠道",
  "title4095": "修改短信渠道",
  "title4096": "是否确认删除短信渠道编号为",
  "title4097": "短信渠道",
  "title4098": "请选择短信渠道",
  "title4099": "模板编号",
  "title4100": "请输入模板编号",
  "title4101": "发送状态",
  "title4102": "请选择发送状态",
  "title4103": "发送时间",
  "title4104": "接收状态",
  "title4105": "请选择接收状态",
  "title4106": "接收时间",
  "title4107": "短信内容",
  "title4108": "短信类型",
  "title4109": "短信日志详细",
  "title4110": "短信渠道：",
  "title4111": "短信模板：",
  "title4112": "的模板编号：",
  "title4113": "短信内容：",
  "title4114": "短信参数：",
  "title4115": "发送状态：",
  "title4116": "发送时间：",
  "title4117": "发送结果：",
  "title4118": "短信编号：",
  "title4119": "请求编号：",
  "title4120": "接收状态：",
  "title4121": "接收时间：",
  "title4122": "接收结果：",
  "title4123": "是否确认导出所有短信日志数据项",
  "title4124": "短信日志",
  "title4125": "找不到签名：",
  "title4126": "请输入标签内容",
  "title4127": "标签内容",
  "title4128": "颜色",
  "title4129": "标签排序",
  "title4130": "请输入标签排序",
  "title4131": "请输入颜色",
  "title4132": "标签排序不能为空",
  "title4133": "标签内容不能为空",
  "title4134": "添加标签",
  "title4135": "修改标签",
  "title4136": "是否确认删除标签",
  "title4137": "是否确认导出所有标签数据项",
  "title4138": "多租户",
  "title4139": "客户名",
  "title4140": "请输入客户名",
  "title4141": "联系手机",
  "title4142": "请输入联系手机",
  "title4143": "客户状态",
  "title4144": "请选择客户状态",
  "title4145": "客户编号",
  "title4146": "客户套餐",
  "title4147": "系统租户",
  "title4148": "子账号数",
  "title4149": "过期时间",
  "title4150": "绑定域名",
  "title4151": "请选择客户套餐",
  "title4152": "用户密码",
  "title4153": "请输入用户密码",
  "title4154": "默认入口",
  "title4155": "请选择默认入口",
  "title4156": "请选择过期时间",
  "title4157": "请输入绑定域名",
  "title4158": "客户名不能为空",
  "title4159": "客户套餐不能为空",
  "title4160": "联系人不能为空",
  "title4161": "客户状态不能为空",
  "title4162": "过期时间不能为空",
  "title4163": "绑定域名不能为空",
  "title4164": "添加客户",
  "title4165": "修改客户",
  "title4166": "是否确认删除客户编号为",
  "title4167": "是否确认导出所有客户数据项",
  "title4168": "未知套餐",
  "title4169": "套餐名",
  "title4170": "请输入套餐名",
  "title4171": "套餐编号",
  "title4172": "受限页面",
  "title4173": "勾选的菜单将会被限制，在这些页面将会有弹框弹出显示",
  "title4174": "套餐名不能为空",
  "title4175": "关联的菜单编号不能为空",
  "title4176": "添加租户套餐",
  "title4177": "修改租户套餐",
  "title4178": "是否确认删除租户套餐编号为",
  "title4179": "请选择短信类型",
  "title4180": "模板编码",
  "title4181": "请输入模板编码",
  "title4182": "的模板编号",
  "title4183": "模板内容",
  "title4184": "短信渠道编号",
  "title4185": "请选择短信渠道编号",
  "title4186": "请输入模板内容",
  "title4187": "测试发送短信",
  "title4188": "短信类型不能为空",
  "title4189": "模板编码不能为空",
  "title4190": "模板名称不能为空",
  "title4191": "模板内容不能为空",
  "title4192": "的模板编号不能为空",
  "title4193": "短信渠道编号不能为空",
  "title4194": "手机不能为空",
  "title4195": "添加短信模板",
  "title4196": "修改短信模板",
  "title4197": "是否确认删除短信模板编号为",
  "title4198": "是否确认导出所有短信模板数据项",
  "title4199": "短信模板",
  "title4200": "提交发送成功！发送结果，见发送日志编号：",
  "title4201": "用户体系",
  "title4202": "三方登陆",
  "title4203": "部门",
  "title4204": "归属部门",
  "title4205": "请选择归属部门",
  "title4206": "用户性别",
  "title4207": "岗位",
  "title4208": "用户名称不能为空",
  "title4209": "用户昵称不能为空",
  "title4210": "用户密码不能为空",
  "title4211": "的新密码",
  "title4212": "报表设计器",
  "title4213": 'Welcome to use',
  "title4214": 'Free dialogue',
  "title4215": 'Advanced',
  "title4216": 'Intelligent model',
  "title4217": 'Make your communication smarter, more efficient, and convenient!',
  "title4218": 'How to ask more effectively?',
  "title4219": 'You can try asking like this:',
  "title4220": 'Save',
  "title4221": 'All content generated by the service is created by an AI model, and the accuracy and completeness of the generated content cannot be guaranteed, and does not represent our attitude or viewpoint',
  "title4222": 'Set role',
  "title4223": 'Assign a professional identity',
  "title4224": 'For example: You are a seasoned marketing expert',
  "title4225": 'Describe the question',
  "title4226": 'Input your question along with background information, specific requirements, task goals, etc.;',
  "title4227": 'Output format',
  "title4228": 'Inform',
  "title4229": 'In what format do you need and output the content?',
  "title4230": 'Create intelligent knowledge base',
  "title4231": 'Edit intelligent knowledge base',
  "title4232": 'Intelligent knowledge base name',
  "title4233": 'Please enter the knowledge base name',
  "title4234": 'Choose platform',
  "title4235": 'Knowledge base introduction',
  "title4236": 'Please enter introduction',
  "title4237": 'Create template',
  "title4238": 'Save template',
  "title4239": 'Already used',
  "title4240": 'Document name',
  "title4241": 'Size',
  "title4242": 'Please select a file',
  "title4243": 'Uploaded file size cannot exceed',
  "title4244": 'Please enter knowledge base introduction',
  "title4245": 'Exchange rate list',
  "title4246": 'Exchange rate trend chart',
  "title4247": 'Monthly official exchange rate',
  "title4248": 'Monthly average execution rate',
  "title4249": 'Instant completion',
  "title4250": 'Enter your copy requirements on the "Input Content" page to instantly generate exclusive high-quality copies',
  "title4251": 'Update profit',
  "title4252": 'Payment rate setting',
  "title4253": 'Add custom expenses',
  "title4254": 'Export report',
  "title4255": 'Payment reconciliation statement',
  "title4256": 'Currency switching is only supported when selecting a single store, not multiple stores',
  "title4257": 'Number of payment orders',
  "title4258": 'Total amount of order payments',
  "title4259": 'Product expenses',
  "title4260": 'Evaluation payment amount - evaluation repayment amount',
  "title4261": 'Custom fees',
  "title4262": 'Learn More',
  "title4263": 'Order income summary table',
  "title4264": 'Platform statement of account',
  "title4265": 'Fees',
  "title4266": 'Buyer payment amount',
  "title4267": 'Total expenses',
  "title4269": 'Seller discounts',
  "title4270": 'Effective order repayment amount',
  "title4271": 'Cross-month adjustment amount',
  "title4272": 'Suspended order quantity',
  "title4273": 'Evaluation order quantity',
  "title4274": 'Suspended order repayment amount',
  "title4275": 'Evaluation order repayment amount',
  "title4276": 'Evaluation repayment amount',
  "title4277": 'Revenue - Product expenses - Custom fees - Evaluation losses',
  "title4278": 'Platform revenue',
  "title4279": 'Platform Charges',
  "title4280": 'Commissions, service fees, transaction fees',
  "title4281": 'Other expenses within the store',
  "title4282": 'Evaluation fees',
  "title4283": 'Evaluation order quantity',
  "title4284": 'Project',
  "title4285": 'Custom income and expenses',
  "title4286": 'Detailed cost expenses',
  "title4287": 'Expenditure as a percentage of total expenditure',
  "title4288": 'Expenditure as a percentage of effective sales',
  "title4289": 'Detailed promotional expense',
  "title4290": 'Other income and expenses',
  "title4291": 'Online product cost',
  "title4292": 'Refunds',
  "title4293": 'Select the data to be exported by filtering criteria, all selected data will be exported when generating a report',
  "title4294": 'Summarized Data',
  "title4295": 'Total number of unique visitors who visited your store and product detail pages during the selected period',
  "title4296": 'Total number of confirmed orders',
  "title4297": 'Total amount of confirmed orders',
  "title4298": 'Evaluation sales volume',
  "title4299": 'Evaluation amount',
  "title4300": 'Sales amount per order',
  "title4301": 'Store conversion rate',
  "title4302": 'Excluding reviews + shelved + canceled + unpaid sales',
  "title4303": 'Search advertising cost',
  "title4304": 'Search advertising conversion',
  "title4305": 'Search advertising sales amount',
  "title4306": 'Search advertising click-through rate',
  "title4307": 'Search advertising conversion rate',
  "title4308": 'Search advertising',
  "title4309": 'Affiliate advertising cost',
  "title4310": 'Affiliate advertising conversion',
  "title4311": 'Affiliate advertising sales amount',
  "title4312": 'Affiliate advertising click-through rate',
  "title4313": 'Affiliate advertising conversion rate',
  "title4314": 'Promotion advertising cost',
  "title4315": 'Promotion advertising conversion',
  "title4316": 'Promotion advertising sales amount',
  "title4317": 'Promotion advertising click-through rate',
  "title4318": 'Promotion advertising conversion rate',
  "title4319": 'Promotion advertising',
  "title4320": 'Estimated shipping cost',
  "title4321": 'Output',
  "title4322": 'Net sales - Search ad cost - Affiliate ad cost - Promotion ad cost - Product cost - Evaluation loss - Estimated shipping - Platform fees - Return amount',
  "title4324": 'Quantity',
  "title4325": 'Total number of confirmed items',
  "title4326": 'Sales Volume',
  "title4327": 'Actual order quantity',
  "title4328": 'Total order quantity - Evaluation order quantity',
  "title4329": 'Average order value',
  "title4330": 'Number of confirmed buyers',
  "title4331": 'change password',
  "title4332": 'Old password',
  "title4333": 'Enter old password',
  "title4334": 'Changing phone number requires verification of the original phone number, and SMS verification code will be sent to your phone',
  "title4335": "On the phone",
  "title4336": "结束时间：",
  "title4337": "请假类型：",
  "title4338": "原因：",
  "title4339": "参数，无法查看",
  "title4340": "请假信息",
  "title4341": "请假类型",
  "title4342": "请选择请假类型",
  "title4343": "申请时间",
  "title4344": "发起请假",
  "title4345": "申请编号",
  "title4346": "开始时间",
  "title4347": "取消请假",
  "title4348": "审批进度",
  "title4349": "选择开始时间",
  "title4350": "选择结束时间",
  "title4351": "提",
  "title4352": "交",
  "title4353": "开始时间不能为空",
  "title4354": "结束时间不能为空",
  "title4355": "请假类型不能为空",
  "title4356": "请假原因不能为空",
  "title4357": "发起成功",
  "title4358": 'Ad clicks',
  "title4359": 'Now supported',
  "title4360": 'Ad data analysis is now supported. You can easily obtain relevant data through the plugin. Please refer to the user manual for specific instructions!',
  "title4361": 'By trend',
  "title4362": 'By value',
  "title4363": 'Organic clicks',
  "title4364": 'Ad impressions',
  "title4365": 'Ad sales percentage',
  "title4366": 'Ad conversion rate',
  "title4367": 'Select up to two indicators',
  "title4368": 'Edit product cost',
  "title4369": 'Add new cost',
  "title4370": 'Add historical cost',
  "title4371": 'Add historical cost',
  "title4372": 'Modifying product cost updates the current cost of the product, without affecting past orders. Future orders will be calculated using the latest cost.',
  "title4373": 'Cost amount',
  "title4374": 'After modifying the cost of products for a specified historical date, the system will automatically update the cost of products in all orders within that date to the new value and recalculate relevant report data.',
  "title4375": 'Cost setting instructions',
  "title4376": 'The suggested calculation method for the cost of a single product is as follows',
  "title4377": 'Cost of a single product',
  "title4378": 'Historical balance of items',
  "title4379": 'Historical unit purchase cost',
  "title4380": 'Total purchase cost of multiple batches on the corresponding date',
  "title4381": 'Total number of items purchased in multiple batches',
  "title4382": 'Example illustration',
  "title4383": 'At the end of the month, there are leftovers from sales',
  "title4384": 'items left with a purchase price of',
  "title4385": 'yuan.',
  "title4386": 'Replenished in the month of',
  "title4387": 'The calculation of the purchase price for June is as follows',
  "title4388": 'Purchase Price',
  "title4389": 'If there are still 10 items left after sales in June, with a purchase price of 17 yuan, the purchase price for July, if the purchasing function is not used, will refer to the last purchase price, which is 17 yuan',
  "title4390": 'There is a date conflict in the same warehouse, please choose again',
  "title4391": 'Date conflict in the same warehouse, please choose again',
  "title4392": 'Edit custom costs',
  "title4393": 'Add custom costs',
  "title4394": 'Please select at least one data',
  "title4395": 'Modify type',
  "title4396": 'Modify content',
  "title4397": 'To',
  "title4398": 'Product cost:',
  "title4399": 'Operating account',
  "title4400": 'Product cost modification record',
  "title4401": 'Default cost amount',
  "title4402": 'Modify cost amount',
  "title4403": 'Historical cost modification record',
  "title4404": 'Cost date range',
  "title4405": 'Historical cost amount',
  "title4406": 'Upload',
  "title4407": 'Please prepare data for import in the format of the data template',
  "title4408": 'Date conflict in the same warehouse, please choose again',
  "title4409": 'Edit custom costs',
  "title4410": 'Add custom costs',
  "title4411": 'Please select at least one data',
  "title4412": 'Modify type',
  "title4413": 'Modify content',
  "title4414": 'To',
  "title4415": 'Product cost:',
  "title4416": 'Operating account',
  "title4417": 'Product cost modification record',
  "title4418": 'Default cost amount',
  "title4419": 'Refunds',
  "title4420": 'Modify cost amount',
  "title4421": 'Historical cost modification record',
  "title4422": 'Cost date range',
  "title4423": 'Historical cost amount',
  "title4424": 'Upload',
  "title4425": 'Please prepare data for import in the format of the data template',
  "title4426": 'Image has been saved',
  "title4427": 'pieces',
  "title4428": 'Increase capacity',
  "title4429": 'Capacity utilization',
  "title4430": 'Used',
  "title4431": 'Total',
  "title4432": 'Already associated',
  "title4433": 'Product Analytics',
  "title4434": 'Total cost of goods for valid orders',
  "title4435": 'Quantity of return orders',
  "title4436": 'Total advertising costs',
  "title4437": 'Ranking of valid sales volume',
  "title4438": 'Sales Trend',
  "title4439": 'Activity modification',
  "title4440": 'Sales volume range from',
  "title4441": 'to',
  "title4442": 'Defined as',
  "title4443": 'And the inventory quantity is in',
  "title4444": 'Please set the sales interval range',
  "title4445": 'Please input inventory in ascending order',
  "title4446": 'Please input range in ascending order',
  "title4447": 'Select online image (',
  "title4448": 'Please enter online image address, one address per line',
  "title4449": 'Competitor information',
  "title4450": 'Competitor link',
  "title4451": 'Verification address',
  "title4452": 'Competitor title',
  "title4453": 'Product remarks',
  "title4454": 'Verification failed',
  "title4455": 'Shelf position import template',
  "title4456": 'Select local image',
  "title4457": 'Select online image',
  "title4458": 'No images available',
  "title4459": 'Please select local image files or choose online images to add, you can upload a maximum of 8 images',
  "title4460": 'Image format:',
  "title4461": 'Image size cannot exceed',
  "title4462": 'Image size must not be smaller than',
  "title4463": 'Warehouse Info',
  "title4464": 'Inventory quantity',
  "title4465": 'Replenishment frequency',
  "title4466": 'Price information',
  "title4467": 'Purchase price',
  "title4468": ' Supplier information',
  "title4469": 'Select supplier',
  "title4470": 'Supplier link',
  "title4471": 'After-sales issues',
  "title4472": 'Return No',
  "title4473": 'Canceling Reason',
  "title4474": 'Handling method',
  "title4475": 'Return Manage',
  "title4476": 'Return processing',
  "title4477": 'Operation description',
  "title4478": 'Purchase time',
  "title4479": 'Purchase quantity',
  "title4480": 'order time',
  "title4481": 'Shipping time:',
  "title4482": 'Stock movement quantity',
  "title4483": 'Stock movement reason',
  "title4484": 'Modification log',
  "title4485": 'Currently selected limit',
  "title4486": 'files, selected this time',
  "title4487": 'files in total selected',
  "title4488": 'files',
  "title4489": 'Selected',
  "title4490": 'Choose shelf position',
  "title4491": 'Add to purchase plan',
  "title4492": 'After adding the purchase plan, please go to "Purchase-"',
  "title4493": 'page to view the purchase plan.',
  "title4494": 'Print quantity',
  "title4495": 'Barcode',
  "title4496": 'QR code',
  "title4497": 'Label paper',
  "title4498": 'One column',
  "title4499": 'Two columns',
  "title4500": 'Three columns',
  "title4501": 'Print',
  "title4502": 'Print',
  "title4503": 'Please select label paper',
  "title4504": 'Please enter quantity',
  "title4505": 'The last one cannot be deleted!',
  "title4506": 'Group settings',
  "title4507": 'Enter keywords to filter',
  "title4508": 'Delete group',
  "title4509": 'Also delete its subgroups, and adjust the grouping of applied data to ungrouped.',
  "title4510": 'Replenishment suggestion data will calculate suggested data based on restocking duration, please fill in according to actual usage',
  "title4511": 'Add daily sales',
  "title4512": 'Restocking rules',
  "title4513": 'Exclude out-of-stock data from historical sales',
  "title4514": 'Daily sales rules',
  "title4515": 'Dynamic sales',
  "title4516": 'Fixed sales',
  "title4517": 'Percentage sales',
  "title4518": 'Time period',
  "title4519": 'All time',
  "title4520": 'Daily Sales',
  "title4521": 'Calculate',
  "title4522": 'Daily average',
  "title4523": 'order time',
  "title4524": 'Buyer request time',
  "title4525": 'Return Reason',
  "title4526": 'Return Status',
  "title4527": 'Incomplete',
  "title4528": 'Completed',
  "title4529": 'Item not received',
  "title4530": 'Missing item',
  "title4531": 'Wrong item received',
  "title4532": 'Item damaged',
  "title4533": 'Defective item',
  "title4534": 'Expired item',
  "title4535": 'Counterfeit item',
  "title4536": 'Item does not match description',
  "title4537": 'Item not suitable',
  "title4538": 'Changed mind',
  "title4539": 'Suspicious package',
  "title4540": 'Damaged packaging',
  "title4541": 'Request for return',
  "title4542": 'Duplicate order',
  "title4543": 'Incorrect order',
  "title4544": 'No longer want',
  "title4545": 'Platform cancellation',
  "title4546": 'High shipping costs',
  "title4547": 'No desired payment method',
  "title4548": 'Change payment method',
  "title4549": 'Found cheaper price',
  "title4550": 'Incorrect price',
  "title4551": 'Buyer did not pay',
  "title4552": 'Discount not as expected',
  "title4553": 'Change discount',
  "title4554": 'Incorrect shipping information',
  "title4555": "Buyer's address unreachable ",
  "title4556": 'Item not delivered on time',
  "title4557": 'Item not shipped on time',
  "title4558": 'Item has quality issues',
  "title4559": 'Missing accessories or gifts',
  "title4560": 'Item not as expected',
  "title4561": 'Here are some common return reasons',
  "title4562": 'Return quantity',
  "title4563": 'Proportion number',
  "title4564": 'Return reason proportion number',
  "title4565": 'by day',
  "title4566": 'Settlement cycle',
  "title4567": 'Settlement status',
  "title4568": 'Payment record',
  "title4569": 'Transfer amount',
  "title4570": 'Transfer date',
  "title4571": 'Transfer status',
  "title4572": 'Bank tail number',
  "title4573": 'Bank transfer code',
  "title4574": 'Unsettled amount',
  "title4575": 'Settled amount',
  "title4576": 'Failed transfer amount',
  "title4577": 'Cost ratio',
  "title4578": 'Multi-select region',
  "title4579": 'Comparative analysis',
  "title4580": 'Export orders',
  "title4581": 'Chinese Yuan (CNY)',
  "title4582": 'Local currency',
  "title4583": 'Add custom template',
  "title4584": 'Exporting, please wait!',
  "title4585": 'Processing export data, please do not close the current page. It will automatically download when completed. Closing the page will cancel the download.',
  "title4586": 'Select template',
  "title4587": 'Standard template',
  "title4588": 'Select tags',
  "title4589": 'Orders, only modify the selected shop in the orders',
  "title4590": 'Matching relationship',
  "title4591": 'Matched products',
  "title4592": 'Multi-select platform',
  "title4593": 'Main',
  "title4594": 'Split',
  "title4595": 'Order status',
  "title4596": 'Basic information',
  "title4597": 'Product amount',
  "title4598": 'Specifications',
  "title4599": 'Nickname',
  "title4600": 'Address 1',
  "title4601": 'Mobile',
  "title4602": 'Town',
  "title4603": 'Address 2',
  "title4604": 'telephone number',
  "title4605": 'House number',
  "title4606": 'Address 3',
  "title4607": 'Shipment Information',
  "title4608": 'Buyer-designated Logistics',
  "title4609": 'Pickup method',
  "title4610": 'My remarks',
  "title4611": 'Financial information',
  "title4612": 'Current currency',
  "title4613": 'Exchange',
  "title4614": 'Fixed exchange rate',
  "title4615": 'Trial calculation',
  "title4616": 'Estimated income',
  "title4617": 'Estimated cost',
  "title4618": 'Freight expenditure (trial)',
  "title4619": 'Total expenditure',
  "title4621": 'Total profit',
  "title4622": 'Earnings',
  "title4623": 'No order parcels',
  "title4624": 'Parcel',
  "title4625": 'No parcel information',
  "title4626": 'No information',
  "title4627": 'Split order',
  "title4628": 'Cancel split order',
  "title4629": 'parcels',
  "title4630": 'products',
  "title4631": 'Warehouse:',
  "title4632": 'Split parcels',
  "title4633": 'Main parcel',
  "title4634": 'Move to',
  "title4635": 'Parcel number',
  "title4636": 'Unit price of goods',
  "title4637": 'Select at least one product',
  "title4638": 'The main package must have at least one product',
  "title4639": 'Brand name',
  "title4640": 'Please enter the brand name',
  "title4641": 'Brand number',
  "title4642": 'Brand image',
  "title4643": 'Category picture',
  "title4644": 'Brand sorting',
  "title4645": 'Brand description',
  "title4646": 'Please enter brand sorting',
  "title4647": 'Brand name cannot be empty',
  "title4648": 'Brand picture cannot be empty',
  "title4649": 'Brand sorting cannot be empty',
  "title4650": 'Add brand',
  "title4651": 'Modify brand',
  "title4652": 'Are you sure to delete the brand number',
  "title4653": 'Thumbnail',
  "title4654": 'Thumbnail picture',
  "title4655": 'Redirect link',
  "title4656": 'Please enter the redirect link',
  "title4657": 'Please enter sorting',
  "title4658": 'Title cannot be empty',
  "title4659": 'Image address cannot be empty',
  "title4660": 'Redirect address cannot be empty',
  "title4661": 'Sorting cannot be empty',
  "title4662": 'Category sorting',
  "title4663": 'Parent category',
  "title4664": 'Recommend',
  "title4665": 'Image resolution',
  "title4666": 'Category description',
  "title4667": 'Please enter category description',
  "title4668": 'Please select parent category',
  "title4669": 'Category name cannot be empty',
  "title4670": 'Category picture cannot be empty',
  "title4671": 'Category sorting cannot be empty',
  "title4672": 'Top-level category',
  "title4673": 'Add product category',
  "title4674": 'Modify product category',
  "title4675": 'Are you sure to delete the product category number',
  "title4676": 'Specification name',
  "title4677": 'Please enter specification name',
  "title4678": 'Attribute value',
  "title4679": 'Attribute value cannot be empty',
  "title4680": 'Add specification',
  "title4681": 'Modify specification',
  "title4682": 'Are you sure to delete the specification name',
  "title4683": 'Are you sure to export all specification name data items',
  "title4684": 'SKU商品编码；Product name商品名称；',
  "title4685": 'Product sales, product amount',
  "title4686": 'Please select the shelf status',
  "title4687": 'Reference interface;',
  "title4688": 'Fields: Product information, price, inventory, sales, sorting, creation time, status, operation;',
  "title4689": 'All, on sale, in warehouse, in warning',
  "title4690": 'Main picture address of the product',
  "title4691": 'Sorting field',
  "title4692": 'Clicks',
  "title4693": 'Price range',
  "title4694": 'Modify product',
  "title4695": 'Basic settings',
  "title4696": 'Product selling point',
  "title4697": 'Please enter product selling point',
  "title4698": 'Product main picture',
  "title4699": 'Product brand',
  "title4700": 'Please select product brand',
  "title4701": 'Merchant SKU Category',
  "title4702": 'List now',
  "title4703": 'Put in warehouse',
  "title4704": 'Price and stock',
  "title4705": 'Enable variations',
  "title4706": 'Single variation',
  "title4707": 'Multiple variations',
  "title4708": 'Variations:',
  "title4709": 'Add variation item',
  "title4710": 'Variation details',
  "title4711": 'Variation images',
  "title4712": 'Market price',
  "title4713": 'Selling price',
  "title4714": 'Cost price',
  "title4715": 'Warning stock',
  "title4716": 'Barcode',
  "title4717": 'Disable',
  "title4718": 'Virtual sales',
  "title4719": 'Please enter virtual sales',
  "title4720": 'Product details',
  "title4721": 'Advanced settings',
  "title4722": 'Please enter sorting field',
  "title4723": 'Show stock',
  "title4724": 'Confirm',
  "title4725": 'Product status cannot be empty',
  "title4726": 'Product carousel image address cannot be empty',
  "title4727": 'Virtual sales cannot be empty',
  "title4728": "渠道费率",
  "title4729": "请输入渠道费率",
  "title4730": "开放平台",
  "title4731": "请输入开放平台",
  "title4732": "渠道状态",
  "title4733": "网关地址",
  "title4734": "算法类型",
  "title4735": "公钥类型",
  "title4736": "商户私钥",
  "title4737": "请输入商户私钥",
  "title4738": "支付宝公钥字符串",
  "title4739": "请输入支付宝公钥字符串",
  "title4740": "商户公钥应用证书",
  "title4741": "请上传商户公钥应用证书",
  "title4742": "支付宝公钥证书",
  "title4743": "请上传支付宝公钥证书",
  "title4744": "根证书",
  "title4745": "请上传根证书",
  "title4746": "请输入开放平台上创建的应用的",
  "title4747": "渠道状态不能为空",
  "title4748": "请传入网关地址",
  "title4749": "请传入签名算法类型",
  "title4750": "公钥类型不能为空",
  "title4751": "请上传指定根证书",
  "title4752": "编辑支付渠道",
  "title4753": "创建支付渠道",
  "title4754": "请上传指定格式",
  "title4755": "文件大小超过",
  "title4756": "公众号",
  "title4757": "请输入公众号",
  "title4758": "商户密钥",
  "title4759": "请输入商户密钥",
  "title4760": "证书",
  "title4761": "请上传",
  "title4762": "请传入商户号",
  "title4763": "版本不能为空",
  "title4764": "密钥值",
  "title4765": 'Please enter execution exchange rate',
  "title4766": 'The fluctuation of the execution exchange rate you set is large, please check and submit again',
  "title4767": 'Currency symbol:',
  "title4768": 'Currency name:',
  "title4769": 'Operation details',
  "title4770": 'Custom template',
  "title4771": 'Please select at least one parameter',
  "title4772": 'The repayment exchange rate setting only affects "received payment" orders and affects financial reports and profit calculations.',
  "title4773": 'After modifying the exchange rate, it is expected to take effect in 10 to 15 minutes. Please be patient.',
  "title4774": 'Select month',
  "title4775": 'Repayment exchange rate',
  "title4776": 'Updating...',
  "title4777": 'Modify exchange rate',
  "title4778": 'International real-time exchange rate',
  "title4779": 'Please enter the repayment exchange rate',
  "title4780": "The fluctuation of the repayment exchange rate you set is significant. Please double-check before submitting.",
  "title4781": "Edit exchange rate",
  "title4782": "Exchange rate discount",
  "title4783": "Tips:",
  "title4784": "How to use exchange rate discount?",
  "title4785": "When selecting an exchange rate discount, the exchange rate will be executed according to the discount. It is actually applied where the exchange rate is calculated for orders and other information.",
  "title4786": "How is the exchange rate discount calculated?",
  "title4787": "The exchange rate discount is usually calculated by dividing the repayment exchange rate by the official exchange rate.",
  "title4788": "For example, if the official exchange rate is 7, and the repayment exchange rate is 7,",
  "title4789": "And the repayment rate is 7",
  "title4790": "then the exchange rate discount ratio",
  "title4791": "is rounded to four decimal places, which equals 99.",
  "title4792": "Enter 99 in the input box.",
  "title4793": "Done.",
  "title4794": "Please enter the fixed exchange rate",
  "title4795": "The fluctuation of the exchange rate you set is significant. Please double-check before submitting.",
  "title4796": "Please enter the proportion",
  "title4797": "Modify the execution exchange rate",
  "title4798": "Modifying historical execution exchange rates, it is expected to take effect in 10 to 15 minutes. Please be patient.",
  "title4799": "Exchange rate discount:",
  "title4800": "Please select a date",
  "title4801": "Cannot select today",
  "title4802": "客户端编号",
  "title4803": "客户端密钥",
  "title4804": "应用图标",
  "title4805": "访问令牌的有效期",
  "title4806": "刷新令牌的有效期",
  "title4807": "授权类型",
  "title4808": "请输入客户端编号",
  "title4809": "请输入客户端密钥",
  "title4810": "应用描述",
  "title4811": "单位：秒",
  "title4812": "请输入授权类型",
  "title4813": "授权范围",
  "title4814": "请输入授权范围",
  "title4815": "自动授权范围",
  "title4816": "可重定向的",
  "title4817": "请输入可重定向的",
  "title4818": "权限",
  "title4819": "请输入权限",
  "title4820": "资源",
  "title4821": "请输入资源",
  "title4822": "附加信息",
  "title4823": "请输入附加信息，",
  "title4824": "格式数据",
  "title4825": "客户端编号不能为空",
  "title4826": "客户端密钥不能为空",
  "title4827": "应用图标不能为空",
  "title4828": "访问令牌的有效期不能为空",
  "title4829": "刷新令牌的有效期不能为空",
  "title4830": "授权类型不能为空",
  "title4831": "客户端",
  "title4832": "是否确认删除客户端编号为",
  "title4833": "访问令牌",
  "title4834": "刷新令牌",
  "title4835": "强退",
  "title4836": "是否确认强退令牌为",
  "title4837": "强退成功",
  "title4838": "Enter old password",
  "title4839": 'New Password',
  "title4840": 'Please enter a password',
  "title4841": 'Please confirm password',
  "title4842": 'The two passwords entered are inconsistent',
  "title4843": 'Old password cannot be empty',
  "title4844": 'New password cannot be empty',
  "title4845": 'Length in',
  "title4846": 'Please enter a password',
  "title4847": 'Fingerprint',
  "title4848": 'Social platform',
  "title4849": 'Already bound',
  "title4850": 'Unbind',
  "title4851": 'Not bound',
  "title4852": 'Bind',
  "title4853": 'Binding successful',
  "title4854": 'Unbinding successful',
  "title4855": 'Profile modification',
  "title4856": 'Profile picture',
  "title4857": 'Change profile picture',
  "title4858": 'Male',
  "title4859": 'Female',
  "title4860": 'Mobile verification',
  "title4861": 'Your bound mobile',
  "title4862": 'Verification Code',
  "title4863": 'Click to upload avatar',
  "title4864": 'Edit avatar',
  "title4865": 'File format error, please upload image type',
  "title4866": 'file with the suffix.',
  "title4867": 'Scrape Produk',
  "title4868": 'Data collection in progress, please do not close the current page. If the page is closed, the product collection operation will be canceled.',
  "title4869": 'Products are being collected, please wait',
  "title4870": 'Collection successful, please check the results in the collection list',
  "title4871": 'Long description',
  "title4872": 'Short description',
  "title4873": 'Based on the restrictions of each platform on the number of product images, the image with higher priority will be selected as the product image.',
  "title4874": 'Local upload',
  "title4875": 'Online address',
  "title4876": 'Red',
  "title4877": 'Yellow',
  "title4878": 'Blue',
  "title4879": 'Current page',
  "title4880": 'Core Indicator',
  "title4881": 'Inventory situation',
  "title4882": 'Cost and sales trend',
  "title4883": 'Sales situation',
  "title4884": 'Cost details',
  "title4885": 'Evaluation situation',
  "title4886": 'Data distribution',
  "title4887": 'Custom cost details',
  "title4888": 'No costs available',
  "title4889": 'Occupying this area',
  "title4890": 'Occupying all stores',
  "title4891": 'Indicator sorting',
  "title4892": 'Drag indicators to sort',
  "title4893": 'Total profit, excluding canceled, evaluated, and shelved orders',
  "title4894": 'Total amount ordered by buyers, including platform subsidies',
  "title4895": 'Total sales under effective orders, excluding canceled, evaluated, and shelved orders, including platform subsidies',
  "title4896": 'Total number of orders placed by buyers.',
  "title4897": 'Total order quantity, including canceled and refunded orders.',
  "title4898": 'View by chart',
  "title4899": 'View by table',
  "title4900": 'Display up to 15 entries',
  "title4901": 'Total cost',
  "title4902": 'Composite chart',
  "title4903": 'Comparison chart',
  "title4904": 'Positive review',
  "title4905": 'Neutral/Negative review',
  "title4906": 'Comments are being collected, please check back later',
  "title4907": 'Analyze comments',
  "title4908": 'Comment date',
  "title4909": 'Cost vs. Sales comparison',
  "title4910": 'Trend chart',
  "title4911": 'Daily average sales per day',
  "title4912": 'Total sales per day',
  "title4913": 'Estimated days of sale',
  "title4914": 'Inventory in transit',
  "title4915": 'Inventory age',
  "title4916": 'Total inventory value',
  "title4917": 'Total available inventory',
  "title4918": 'Total frozen inventory',
  "title4919": 'Total in transit',
  "title4920": 'Average days of sale',
  "title4921": 'The',
  "title4922": 'The maximum number of days for the group cannot be empty',
  "title4923": 'The value must be greater than the previous group',
  "title4924": 'Refunds',
  "title4925": 'WeChat Pay',
  "title4926": 'Store information',
  "title4927": 'Estimated receivable amount',
  "title4928": 'Estimated handling fee',
  "title5000": 'Match',
  "title5001": 'Letters must contain uppercase',
  "title5002": 'Letters, numbers, and characters must contain at least 2 types',
  "title5003": 'Password cannot be the same as the username',
  "title5004": 'Exit',
  "title5005": 'Congratulations on successfully registering! To provide a better experience, please fill in the following information.',
  "title5006": 'Please select the sales area and confirm the main currency. Once the sales area and main currency of the account are confirmed, they cannot be changed again. Please check carefully before submitting.',
  "title5007": 'Select sales area',
  "title5008": 'Account currency',
  "title5010": 'Chat with us',
  "title5011": 'Add dedicated customer service, solve problems in a timely manner',
  "title5012": 'Provide',
  "title5013": 'To',
  "title5014": 'product operation demonstration',
  "title5015": 'Let you and your team quickly grasp and use',
  "title5016": 'Scan the code to get professional services',
  "title5017": 'Please select sales area',
  "title5019": "Indonesia",
  "title5020": "Malaysia",
  "title5021": "Philippines",
  "title5022": "Singapore",
  "title5023": "Thailand",
  "title5024": "Vietnam",
  "title5025": 'Are you sure you want to log out and exit the system?',
  "title5026": 'Prompt',
  "title5027": 'Script',
  "title5028": 'Corresponding description copy',
  "title5029": 'Required',
  "title5030": 'Default Value',
  "title5031": 'Template',
  "title5032": 'Template number is',
  "title5033": 'Template data items',
  "title5034": 'Template category',
  "title5035": 'Template category number is',
  "title5036": 'Template category data items',
  "title5037": 'Day',
  "title5038": 'Piece',
  "title5039": 'Total',
  "title5040": 'Number of comment analysis',
  "title5041": 'Points',
  "title5042": 'Validity period',
  "title5043": 'Please complete the payment on the new pop-up page, and click the button below after the payment is completed.',
  "title5044": 'Friendly reminder',
  "title5045": 'Payment completed',
  "title5046": 'Congratulations, package purchased successfully',
  "title5047": 'Package purchase failed, payment unsuccessful',
  "title5048": 'Search users',
  "title5049": 'To continue, follow these steps:',
  "title5050": 'Parameters, cannot view process information',
  "title5051": 'Cannot be empty',
  "title5052": 'Organic visitors',
  "title5053": 'Organic sales',
  "title5054": 'Organic sales revenue',
  "title5055": 'Data items',
  "title5056": 'Products',
  "title5057": "Product",
  "title5058": 'Hour',
  "title5059": 'Price',
  "title5060": 'Please enter product name',
  "title5061": 'Product name',
  "title5062": 'High,',
  "title5063": 'days cannot be empty',
  "title5064": 'Profit in days goods',
  "title5065": 'Days goods profit number is',
  "title5066": 'Days goods profit data items',
  "title5067": 'Irreversible deletion, will also delete associated inventory and pairing relationships',
  "title5068": 'Numbered as',
  "title5069": 'Box: Exclusive private space for cats, making them healthier and happier lives!',
  "title5070": 'How to use?',
  "title5071": 'Matching relationship help information',
  "title5072": 'Synchronize online products',
  "title5073": "Update products from authorized shops to local.",
  "title5074": "Update to local.",
  "title5075": 'Quickly generate matches',
  "title5076": "Eliminate the manual creation of local products.",
  "title5077": "The cumbersome process.",
  "title5078": 'Generate products',
  "title5079": "Quickly sync products after synchronization",
  "title5080": "and automatically establish pairing relationships",
  "title5081": 'Merge products',
  "title5082": "Select the products to be merged",
  "title5083": "Merge into one local product",
  "title5084": 'Automatic pairing',
  "title5085": 'Achieve online and offline through automatic recognition rules',
  "title5086": "automatic pairing relationships",
  "title5087": 'Unpair',
  "title5088": "Cancel Products",
  "title5089": "with Local Products",
  "title5090": "pairing relationship",
  "title5091": 'Friendly reminder:',
  "title5092": 'Currently only supports generating single',
  "title5093": 'I got it',
  "title5094": 'Product cannot be empty',
  "title5095": 'Add Shelf',
  "title5096": 'Add Singly',
  "title5097": 'Add In Bulk',
  "title5098": 'Import  Shelves',
  "title5099": 'Print shelf labels',
  "title5100": 'Shelf specifications',
  "title5101": 'Maximum 100 columns',
  "title5102": 'Number of columns',
  "title5103": 'Maximum 100 layers',
  "title5104": 'Layer',
  "title5105": 'Hyphen',
  "title5106": 'Preview',
  "title5107": 'Shelf bottom',
  "title5108": 'Please enter quantity',
  "title5109": 'Please enter a positive integer between 1-100',
  "title5110": 'All',
  "title5111": 'Empty',
  "title5112": 'stored',
  "title5113": 'Full',
  "title5114": 'Clearable',
  "title5115": 'Please select an item',
  "title5116": '?',
  "title5117": 'Failure',
  "title5118": 'Avg Daily Sales',
  "title5119": 'Sea freight days',
  "title5120": 'Additional stocking days',
  "title5121": 'Average daily sales in the past 15 days',
  "title5122": 'Allocation in transit',
  "title5123": 'Scan to Stock-in',
  "title5124": 'Print label',
  "title5125": 'Shipping days',
  "title5126": 'Out-of-stock quantity',
  "title5127": 'Store profit per day',
  "title5128": 'Daily store profit number is',
  "title5129": 'Daily store profit data item',
  "title5130": 'Scan operation',
  "title5131": 'Scan and Ship',
  "title5132": 'Product amount',
  "title5135": 'Shipping Label',
  "title5136": 'Pick List',
  "title5137": 'State',
  "title5138": 'County',
  "title5139": 'New successful quantity:',
  "title5140": 'New failed quantity:',
  "title5141": "错误日志数据项",
  "title5142": "访问日志数据项",
  "title5143": "匹配的",
  "title5144": "的对象内容",
  "title5145": "资源路径",
  "title5146": "重复的问题。",
  "title5147": "模板过于复杂，不利于胖友二次开发",
  "title5148": "折扣",
  "title5149": "随机",
  "title5150": "为",
  "title5151": "时需要添加",
  "title5152": "时可选择性添加",
  "title5153": "领取之日起或者次日",
  "title5154": "天内有效",
  "title5155": "优惠券仅原价购买商品时可用",
  "title5156": "满减",
  "title5157": "随机不能为空",
  "title5158": "有门槛不能为空",
  "title5159": "代表无限制不能为空",
  "title5160": "当",
  "title5161": "时需要添加不能为空",
  "title5162": "时可选择性添加不能为空",
  "title5163": "领取之日固定日期后过期",
  "title5164": "领取次日固定日期后过期不能为空",
  "title5165": "为2或者3时需要添加",
  "title5166": "天内有效不能为空",
  "title5167": "是不能为空",
  "title5168": "开启不能为空",
  "title5169": "天提醒不能为空",
  "title5170": "不限制",
  "title5171": "优惠券仅原价购买商品时可用不能为空",
  "title5172": "天！",
  "title5173": "开头",
  "title5174": "的账号不能为空",
  "title5175": "的模板编号不能为空",
  "title5176": "赋予专业身份",
  "title5177": 'For example: You are a senior marketing master',
  "title5178": 'What format is needed and output content.',
  "title5179": 'Evaluation cost',
  "title5180": 'Profit monthly report help information',
  "title5181": 'Check order:',
  "title5182": 'Check if the order matches or the cost is maintained, in',
  "title5183": 'Profit analysis - Order profit details',
  "title5184": 'Maintenance of orders screened for 【no product cost】',
  "title5185": 'Mark evaluation and hold orders',
  "title5186": 'In order management, mark orders that need evaluation or holding accordingly',
  "title5187": 'Order management - Order processing',
  "title5188": 'Set repayment exchange rate',
  "title5189": 'Adjust according to the latest exchange rate settings to ensure accurate calculation of repayment amount',
  "title5190": 'Profit analysis - Profit monthly report - Repayment exchange rate setting',
  "title5191": 'Evaluation exchange rate setting error',
  "title5192": 'When the evaluation payment exchange rate setting is incorrect, in',
  "title5193": 'Settings - Evaluation Parameters',
  "title5194": 'Make changes inside, after completion of modifications, in',
  "title5195": 'Find the corresponding order and select 【Batch operation - Refresh latest cost】.',
  "title5196": 'When any of the above content is modified, you need to click the 【Update Profit】button to update profit data.',
  "title5197": 'The system will automatically update data on the 1st of every month. If you need to calculate in advance, click the 【Update Profit】button to update the data.',
  "title5198": 'Empty package fee',
  "title5199": 'Verify the original phone number',
  "title5200": 'Verify',
  "title5201": 'New phone number',
  "title5202": 'Phone number',
  "title5203": 'Incorrect verification code, please re-enter',
  "title5204": 'Parameters, cannot view',
  "title5205": 'Leave information',
  "title5206": 'Day',
  "title5207": 'files, selected this time',
  "title5208": 'files, total selected',
  "title5209": 'files',
  "title5210": 'Also delete its subgroups and adjust the grouping of applied data to ungrouped.',
  "title5211": 'Error orders',
  "title5212": 'The receiving warehouse will automatically select the default warehouse, not the outbound warehouse of one of the orders. Scanning a single package twice can perform an inbound operation. The maximum limit for scanning once is to handle 500 return packages.',
  "title5213": 'Is it in stock?',
  "title5215": 'None of them are in stock',
  "title5216": 'Receiving Warehouse',
  "title5217": 'Scan or enter the order number',
  "title5218": 'Tracking No.',
  "title5219": 'Batch import waybill number',
  "title5220": 'Scanning result',
  "title5221": 'Please enter the return order number',
  "title6000": 'Please enter the return waybill number',
  "title6001": 'Scan or enter the return order number',
  "title6002": 'Return Tracking No',
  "title6003": 'Please scan or enter the return order number first',
  "title5222": 'No scanning data available',
  "title5223": 'Marketplace',
  "title5224": 'Return No',
  "title5225": 'Return Tracking No',
  "title5226": 'Product',
  "title5228": 'Receive Shelf',
  "title5230": 'Action',
  "title5231": 'Remove',
  "title5232": 'Friendly reminder: Once processed into storage, the return order cannot be canceled for storage',
  "title5233": 'Please operate with caution',
  "title5234": 'Close',
  "title5235": 'This order is already in the scanning list',
  "title5236": 'Successfully stored',
  "title5237": 'After scanning for delivery, the package will automatically move to the "To be picked up" list',
  "title5238": 'Confirm delivery',
  "title5240": 'Scanning successful',
  "title5241": 'Clear and continue scanning',
  "title5242": 'Scanning',
  "title5243": 'Tracking No.',
  "title5244": "Shipping method:",
  "title5245": 'Please connect the scanner to scan packages',
  "title5246": 'Information',
  "title5247": 'Quantity',
  "title5248": 'Scan Records',
  "title5249": 'Scanned：',
  "title5250": 'Shipped Successfully',
  "title5251": 'Order Number',
  "title5252": 'Total',
  "title5253": 'Shipping Status',
  "title5254": 'Shipment Successful',
  "title5255": 'Scanning once indicates shipping once, scanning twice indicates displaying order information for the first time, and scanning twice indicates shipping.',
  "title5256": 'Scan Count',
  "title5257": 'Scan Once',
  "title5258": 'Scan Twice',
  "title5259": 'Scan error, please click the input box with the mouse to turn it blue',
  "title5260": 'System Prompt',
  "title5261": 'Confirm Clear All Scan Records',
  "title5262": 'Amount Details',
  "title5263": 'Current Currency',
  "title5264": 'Switch',
  "title5265": 'Current Exchange Rate:',
  "title5266": 'Buyer Actual Payout',
  "title5267": 'Subtotal After Discount',
  "title5268": 'Subtotal Before Discount',
  "title5269": 'Seller Item Discount',
  "title5270": 'Item Discount',
  "title5271": 'Shipping Fee After Discount',
  "title5272": 'Shipping Fee Before Discount',
  "title5273": 'Seller Shipping Fee Discount',
  "title5274": 'Shipping Fee Discount',
  "title5275": 'Tax',
  "title5276": 'Platform Release Amount',
  "title5278": 'Subtotal after seller discount',
  "title5279": 'Refund subtotal after seller discount',
  "title5280": 'Total expenses',
  "title5281": 'Transaction Fees',
  "title5282": 'Commission',
  "title5283": 'Seller Shipping Fee',
  "title5284": 'User Shipping Fee',
  "title5285": 'Actual Shipping Fee',
  "title5286": 'Shipping Subsidy',
  "title5287": 'Actual Return Shipping Fee',
  "title5288": 'Refunded Customer Shipping Fee',
  "title5289": 'Influencer Commission',
  "title5290": 'Service Fee',
  "bonus_cashback_service_fee": 'Bonus cashback service fee',
  "title5292": 'Estimated Payment Amount',
  "title5293": 'Release Amount',
  "title5296": 'Packaging cost',
  "title5297": 'Estimated shipping cost',
  "title5298": 'Estimated handling fee',
  "title5301": 'Profit/Loss',
  "title5302": 'Profit Rate',
  "title5303": 'Shipping fee',
  "title5304": 'Original shipping cost',
  "title5305": 'Voucher',
  "title5306": 'Seller voucher',
  "title5307": 'Product amount',
  "title5308": 'Cross-border logistics cost (actual)',
  "title5311": 'Shopee Shipping Refund',
  "title5312": 'Seller Coupon Amount',
  "title5313": 'Fees and Charges',
  "title5314": 'Affiliate Marketing Commission',
  "title5315": 'Original Shipping Fee',
  "title5316": 'Platform Shipping Discount',
  "title5317": 'Seller Discount',
  "title5318": 'Adjustment Fee',
  "title5319": 'Financial Information',
  "title5320": 'Parcel Number',
  "title5321": 'Certificate',
  "title5322": 'Key Value',
  "title5323": 'Success',
  "title5324": 'Address Cannot Be Empty',
  "title5325": 'Client',
  "title5326": 'To',
  "title5327": 'characters',
  "title5328": 'Such as',
  "title5329": 'file with the suffix',
  "title5330": 'Cross-Month Adjustment',
  "title5332": 'Maximum group days cannot be empty',
  "title5333": 'Recently',
  "title5334": 'Total sales in the last days',
  "title5335": 'Please enter order content',
  "title10000": 'Home',
  "title10001": 'Hour, Allowed Wildcards',
  "title10002": 'Period Starts from',
  "title10003": 'Hour',
  "title10004": 'From',
  "title10005": 'Starting on the Hour, Every',
  "title10006": 'Execute Once an Hour',
  "title10007": 'Specify',
  "title10008": 'Multiple Selections Allowed',
  "title10009": 'Minute, Allowed Wildcards',
  "title10010": 'Minute',
  "title10011": 'Starting on the Minute, Every',
  "title10012": 'Execute Once a Minute',
  "title10013": 'Second',
  "title10014": 'Day',
  "title10015": 'Month',
  "title10016": 'Week',
  "title10017": 'Year',
  "title10018": 'Time Expression',
  "title10019": 'Expression',
  "title10021": 'Reset',
  "title10022": 'Close',
  "title10023": 'From Component',
  "title10024": 'Changed',
  "title10025": 'Ready to Restore',
  "title10026": 'One',
  "title10027": 'Two',
  "title10028": 'Three',
  "title10029": 'Four',
  "title10030": 'Five',
  "title10031": 'Six',
  "title10032": 'Last 5 Run Times',
  "title10033": 'In Calculated Results',
  "title10034": 'No Results Meeting Conditions!',
  "title10035": 'Only the above in the last 100 years',
  "title10036": 'Results!',
  "title10037": 'Day, Allowed Wildcards',
  "title10038": 'Not Specified',
  "title10039": 'Starting on the, Every',
  "title10040": 'Execute Once a Day',
  "title10041": 'Every Month',
  "title10042": 'Nearest Working Day of the Month',
  "title10043": 'Last Day of the Month',
  "title10044": 'Second, Allowed Wildcards',
  "title10045": 'Starting on the Second, Every',
  "title10046": 'Execute Once a Second',
  "title10047": 'Week, Allowed Wildcards',
  "title10048": 'Period Starts from the Week',
  "title10049": 'The',
  "title10050": 'Day of the Week',
  "title10051": 'Last Weekday of the Month',
  "title10052": 'Monday',
  "title10053": 'Tuesday',
  "title10054": 'Wednesday',
  "title10055": 'Thursday',
  "title10056": 'Friday',
  "title10057": 'Saturday',
  "title10058": 'Sunday',
  "title10059": 'Do not fill in, allowed wildcards',
  "title10060": 'Per year',
  "title10061": 'Starting from year, every',
  "title10062": 'Year execute once',
  "title10063": 'Month, allowed wildcards',
  "title10064": 'Starting from month, every',
  "title10065": 'Monthly execution once',
  "title10066": 'Document address',
  "title10067": 'Please enter content',
  "title10068": 'Uploaded file size cannot exceed',
  "title10069": 'Failed to insert image',
  "title10070": 'Please enter the link address',
  "title10071": 'Save',
  "title10072": 'Please enter video address',
  "title10073": 'Text',
  "title10074": 'Title 1',
  "title10075": 'Title 2',
  "title10076": 'Title 3',
  "title10077": 'Title 4',
  "title10078": 'Title 5',
  "title10079": 'Title 6',
  "title10080": 'Standard font',
  "title10081": 'Serif font',
  "title10082": 'Monospaced font',
  "title10083": 'Single-line text',
  "title10084": 'Incorrect phone number format',
  "title10085": 'Please enter phone number',
  "title10086": 'Choose file',
  "title10087": 'Please upload',
  "title10088": 'Size not exceed',
  "title10089": 'Format is',
  "title10090": 'file',
  "title256": 'Delete',
  "title10092": 'Incorrect file format',
  "title10093": 'File format',
  "title10094": 'Uploading file, please wait',
  "title10095": 'Uploaded file quantity cannot exceed',
  "title10096": 'Pieces',
  "title10097": 'Failed to upload image, please try again',
  "title10098": 'Please enter',
  "title10099": 'Multi-line text',
  "title10100": 'Password',
  "title10101": 'Counter',
  "title10102": 'Editor',
  "title10103": 'Dropdown select',
  "title10104": 'Option one',
  "title10105": 'Option two',
  "title10106": 'Please select',
  "title10107": 'Cascading select',
  "title10108": 'Option 1',
  "title10109": 'Option 1-1',
  "title10110": 'Radio button group',
  "title10111": 'Checkbox group',
  "title10112": 'Switch',
  "title10113": 'Slider',
  "title10114": 'Select Time',
  "title10115": 'Time range',
  "title10116": 'To',
  "title10117": 'Start time',
  "title10118": 'End time',
  "title10119": 'Date selection',
  "title10120": 'Date range',
  "title10121": 'Start date',
  "title10122": 'End date',
  "title10123": 'Rating',
  "title10124": 'Color selection',
  "title10125": 'Upload',
  "title10126": 'Click to upload',
  "title10127": 'Row container',
  "title10128": 'Button',
  "title10129": 'Primary button',
  "title10130": 'Table',
  "title10131": 'Under development',
  "title10133": 'Address',
  "title10135": 'Operation',
  "title10136": 'Please enter icon name',
  "title10137": 'Please select at least one',
  "title10138": 'Cannot be empty',
  "title10139": 'File size exceeds',
  "title10140": 'Should select',
  "title10141": 'Type of file',
  "title10142": ' Search ',
  "title10143": 'Submit',
  "title10144": 'Can only upload up to',
  "title10145": 'of',
  "title10146": 'files',
  "title10147": 'Preview',
  "title10148": 'Image format files',
  "title10149": 'The size of the uploaded avatar image cannot exceed',
  "title10150": 'Uploading image, please wait',
  "title10151": 'Today',
  "title10152": 'Yesterday',
  "title10153": '7 Days',
  "title10154": 'Last 14 days',
  "title10155": 'Last 30 days',
  "title10156": 'This month',
  "title10157": 'Last month',
  "title10158": 'Last 6 months',
  "title10159": 'Last year',
  "title10160": 'Status',
  "title10161": 'Processing, please do not close the current window',
  "title10162": 'Completed',
  "title10163": 'Processing data',
  "title10164": 'Export',
  "title10165": "Do not close the current window during data export, you can download the report after the export is completed",
  "title10166": 'Download',
  "title10167": 'Close',
  "title10168": 'Ready to export',
  "title10169": 'Exporting',
  "title10170": 'Processing completed, download available',
  "title10171": 'Title',
  "title10172": 'Sync',
  "title10173": 'Do not close the current window while syncing data. You can close the window after syncing is complete.',
  "title10174": 'Use after upgrading to a paid user.',
  "title10175": 'Training Request',
  "title10176": 'View Help',
  "title10177": 'Upgrade Now',
  "title10178": "No match with:",
  "title10179": "Matching:",
  "title10180": 'Your browser does not support full screen',
  "title10181": 'Hide Search',
  "title10182": 'Show Search',
  "title10183": 'Refresh',
  "title10184": 'Show Column',
  "title10185": 'Show',
  "title10186": 'Hide',
  "title10187": 'Merge and Continue',
  "title10188": 'More',
  "title10189": 'Initialization Tutorial',
  "title10190": 'Easily manage shops, improve efficiency, and reduce business costs.',
  "title10191": 'Operation Tutorial',
  "title10192": 'Authorize Three Warehouses',
  "title10194": 'Please enter store alias',
  "title10195": 'Warehouse',
  "title10196": 'Select warehouse',
  "title10197": 'When you register your Xinzhan account, the system automatically generates a warehouse for you and sets it as the default warehouse. If you need to modify it, please go to [Inventory-Inventory Management-Warehouse List] to set it.',
  "title10198": 'Authorize store',
  "title10199": 'Add product',
  "title10200": 'Maintain local product information in the system for profit, cost data statistics',
  "title10201": 'Merchant SKU Mapping',
  "title10202": 'Match online products, match local products with online platforms',
  "title10203": 'Match',
  "title10204": 'Online product pairing',
  "title10205": 'Warehouse',
  "title10206": 'Initialize inventory for subsequent purchases, replenishments, shipments, and other operations',
  "title10207": 'Add warehouse',
  "title10208": 'Personnel management',
  "title10209": 'Configure corresponding permissions for roles such as operations, procurement, finance, and supervisors in your company',
  "title10210": 'Parameter settings',
  "title10211": 'Set up currency display for your system according to country exchange rates, evaluate order exchange rate settings',
  "title10212": 'Exchange Settings',
  "title10213": 'Do not remind',
  "title10214": 'Enable guide',
  "title10215": 'Back',
  "title10216": 'Next',
  "title10217": 'Shop alias cannot be empty',
  "title10218": 'Warehouse cannot be empty',
  "title10219": "Beginner's guide ",
  "title10220": 'Welcome',
  "title10221": 'Shop authorization',
  "title10222": 'Add shop authorization',
  "title10223": 'Authorize your shop to the system for synchronization of shop orders, online products, etc.',
  "title10224": 'Stores can be authorized through [System-Authorization Management-Store Management]. Add new store authorizations.',
  "title10225": 'The third-party authorization page is the official page of the store. Seller account and password are only used for store authorization, Xinzhan will not save your password.',
  "title10226": 'Product',
  "title10227": 'Can be done through [Inventory-Product',
  "title10228": 'Product',
  "title10229": 'Create, manually add, import spreadsheet to create products',
  "title10230": 'Can create individual',
  "title10231": 'Products and combinations',
  "title10232": 'Products, need to fill in standardized format when creating products',
  "title10233": 'With online',
  "title10234": 'Matching, used to automatically deduct inventory in the system, and statistics related data',
  "title10235": 'Product pairing can be done through [Inventory-Product',
  "title10236": 'Online product pairing setting',
  "title10237": 'Own Warehouse: When registering for a Xinzhan account, the system automatically generates a warehouse for you and sets it as the default warehouse. If you need to modify it, please go to [Inventory-Warehouse Management-Warehouse List] to set it.',
  "title10238": 'Third-party Warehouse: Authorize third-party warehouses. In [Inventory-Warehouse Management-Warehouse List], create a new warehouse and select the authorized third-party warehouse during creation.',
  "title10239": 'After authorizing third-party warehouses, orders can be pushed to third-party warehouses, and support for synchronizing product inventory from third-party warehouses. Purchase orders also support automatic push for inbound processing.',
  "title10240": 'Personnel Settings',
  "title10241": 'Personnel management can be set through [System-Account Management-Sub-account Management]. Setting sub-accounts can allocate different role permissions, assign store permissions, warehouse permissions, etc.',
  "title10242": 'Role settings can be created through [System-Account Management-Role Management].',
  "title10243": 'Exchange rate settings can be modified through [System-Settings-Exchange Rate Settings]. The system defaults to real-time exchange rates, and you can customize the exchange rates. After setting the exchange rate, orders will display prices according to the latest settings.',
  "title10244": 'Evaluation parameters can be set through [System-Settings-Evaluation Parameters]. After setting, the cost of evaluation orders will be calculated according to the set parameters.',
  "title10245": 'No permission',
  "title10246": 'Please add products first',
  "title10247": 'Please complete store authorization first',
  "title10248": 'Please complete security verification',
  "title10249": 'Drag the slider below to complete the puzzle',
  "title10250": '中文',
  "title10251": 'Current member',
  "title10252": 'Purchase',
  "title10253": 'Basic information',
  "title10254": 'My package',
  "title10255": 'My orders',
  "title10256": 'Exit role play',
  "title10257": 'Log out',
  "title10258": "林珍",
  "title10259": 'Please enter login password',
  "title10260": 'Login using another account',
  "title10261": 'All rights reserved',
  "title10262": 'Min',
  "title10263": 'Backup number 2023007214-2',
  "title10264": 'Are you sure you want to log out and exit the system?',
  "title10265": 'Are you sure you want to exit role play?',
  "title10266": 'Prompt',
  "title10267": "消息启动事件",
  "title10268": "定时启动事件",
  "title10269": "条件启动事件",
  "title10270": "信号启动事件",
  "title10271": "错误启动事件",
  "title10272": "升级启动事件",
  "title10273": "补偿启动事件",
  "title10274": "非中断",
  "title10275": "测试按钮1",
  "title10276": "测试按钮1】点击事件里可以访问当前表单：",
  "title10277": "所以",
  "title10278": "可以拿到当前表单的",
  "title10279": "组件",
  "title10280": "表单的",
  "title10281": "提交数据：",
  "title10282": "在这里输入文字",
  "title10283": "配置文档参阅：",
  "title10284": "验证成功",
  "title10285": "验证失败",
  "title10286": "请依次点击【",
  "title10287": "向右滑动完成验证",
  "title10288": "主题风格设置",
  "title10289": "图标",
  "title10290": "主题颜色",
  "title10291": "系统布局配置",
  "title10292": "开启",
  "title10293": "固定",
  "title10294": "动态标题",
  "title10295": "保存配置",
  "title10296": "重置配置",
  "title10297": "正在保存到本地，请稍候",
  "title10298": "正在清除设置缓存并刷新，请稍候",
  "title10299": 'xinjian',
  "title10300": "刷新页面",
  "title10301": "关闭当前",
  "title10302": "关闭其他",
  "title10303": "关闭左侧",
  "title10304": "关闭右侧",
  "title10305": "全部关闭",
  "title10306": "测试任务",
  "title10307": "打开文件",
  "title10308": "下载为",
  "title10309": "下载文件",
  "title10310": "退出模拟",
  "title10311": "开启模拟",
  "title10312": "模拟",
  "title10313": "向左对齐",
  "title10314": "向右对齐",
  "title10315": "向上对齐",
  "title10316": "向下对齐",
  "title10317": "水平居中",
  "title10318": "垂直居中",
  "title10319": "缩小视图",
  "title10320": "放大视图",
  "title10321": "重置视图并居中",
  "title10322": "撤销",
  "title10323": "恢复",
  "title10324": "重新绘制",
  "title10325": "保存模型",
  "title10326": "业务流程",
  "title10327": "请按住",
  "title10328": "键选择多个元素对齐",
  "title10329": "自动对齐可能造成图形变形，是否继续？",
  "title10330": "警告",
  "title10331": "保存模型失败，请重试！",
  "title10332": "发起人：",
  "title10333": "部门：",
  "title10334": "创建时间：",
  "title10335": "审批人：",
  "title10336": "结果：",
  "title10337": "结束时间：",
  "title10338": "审批建议：",
  "title10339": "常规",
  "title10340": "消息与信号",
  "title10341": "流转条件",
  "title10342": "表单",
  "title10343": "友情提示：使用",
  "title10344": "流程表单",
  "title10345": "替代，提供更好的表单设计功能",
  "title10346": "任务",
  "title10347": "多实例",
  "title10348": "执行监听器",
  "title10349": "任务监听器",
  "title10350": "扩展属性",
  "title10351": 'Others',
  "title10352": 'Grant professional identity',
  "title10353": 'Such as: You are a senior marketing master',
  "title10354": 'What format is needed and output content',
  "title10355": 'How to use?',
  "title10356": 'Evaluation cost',
  "title10357": 'Profit monthly report help information',
  "title10358": 'Check orders:',
  "title10359": 'Check if orders are paired or costs maintained, in',
  "title10361": 'Maintain orders with 【No product cost】 filter',
  "title10362": 'Mark assessment and hold orders:',
  "title10363": 'In order management, mark orders that require assessment or hold accordingly',
  "title10364": 'Order Management - Order Processing',
  "title10365": 'Set repayment exchange rate:',
  "title10366": 'Adjust based on latest exchange rate settings to ensure accurate calculation of repayment amount',
  "title10367": 'Profit Analysis - Profit Monthly Report - Repayment Exchange Rate Settings',
  "title10368": 'Evaluation exchange rate setting error:',
  "title10369": 'When the evaluation payment exchange rate setting is incorrect, in',
  "title10370": 'Settings - Evaluation Parameters',
  "title10371": 'Make changes inside, after modification, in',
  "title10372": 'Find the corresponding order and choose 【Batch Operation - Refresh Latest Cost】.',
  "title10373": 'Friendly reminder:',
  "title10374": 'When any of the above content is modified, you need to click the 【Update Profit】button to update profit data.',
  "title10375": 'The system will automatically update the data on the 1st day of each month. If you need to calculate in advance, click the 【Update Profit】button to update the data.',
  "title10376": 'Do not show again',
  "title10377": 'Got it',
  "title10378": 'Letters must include uppercase',
  "title10379": 'Letters, numbers, and characters must include at least 2 types',
  "title10380": 'days',
  "title10381": 'files selected this time:',
  "title10382": 'files selected in total:',
  "title10383": 'files',
  "title10384": 'Also delete its subgroups and adjust the grouping of applied data to ungrouped.',
  "title10385": 'Incorrect orders',
  "title10386": 'Scan to Stock-in',
  "title10387": 'The receiving warehouse will automatically select the default warehouse, not the outbound warehouse of one of the orders. Scanning a single package twice can perform an inbound operation. The maximum limit for scanning once is to handle 500 return packages.',
  "title10388": 'Is it in stock?',
  "title10390": 'None to be stored',
  "title10391": 'Receiving Warehouse',
  "title10392": 'Scan or enter order number',
  "title10393": 'Tracking No.',
  "title10394": 'Bulk import waybill number',
  "title10395": 'Scan results',
  "title10396": 'No scan data available',
  "title10397": 'Please connect the scanner to scan the package',
  "title10398": 'Marketplace',
  "title10399": 'Return No',
  "title10400": 'Return Tracking No',
  "title10401": 'Product',
  "title10403": 'Receive Shelf',
  "title10405": 'Remove',
  "title10407": 'Scanning once means scanning once for delivery, scanning twice means displaying order information for the first time, and scanning for delivery the second time.',
  "title10408": 'Number of scans',
  "title10409": 'Amount details',
  "title10410": 'Current currency',
  "title10411": 'Switch',
  "title10412": 'Current exchange rate:',
  "title10413": 'Buyer Actual Payout',
  "title10414": 'Subtotal after Discount',
  "title10415": 'Subtotal before Discount',
  "title10416": "Seller's Product Discount ",
  "title10417": 'Product Discount',
  "title10418": 'Shipping Fee after Discount',
  "title10419": 'Shipping Fee before Discount',
  "title10420": "Seller's Shipping Fee Discount",
  "title10421": 'Shipping Fee Discount',
  "title10422": 'Tax',
  "title10423": 'Platform Release Amount',
  "title10425": 'Subtotal after seller discount',
  "title10426": 'Subtotal after seller discount refund',
  "title10427": 'Total expenses',
  "title10428": 'Transaction Fees',
  "title10429": 'Commission',
  "title10430": 'Seller Shipping Fee',
  "title10431": 'Buyer Shipping Fee',
  "title10432": 'Actual Shipping Fee',
  "title10433": 'Shipping Subsidy',
  "title10434": 'Actual Return Shipping Fee',
  "title10435": 'Refund Customer Shipping Fee',
  "title10436": 'Influencer Commission',
  "title10437": 'Service Fee',
  "title10438": 'Estimated Refund Amount',
  "title10439": 'Release Amount',
  "title10441": 'Packaging fee',
  "title10442": 'Estimated shipping cost',
  "title10443": 'Estimated handling fee',
  "title10444": 'Go to Settings',
  "title10446": 'Profit/Loss',
  "title10447": 'Profit Rate',
  "title10448": 'Product amount',
  "title10449": 'Shipping Fee',
  "title10450": 'Original shipping cost',
  "title10451": 'Coupon',
  "title10452": 'Seller coupon',
  "title10453": 'Product amount',
  "title10454": 'Cross-border logistics cost (actual)',
  "title10457": 'Shopee shipping refund',
  "title10458": 'Seller coupon amount',
  "title10459": 'Costs and fees',
  "title10460": 'Affiliate marketing commission expense',
  "title10461": 'Original shipping cost',
  "title10462": 'Platform shipping discount',
  "title10463": 'Discount',
  "title10464": 'Seller discount',
  "title10465": 'Adjustment fee',
  "title10466": 'Financial information',
  "title10467": 'Scan and Ship',
  "title10468": 'After scanning for delivery, the parcel will automatically move to the [To be collected] list',
  "title10469": 'Confirm shipment',
  "title10470": 'Scanning success',
  "title10471": 'Clear and continue scanning',
  "title10472": 'Scanning',
  "title10473": 'Tracking No.',
  "title10474": "Shipping method:",
  "title10475": 'Information',
  "title10476": 'Quantity',
  "title10477": 'Scan Records',
  "title10478": 'Scanned',
  "title10479": 'Successfully shipped:',
  "title10480": 'Order number',
  "title10481": 'Total',
  "title10482": 'Shipment status',
  "title10483": 'Shipment successful',
  "title10484": 'Scan once',
  "title10485": 'Scan twice',
  "title10486": 'Scanning error, please click on the input box with the mouse first, the input box turns blue',
  "title10487": 'System prompt',
  "title10488": 'Are you sure to clear all scanning records?',
  "title10489": 'Success',
  "title10490": 'Certificate',
  "title10491": 'Key value',
  "title10492": 'Parcel number',
  "title10493": "如何实现实现会签、或签？",
  "title10494": "流程标识",
  "title10495": "请输入流标标识",
  "title10496": "流程名称",
  "title10497": "请输入流程名称",
  "title10498": "流程标识不能为空",
  "title10499": "流程名称不能为空",
  "title10500": "不满足",
  "title10501": "规则，所以不进行赋值",
  "title10502": "满足",
  "title10503": "规则，所以进行赋值",
  "title10504": "表单标识",
  "title10505": "业务标识",
  "title10506": "无",
  "title10507": "表单字段",
  "title10508": "序号",
  "title10509": "字段名称",
  "title10510": "字段类型",
  "title10511": "默认值",
  "title1593": "编辑",
  "title10513": "添加字段",
  "title10514": "字段配置",
  "title10515": "字段",
  "title10516": "类型",
  "title10517": "请选择字段类型",
  "title10518": "类型名称",
  "title10519": "时间格式",
  "title10520": "枚举值列表：",
  "title10521": "添加枚举值",
  "title10522": "枚举值编号",
  "title10523": "枚举值名称",
  "title10524": "约束条件列表：",
  "title10525": "添加约束",
  "title10526": "约束名称",
  "title10527": "约束配置",
  "title10528": "字段属性列表：",
  "title10529": "添加属性",
  "title10530": "属性编号",
  "title10531": "属性值",
  "title10532": "取",
  "title10534": "保",
  "title10535": "存",
  "title10536": "编号",
  "title10537": "配置",
  "title10538": "值",
  "title10539": "确 定",
  "title10540": "",
  "title10541": "长整型",
  "title10542": "字符串",
  "title10543": "布尔类",
  "title10544": "日期类",
  "title10545": "枚举类",
  "title10546": "自定义类型",
  "title10547": "属性配置",
  "title10548": "枚举值配置",
  "title10549": "约束条件配置",
  "title10550": "流转类型",
  "title10551": "普通流转路径",
  "title10552": "默认流转路径",
  "title10553": "条件流转路径",
  "title10554": "条件格式",
  "title10555": "脚本",
  "title10556": "脚本语言",
  "title10557": "脚本类型",
  "title10558": "内联脚本",
  "title10559": "外部脚本",
  "title10560": "资源地址",
  "title10561": "事件类型",
  "title10562": "监听器类型",
  "title10563": "添加监听器",
  "title10564": "类",
  "title10565": "代理表达式",
  "title10566": "脚本格式",
  "title10567": "请填写脚本格式",
  "title10568": "请选择脚本类型",
  "title10569": "脚本内容",
  "title10570": "请填写脚本内容",
  "title10571": "请填写资源地址",
  "title10572": "定时器类型",
  "title10573": "持续时长",
  "title10574": "循环",
  "title10575": "定时器",
  "title10576": "注入字段：",
  "title10577": "字段值",
  "title10578": "字段名称：",
  "title10579": "字段类型：",
  "title10580": "字段值：",
  "title10581": "表达式：",
  "title10582": "回路特性",
  "title10583": "并行多重事件",
  "title10584": "时序多重事件",
  "title10585": "循环事件",
  "title10586": "循环基数",
  "title10587": "集合",
  "title10588": "元素变量",
  "title10589": "完成条件",
  "title10590": "异步状态",
  "title10591": "异步前",
  "title10592": "异步后",
  "title10593": "排除",
  "title10594": "重试周期",
  "title10595": "确认移除该字段吗？",
  "title10596": "认",
  "title10597": "操作取消",
  "title10598": "确认移除该监听器吗？",
  "title10599": "事件",
  "title10600": "监听器",
  "title10601": "请填写定时器配置",
  "title10602": "元素文档：",
  "title10603": "创建",
  "title10604": "指派",
  "title10605": "完成",
  "title10606": "更新",
  "title10607": "超时",
  "title10608": "属性名",
  "title10609": "属性名：",
  "title10610": "属性值：",
  "title10611": "确认移除该属性吗？",
  "title10612": "消息列表",
  "title10613": "创建新消息",
  "title10614": "消息",
  "title10615": "消息名称",
  "title10616": "信号列表",
  "title10617": "创建新信号",
  "title10618": "信号",
  "title10619": "信号名称",
  "title10620": "创建消息",
  "title10621": "创建信号",
  "title10622": "该消息已存在，请修改",
  "title10623": "后重新保存",
  "title10624": "该信号已存在，请修改",
  "title10625": "异步延续",
  "title10626": 'Cross-month adjustment',
  "title10627": 'Empty package fee',
  "title10629": "追加结束事件",
  "title10630": "追加网关",
  "title10631": "追加任务",
  "title10632": "追加中间抛出事件",
  "title10633": "边界事件",
  "title10634": "激活全局连接工具",
  "title10635": "添加",
  "title10636": "在上面添加道",
  "title10637": "分割成两个道",
  "title10638": "分割成三个道",
  "title10639": "在下面添加道",
  "title10640": "追加补偿活动",
  "title10641": "修改类型",
  "title10642": "使用关联连接",
  "title10643": "使用顺序",
  "title10644": "消息流或者关联连接",
  "title10645": "使用数据输入关联连接",
  "title10646": "激活抓手工具",
  "title10647": "激活套索工具",
  "title10648": "激活创建",
  "title10649": "删除空间工具",
  "title10650": "创建扩展子过程",
  "title10651": "创建中间抛出事件",
  "title10652": "创建池",
  "title10653": "参与者",
  "title10654": "数据对象参考",
  "title10655": "数据存储参考",
  "title10656": "即席",
  "title10657": "发送任务",
  "title10658": "接收任务",
  "title10659": "用户任务",
  "title10660": "手工任务",
  "title10661": "业务规则任务",
  "title10662": "服务任务",
  "title10663": "脚本任务",
  "title10664": "调用活动",
  "title10665": "子流程（折叠的）",
  "title10666": "子流程（展开的）",
  "title10667": "开始事件",
  "title10668": "中间事件",
  "title10669": "结束事件",
  "title10670": "创建开始事件",
  "title10671": "创建结束事件",
  "title10672": "创建任务",
  "title10673": "创建用户任务",
  "title10674": "创建网关",
  "title10675": "创建数据对象",
  "title10676": "创建数据存储",
  "title10677": "创建分组",
  "title10678": "创建中间",
  "title10679": "消息开始事件",
  "title10680": "定时开始事件",
  "title10681": "条件开始事件",
  "title10682": "信号开始事件",
  "title10683": "错误开始事件",
  "title10684": "升级开始事件",
  "title10685": "补偿开始事件",
  "title10686": "消息开始事件（非中断）",
  "title10687": "定时开始事件（非中断）",
  "title10688": "条件开始事件（非中断）",
  "title10689": "信号开始事件（非中断）",
  "title10690": "升级开始事件（非中断）",
  "title10691": "消息中间捕获事件",
  "title10692": "消息中间抛出事件",
  "title10693": "定时中间捕获事件",
  "title10694": "升级中间抛出事件",
  "title10695": "条件中间捕获事件",
  "title10696": "链接中间捕获事件",
  "title10697": "链接中间抛出事件",
  "title10698": "补偿中间抛出事件",
  "title10699": "信号中间捕获事件",
  "title10700": "信号中间抛出事件",
  "title10701": "消息结束事件",
  "title10702": "定时结束事件",
  "title10703": "错误结束事件",
  "title10704": "取消结束事件",
  "title10705": "补偿结束事件",
  "title10706": "信号结束事件",
  "title10707": "终止结束事件",
  "title10708": "消息边界事件",
  "title10709": "消息边界事件（非中断）",
  "title10710": "定时边界事件",
  "title10711": "定时边界事件（非中断）",
  "title10712": "升级边界事件",
  "title10713": "升级边界事件（非中断）",
  "title10714": "条件边界事件",
  "title10715": "条件边界事件（非中断）",
  "title10716": "错误边界事件",
  "title10717": "取消边界事件",
  "title10718": "信号边界事件",
  "title10719": "信号边界事件（非中断）",
  "title10720": "补偿边界事件",
  "title10721": "互斥网关",
  "title10722": "并行网关",
  "title10723": "相容网关",
  "title10724": "复杂网关",
  "title10725": "事件网关",
  "title10726": "转运",
  "title10727": "子流程",
  "title10728": "事件子流程",
  "title10729": "折叠池",
  "title10730": "展开池",
  "title10731": "在",
  "title10732": "里，",
  "title10733": "没有父类",
  "title10734": "没有指定的形状类型",
  "title10735": "流元素必须是池",
  "title10736": "参与者的子类",
  "title10737": "子道大于",
  "title10738": "元素不能为空",
  "title10739": "流程图不符合",
  "title10740": "规范",
  "title10741": "没有可展示的流程图",
  "title10742": "没有可展示的流程",
  "title10743": "协作",
  "title10744": "由",
  "title10745": "引用的",
  "title10746": "元素仍未绘制",
  "title10747": "已被渲染",
  "title10748": "导入",
  "title10749": "失败",
  "title10750": "详情",
  "title10751": "创建者",
  "title10752": "持续异步",
  "title10753": "工作配置",
  "title10754": "工作优先级",
  "title10755": "重试时间周期",
  "title10756": "文档",
  "title10757": "元素文档",
  "title10758": "历史配置",
  "title10759": "历史的生存时间",
  "title10760": "业务",
  "title10761": "校验",
  "title10762": "属性",
  "title10763": "执行监听",
  "title10764": "必须提供一个值",
  "title10765": "资源",
  "title10766": "字段注入",
  "title10767": "扩展",
  "title10768": "输入",
  "title10769": "输出",
  "title10770": "输入参数",
  "title10771": "输出参数",
  "title10772": "参数",
  "title10773": "定时器定义类型",
  "title10774": "定时器定义",
  "title10775": "持续",
  "title10776": "升级",
  "title10777": "错误",
  "title10778": "链接名称",
  "title10779": "条件名称",
  "title10780": "变量名称",
  "title10781": "变量事件",
  "title10782": "多个变量事件以逗号隔开",
  "title10783": "等待完成",
  "title10784": "活动参考",
  "title10785": "版本标签",
  "title10786": "可执行文件",
  "title10787": "扩展任务配置",
  "title10788": "任务优先级",
  "title10789": "外部",
  "title10790": "连接器",
  "title10791": "必须配置连接器",
  "title10792": "连接器编号",
  "title10793": "实现方式",
  "title10794": "结果变量",
  "title10795": "主题",
  "title10796": "配置连接器",
  "title10797": "代理人",
  "title10798": "候选用户",
  "title10799": "候选组",
  "title10800": "到期时间",
  "title10801": "跟踪日期",
  "title10802": "优先级",
  "title10803": "跟踪日期必须符合",
  "title10804": "表达式，如：",
  "title10805": "或者一个",
  "title10806": "标准日期，如：2015-06-26",
  "title10807": "变量",
  "title10808": "候选人起动器配置",
  "title10809": "候选人起动器组",
  "title10810": "这映射到流程定义键。",
  "title10811": "候选人起动器的用户",
  "title10812": "指定多个用户作为逗号分隔的列表。",
  "title10813": "启动",
  "title10814": "指定多个组作为逗号分隔的列表。",
  "title10815": "消息实例",
  "title10816": "外部资源",
  "title10817": "跟踪时间",
  "title10818": "友情提示：任务的分配规则，使用",
  "title10819": "流程模型",
  "title10820": "下的【分配规则】替代，提供指定角色、部门负责人、部门成员、岗位、工作组、自定义脚本等",
  "title10821": "种维护的任务分配维度，更加灵活！",
  "title10822": 'Recent',
  "title10823": 'Total sales in the last days',
  "title10824": 'Maximum days for grouping cannot be empty',
  "title10825": 'Refund Amount',
  "title10826": 'Please enter the name of the third-party service provider',
  "title10827": 'Bulk push',
  "title10828": 'Push selected items',
  "title10829": 'Push all items',


  "magellan_600001": "Home Supplies",
  "magellan_600024": "Kitchenware",
  "magellan_600154": "Textiles & Soft Furnishings",
  "magellan_600942": "Household Appliances",
  "magellan_601152": "Womenswear & Underwear",
  "magellan_601303": "Muslim Fashion",
  "magellan_601352": "Shoes",
  "magellan_601450": "Beauty & Personal Care",
  "magellan_601739": "Phones & Electronics",
  "magellan_601755": "Computers & Office Equipment",
  "magellan_602118": "Pet Supplies",
  "magellan_602284": "Baby & Maternity",
  "magellan_603014": "Sports & Outdoor",
  "magellan_604206": "Toys & Hobbies",
  "magellan_604453": "Furniture",
  "magellan_604579": "Tools & Hardware",
  "magellan_604968": "Home Improvement",
  "magellan_605196": "Automotive & Motorcycle",
  "magellan_605248": "Fashion Accessories",
  "magellan_700437": "Food & Beverages",
  "magellan_700645": "Health",
  "magellan_801928": "Books, Magazines & Audio",
  "magellan_802184": "Kids' Fashion",
  "magellan_824328": "Menswear & Underwear",
  "magellan_824584": "Luggage & Bags",
  "magellan_834312": "Virtual Products",
  "magellan_856720": "Pre-Owned",
  "magellan_951432": "Collectibles",
  "magellan_953224": "Jewelry Accessories & Derivatives",
  "magellan_851848": "Home Organizers",
  "magellan_851976": "Bathroom Supplies",
  "magellan_852104": "Home Decor",
  "magellan_852232": "Home Care Supplies",
  "magellan_852360": "Laundry Tools & Accessories",
  "magellan_852488": "Festive & Party Supplies",
  "magellan_852616": "Miscellaneous Home",
  "magellan_858504": "Tea & Coffeeware",
  "magellan_858632": "Kitchen Knives",
  "magellan_858760": "Barbecue",
  "magellan_858888": "Bar & Wine Utensils",
  "magellan_859016": "Bakeware",
  "magellan_859144": "Cookware",
  "magellan_859272": "Cutlery & Tableware",
  "magellan_859400": "Drinkware",
  "magellan_859528": "Kitchen Utensils & Gadgets",
  "magellan_808328": "Bedding",
  "magellan_809992": "Household Textiles",
  "magellan_811016": "Fabrics & Sewing Supplies",
  "magellan_844168": "Kitchen Appliances",
  "magellan_844808": "Home Appliances",
  "magellan_845064": "Large Home Appliances",
  "magellan_845320": "Commercial Appliances",
  "magellan_842248": "Women's Tops",
  "magellan_842376": "Women's Bottoms",
  "magellan_842504": "Women's Dresses",
  "magellan_842632": "Women's Special Clothing",
  "magellan_842760": "Women's Suits & Overalls",
  "magellan_842888": "Women's Underwear",
  "magellan_843016": "Women's Sleepwear & Loungewear",
  "magellan_601304": "Hijabs",
  "magellan_601310": "Women's Islamic Clothing",
  "magellan_601325": "Men's Islamic Clothing",
  "magellan_601331": "Outerwear",
  "magellan_601339": "Kids' Islamic Clothing",
  "magellan_601343": "Islamic Accessories",
  "magellan_601348": "Prayer Attire & Equipment",
  "magellan_838920": "Islamic Sportswear",
  "magellan_839176": "Umroh Equipment",
  "magellan_900488": "Women's Shoes",
  "magellan_900616": "Men's Shoes",
  "magellan_900744": "Shoe Accessories",
  "magellan_848648": "Makeup",
  "magellan_848776": "Skincare",
  "magellan_848904": "Haircare & Styling",
  "magellan_849032": "Hand, Foot & Nail Care",
  "magellan_849160": "Bath & Body Care",
  "magellan_849288": "Men's Care",
  "magellan_849416": "Personal Care Appliances",
  "magellan_849544": "Eye & Ear Care",
  "magellan_849672": "Nasal & Oral Care",
  "magellan_849800": "Feminine Care",
  "magellan_856208": "Perfume",
  "magellan_981128": "Special Personal Care",
  "magellan_909064": "Phone Accessories",
  "magellan_909192": "Cameras & Photography",
  "magellan_909320": "Audio & Video",
  "magellan_909448": "Gaming & Consoles",
  "magellan_909576": "Smart & Wearable Devices",
  "magellan_909704": "Education Devices",
  "magellan_978952": "Universal Accessories",
  "magellan_984584": "Tablet & Computer Accessories",
  "magellan_995976": "Phones & Tablets",
  "magellan_824840": "Desktop Computers, Laptops & Tablets",
  "magellan_825352": "Desktop & Laptop Components",
  "magellan_826760": "Computer Accessories",
  "magellan_828168": "Data Storage & Software",
  "magellan_829192": "Network Components",
  "magellan_830344": "Office Equipment",
  "magellan_831112": "Office Stationery & Supplies",
  "magellan_812168": "Dog & Cat Food",
  "magellan_812808": "Dog & Cat Furniture",
  "magellan_813960": "Dog & Cat Clothing",
  "magellan_815624": "Dog & Cat Litter",
  "magellan_816392": "Dog & Cat Grooming",
  "magellan_818184": "Dog & Cat Healthcare",
  "magellan_818696": "Dog & Cat Accessories",
  "magellan_819848": "Fish & Aquatic Supplies",
  "magellan_821000": "Reptile & Amphibian Supplies",
  "magellan_821896": "Bird Supplies",
  "magellan_822792": "Small Animal Supplies",
  "magellan_1001992": "Farm Animal & Poultry Supplies",
  "magellan_877320": "Baby Clothing & Shoes",
  "magellan_877576": "Baby Travel Essentials",
  "magellan_877832": "Nursing & Feeding",
  "magellan_878216": "Baby Furniture",
  "magellan_878600": "Baby Safety",
  "magellan_878984": "Baby Toys",
  "magellan_879112": "Baby Care & Health",
  "magellan_879496": "Formula Milk & Baby Food",
  "magellan_880008": "Maternity Supplies",
  "magellan_961928": "Baby Fashion Accessories",
  "magellan_834568": "Sport & Outdoor Clothing",
  "magellan_834696": "Sports Footwear",
  "magellan_834824": "Sports & Outdoor Accessories",
  "magellan_834952": "Ball Sports Equipment",
  "magellan_835080": "Water Sports Equipment",
  "magellan_835208": "Winter Sports Equipment",
  "magellan_835336": "Fitness Equipment",
  "magellan_835464": "Camping & Hiking Equipment",
  "magellan_835592": "Leisure & Outdoor Recreation Equipment",
  "magellan_846224": "Swimwear, Surfwear & Wetsuits",
  "magellan_936712": "Fan Shop",
  "magellan_859656": "Dolls & Stuffed Toys",
  "magellan_859784": "Educational Toys",
  "magellan_859912": "Sports & Outdoor Play",
  "magellan_860040": "Electric & Remote Control Toys",
  "magellan_860168": "Games & Puzzles",
  "magellan_860296": "Classic & Novelty Toys",
  "magellan_860552": "Musical Instruments & Accessories",
  "magellan_951560": "DIY",
  "magellan_871048": "Indoor Furniture",
  "magellan_871176": "Outdoor Furniture",
  "magellan_871304": "Children's Furniture",
  "magellan_871432": "Commercial Furniture",
  "magellan_871560": "Power Tools",
  "magellan_871688": "Hand Tools",
  "magellan_871816": "Measuring Tools",
  "magellan_871944": "Garden Tools",
  "magellan_872072": "Soldering Equipment",
  "magellan_872200": "Tool Organizers",
  "magellan_872328": "Hardware",
  "magellan_980488": "Pumps & Plumbing",
  "magellan_808208": "Solar & Wind Power",
  "magellan_872456": "Lights & Lighting",
  "magellan_872584": "Electrical Equipment & Supplies",
  "magellan_872712": "Kitchen Fixtures",
  "magellan_872840": "Smart Home Systems",
  "magellan_872968": "Building Supplies",
  "magellan_873096": "Bathroom Fixtures",
  "magellan_873224": "Security & Safety",
  "magellan_873352": "Garden Supplies",
  "magellan_809488": "Auto Replacement Parts",
  "magellan_809616": "Motorcycle",
  "magellan_929928": "Car Electronics",
  "magellan_930056": "Car Exterior Accessories",
  "magellan_930184": "Car Interior Accessories",
  "magellan_940296": "Car Repair Tools",
  "magellan_940424": "Car Lights",
  "magellan_940680": "Quads, Motorhomes & Boats",
  "magellan_940808": "Car Washing & Maintenance",
  "magellan_940936": "Motorcycle Accessories",
  "magellan_810128": "Hair Extensions & Wigs",
  "magellan_843144": "Dressmaking Fabrics",
  "magellan_888592": "Wedding Accessories",
  "magellan_905224": "Clothes Accessories",
  "magellan_905352": "Eyewear",
  "magellan_905480": "Watches & Accessories",
  "magellan_905608": "Costume Jewelry & Accessories",
  "magellan_905864": "Hair Accessories",
  "magellan_809744": "Milk & Dairy",
  "magellan_914696": "Beer, Wine & Spirits",
  "magellan_914824": "Drinks",
  "magellan_914952": "Instant Food",
  "magellan_915080": "Staples & Cooking Essentials",
  "magellan_915208": "Baking",
  "magellan_915336": "Snacks",
  "magellan_915464": "Fresh & Frozen Food",
  "magellan_700646": "Food Supplements",
  "magellan_924424": "Medical Supplies",
  "magellan_924552": "Sexual Wellness",
  "magellan_949384": "OTC Medications & Treatments",
  "magellan_950792": "Alternative Medications & Treatments",
  "magellan_927112": "Humanities & Social Sciences",
  "magellan_985736": "Magazines & Newspapers",
  "magellan_986760": "Literature & Art",
  "magellan_989320": "Economics & Management",
  "magellan_989704": "Children's & Infants' Books",
  "magellan_990216": "Science & Technology",
  "magellan_992392": "Lifestyle & Hobbies",
  "magellan_992904": "Education & Schooling",
  "magellan_997384": "Video & Music",
  "magellan_802312": "Boys' Clothes",
  "magellan_803592": "Girls' Clothes",
  "magellan_805128": "Boys' Footwear",
  "magellan_806024": "Girls' Footwear",
  "magellan_806792": "Kids' Fashion Accessories",
  "magellan_839944": "Men's Tops",
  "magellan_840072": "Men's Bottoms",
  "magellan_840328": "Men's Special Clothing",
  "magellan_840456": "Men's Underwear",
  "magellan_840584": "Men's Sleepwear & Loungewear",
  "magellan_840712": "Men's Suits & Overalls",
  "magellan_902408": "Women's Bags",
  "magellan_902536": "Men's Bags",
  "magellan_902664": "Luggage & Travel Bags",
  "magellan_902792": "Functional Bags",
  "magellan_902920": "Bag Accessories",
  "magellan_888720": "Physical Voucher",
  "magellan_996360": "Telecommunications",
  "magellan_856976": "Collectible Trading Cards",
  "magellan_857104": "Fashion Accessories",
  "magellan_857232": "Bags",
  "magellan_857360": "Footwear",
  "magellan_857488": "Menswear",
  "magellan_857616": "Womenswear",
  "magellan_857744": "Refurbished Phones & Electronics",
  "magellan_864656": "Luggage & Travel",
  "magellan_865296": "Watches",
  "magellan_809872": "Contemporary Culture Collectibles",
  "magellan_810000": "Trading Cards & Accessories",
  "magellan_937736": "Sports Collectibles",
  "magellan_952712": "Collectible Coins & Money",
  "magellan_953352": "Entertainment",
  "magellan_954888": "Platinum & Carat Gold",
  "magellan_955016": "Gold",
  "magellan_955144": "Silver",
  "magellan_955272": "Diamond",
  "magellan_955400": "Natural Crystal",
  "magellan_961800": "Non-natural Crystal",
  "magellan_963848": "Jade",
  "magellan_964104": "Ruby, Sapphire & Emerald",
  "magellan_964232": "Semiprecious Stones",
  "magellan_964360": "Artificial Gemstones",
  "magellan_964488": "Pearl",
  "magellan_964616": "Amber",
  "magellan_964744": "Mellite",

  // 2024.3.5 物流功能新增翻译
  "可按物流方式分别设置揽收方式": "Set pick-up method separately according to shipping methods.",
  "物流方式": "Shipping Method",
  "物流名称": "Shipping Name",
  "状态": "Status",
  "操作": "Action",
  "设置": "Settings",
  "揽货方式": "Pick-up Method",
  "快递取件": "Courier Pick-up",
  "自行寄件": "Self-Service Shipping",
  "请选择揽收方式": "Please select a pick-up method",
  "全部": "All",
  "印尼": "Indonesia",
  "马来西亚": "Malaysia",
  "菲律宾": "Philippines",
  "泰国": "Thailand",
  "越南": "Vietnam",
  "中国台湾":"Taiwan, China",
  "新加坡":"Singapore",
  "线上物流": "Online Logistics",
  "操作成功！": "Operation Successful!",
  "打印拣货单(含汇总)":"Print Picking(with summary)",
  "商品SKU": "Product SKU",
  "产品SKU": "Shop SKU",
  "包裹数量": "Package Quantity",
  "SKU数量": "SKU Quantity",
  "货品总数": "Total Products",
  "打印时间": "Print Time",
  "货品信息": "Product Details",
  "变种名称": "Variant Name",
  "货架位": "Shelf Location",
  "数量": "Quantity",
  "包裹信息": "Package Info",
  "包裹号": "Package Number",
  "总计": "Total",
  "打印":"Print",
  "拣货单":"Picking List",
  "包裹数量":"Number of Packages",
  "货品总数":"Total Products",
  "打单时间":"Printing Time",
  "货品信息":"Product Information",
  "买家信息":"Buyer Information",
  "备注信息":"Remarks",
  "总数":"Total",
  "订单号":"Order Number",
  "运单号":"Waybill Number",
  "物流方式":"Shipping Method",
  "店铺名称":"Store Name",
  "货品总数":"Total Products",
  "打印面单和配货单":"Printing Waybill and Packing List",
  "稳定出单的商品，销量下滑":"Stable orders, declining sales",
  "爆款商品":"Hot-selling products",
  "出单较为稳定，销量暴涨的潜力商品":"Potential products with stable orders and booming sales",
  "稳定出单的商品，销量持续上涨":"Products with stable orders and continuous sales growth",
  "上架时间长，销量较低":"Long shelf time, low sales",
  "高退货率":"High return rate",
  "高差评率":"High negative review rate",
  "低回复率":"Low response rate",
  "取消率过高":"High cancellation rate",
  "样品费用":"Sample cost",
  "空包费用":"Empty package cost",
  "测评佣金":"Evaluation commission",
  "测评付款佣金/手续费":"Evaluation payment commission/fee",
  "修改自定义费用":"Modify custom fee",
  "小件包裹":"Small package",
  "测试刷单":"Test brushing",
  "漏单":"Missed order",
  "预售":"Pre-sale",
  "海运":"Sea freight",
  "不发":"Not shipped",
  "补寄":"Resend",
  "已采":"Procured",
  "未分组":"Ungrouped",
  "测评佣金":"Evaluation commission",
  "测评付款佣金/手续费":"Evaluation payment commission/fee",
  "提现支出":"Withdrawal expenses",
  "线下售后退款":"Offline after-sales refund",
  "线下补发运费":"Offline reshipping fee",
  "线下补发成本":"Offline reshipping cost",
  "退件处理费":"Return processing fee",
  "活动包裹费用":"Activity package fee",
  "其他杂费":"Other miscellaneous expenses",
  "仓库费用":"Warehouse expenses",
  "未推送":"Not pushed",
  "推送中":"Pushing",
  "推送失败":"Push failed",
  "待盘点":"Pending inventory",
  "按仓库":"By warehouse",
  "按商品SKU":"By product SKU",
  "调拨计划":"Allocation plan",
  "不分摊":"Not apportioned",
  "按价格分摊":"Apportioned by price",
  "按数量分摊":"Apportioned by quantity",
  "按体积分摊":"Apportioned by volume",
  "待出库":"Pending outbound",
  "已出库":"Outbound",
  "采购入库":"Purchase receipt",
  "销售出库":"Sales issue",
  "手动入库":"Manual receipt",
  "手动出库":"Manual issue",
  "回滚出库":"Rollback issue",
  "导入入库":"Import receipt",
  "盘点入库":"Inventory receipt",
  "盘点出库":"Inventory issue",
  "调拨入库":"Allocation receipt",
  "调拨出库":"Allocation issue",
  "取消调拨入库":"Cancel allocation receipt",
  "三方仓同步入库":"Third-party warehouse synchronous receipt",
  "三方仓同步出库":"Third-party warehouse synchronous issue",
  "退货入库":"Return receipt",
  "到付":"Cash on delivery",
  "现结":"Pay now",
  "分期付款":"Installment payment",
  "周结":"Weekly settlement",
  "月结":"Monthly settlement",
  "到期支付":"Payment on due",
  "未扣除广告费后的利润":"Net profit after deducting advertising costs",
  "排除测评搁置取消未付款的订单量":"Exclude evaluation + shelving + cancellation + unpaid order volume",
  "排除测评搁置取消未付款的销量":"Exclude evaluation + shelving + cancellation + unpaid sales volume",
  "有效销售额释义":"Effective sales amount = Buyer payment + Platform subsidy + Freight subsidy (excluding evaluation + shelving + cancellation + unpaid sales amount)",
  "每次最多可选取50条数据进行打印。":"Maximum of 50 records can be selected for printing each time",
  "导出已选商品": "Export Selected Items",
  "导出全部页商品": "Export All Page Items",
  "导出SKU配对关系": "Export SKU Pairing",
  "显示下架商品":"Display off-sale products",
  "扫描或输入包裹号": "Scan or enter parcel number / waybill number",
  "拆单成功": "Split successful",
  "已确定订单销售额除以已确定订单订单数": "Total sales amount of confirmed orders divided by the total number of confirmed orders",
  "访客数": "Visitors",
  "订单数": "Orders",
  "销售额": "Sales",
  "环比":"MoM (Month-over-Month)	",
  "本地状态是退货退款中和退货订单或退款金额大于0的退款金额":"Local status is return and refund or refund amount greater than 0.",
  "退款金额=本地状态是退货退款中和退货订单或退款金额大于0的退款金额":"Refund amount equals the refund amount when the local status is return and refund or the refund amount is greater than 0.",
  "退货订单量=本地状态是退货退款中和退货订单或退款金额大于0的订单数量":"Return order quantity equals the order quantity when the local status is return and refund or the refund amount is greater than 0.",
  "有效订单量=排除测评+搁置+取消+未付款的订单量":"Valid order quantity equals the quantity of orders excluding evaluation, on hold, canceled, and unpaid.",
  "有效销售额=排除测评+搁置+取消+未付款的销售额":"Valid sales amount equals the sales amount excluding evaluation, on hold, canceled, and unpaid.",
  "销售额=已下单的总销售额（未取消订单销售额+取消订单销售额 ）":"Sales amount equals total sales amount of placed orders (sales amount of uncancelled orders + sales amount of cancelled orders).",
  "订单量=已下单的订单数量":"Order quantity equals quantity of placed orders.",
  "共{cont}条":"Total {cont} items",
  "发现新版本，是否更新":"New version found, update now?",
  "高级版":"Advanced Version",
  "您有62个店铺授权过期，请点击重新授权":"You have 62 store authorizations expired, please click to reauthorize",
  "亏损商品":"Loss-making Products",
  "利润":"Profit",
  "利润偏低":"Low Profit",
  "利润率":"Profit Margin",
  "销量暴跌":"Sales Volume Dropped Sharply",
  "稳定出单的商品，销量下滑":"Stable-ordering products, sales volume declined",
  "销量环比":"Sales Volume Compared",
  "出单较为稳定，销量暴涨的潜力商品":"Products with relatively stable orders, potential for sharp increase in sales volume",
  "销量":"Sales Volume",
  "稳定出单的商品，销量持续上涨":"Products with stable orders, sales volume continues to rise",
  "销量环比":"Sales Volume Month-over-Month",
  "上架时间长，销量较低":"Long time on shelf, low sales volume",
  "高退货率":"High Return Rate",
  "退货率":"Return Rate",
  "高差评率":"High Negative Feedback Rate",
  "差评率":"Negative Feedback Rate",
  "低回复率":"Low Response Rate",
  "回复率":"Response Rate",
  "回应时间":"Response Time",
  "回应时间>1小时":"Response Time >1 Hour",
  "逾期发货率过高":"Excessive Overdue Delivery Rate",
  "逾期发货率":"Overdue Delivery Rate",
  "取消率过高":"High Cancellation Rate",
  "取消率":"Cancellation Rate",
  "联系我们":"Contact Us",
  "修改自定义费用":"Modify Custom Fees",
  "快递取件":"Express Pickup",
  "自行取件":"Self Pickup",
  "未分组":"Uncategorized",
  "更新时间":"Update Time",
  "默认仓库":"Default Warehouse",
  "商品SKU推送失败:2000035000: 授权失败":"Product SKU push failed:2000035000: Authorization failed",
  "商品SKU推送失败:2000035000: 授权失败,请检查appKey和appSecret是否正确":"Product SKU push failed: 2000035000: Authorization failed, please check if appKey and appSecret are correct",
  "调拨计划":"Allocation Plan",
  "在途中":"In transit",
  "部分收货":"Partially received",
  "已完成":"Completed",
  "已取消":"Canceled",
  "待出库":"Pending outbound",
  "已出库":"Shipped",
  "待出库":"Pending shipment",
  "已入库":"Received",
  "订单 销售出库1":"Sales Order Shipment 1",
  "联系我们":"Contact us",
  "联系我们":"Contact us",
  "采购订单推送失败:2000035000: 授权失败":"Purchase order push failed: 2000035000: Authorization failed",
  "采购订单推送失败:2000035000: 客户SKU 不存在。":"Purchase order push failed: 2000035000: Customer SKU does not exist",
  "采购订单推送失败:2000035000: Item not exist: sku = Testing Product K, name = null;":"Purchase order push failed: 2000035000: Item not exist: sku = Testing Product K, name = null",
  "AI模板管理":"AI Template Management",
  "修改":"Edit",
  "AI模板分类管理":"AI Template Category Management",
  "自由会话":"Free Session",
  "模板会话":"Template Session",
  "shopee listing写作与优化6。":"Shopee Listing Writing and Optimization 6",
  "根据提供的关键词、类目、商品卖点信息，为你生成地道流畅的语言表达，提升商品曝光量；使用客观和中立的语言，避免夸张和绝对化的词汇。":"Generate authentic and fluent language expressions for you based on provided keywords, categories, and product selling points, to enhance product exposure; Use objective and neutral language, avoiding exaggerated and absolute terms.",
  "商品特性的条数":"Number of Product Features",
  "商品关键词":"Product Keywords",
  "商品品牌":"Product Brand",
  "商品类目":"Product Category",
  "商品卖点":"Product Selling Points",
  "我的收藏":"My Favorites",
  "知识库":"Knowledge Base",
  "发现新版本，点击更新完成升级。体验新版本":"Discover new version, click to update and complete the upgrade.",
  "系统消息":"System Message",
  "今日不再提醒":"No longer remind today",
  "去授权或删除":"Go to authorize or delete",
  "为避免使用及数据分析受影响，请尽快处理过期的店铺数据，您可以":"To avoid affecting the use and data analysis, please handle the expired store data as soon as possible. You can",
  "已过期的店铺":"Expired store",
  "最多置顶5个":"Top up to 5",
  "综合分析":"Comprehensive analysis",
  "受限页面":"Restricted page",
  "已下单的订单数量 （包含取消和退款订单）":"Number of orders placed (including canceled and refunded orders)",
  "已下单的用户数量（按买家姓名/邮箱去重）":"Number of customers who have placed orders (deduplicated by buyer name/email)",
  "付款客户数":"Number of paying customers",
  "退款客户数":"Number of refund customers",
  "已退款的用户数（包含部分退款）":"Number of users who have been refunded (including partial refunds)",
  "已下单的用户数量（按买家姓名/邮箱去重）":"Number of unique customers who ordered (deduplicated by buyer name/email)",
  "已取消订单数量":"Number of unique customers who ordered (deduplicated by buyer name/email)",
  "仅统计已付款的订单销售数量":"Sales quantity only for paid orders",
  "销售额/客户数":"Sales Amount/Customer",
  "退款金额":"Refund Amount",
  "店铺销售详情":"Store Sales Details",
  "店铺详情":"Store Details",
  "商品销量统计":"Product Sales Statistics",
  "非追加产品主包裹至少保留一个":"Non-additional products at least one main package",
  "扮演":"Runas",


  "批量添加标记":"Batch Add Tags",
  "添加标记":"Add Tags",
  "选择标记":"Select Tag",
  "shopeeCoinsRedeemed":"Shopee Coins Redeemed"
}
