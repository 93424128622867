var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "tableControlBox",
      staticClass: "control-row flex-row",
      style: { height: _vm.height },
    },
    [
      _c(
        "div",
        { staticStyle: { color: "#999", "margin-left": "10px" } },
        [
          _vm.total
            ? _c("span", [
                _vm._v(
                  _vm._s(
                    "" + _vm.$t("title11002") + _vm.total + _vm.$t("title11003")
                  )
                ),
              ])
            : _vm._e(),
          _vm._t("tips", [
            _vm.tips
              ? _c("span", { staticStyle: { "margin-left": "20px" } }, [
                  _vm._v(_vm._s(_vm.tips)),
                ])
              : _vm._e(),
          ]),
          _vm._t("default"),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "flex-row align-center" },
        [
          _vm._t("checkCorner", [
            _c(
              "div",
              { staticClass: "flex-row align-center" },
              [
                _vm.showCheckL
                  ? _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.computedCheckL,
                          callback: function ($$v) {
                            _vm.computedCheckL = $$v
                          },
                          expression: "computedCheckL",
                        },
                      },
                      [_vm._v(_vm._s(_vm.checkLStr || _vm.$t("title11004")))]
                    )
                  : _vm._e(),
                _vm.showCheckR
                  ? _c(
                      "el-checkbox",
                      {
                        model: {
                          value: _vm.computedCheckR,
                          callback: function ($$v) {
                            _vm.computedCheckR = $$v
                          },
                          expression: "computedCheckR",
                        },
                      },
                      [_vm._v(_vm._s(_vm.checkRStr || _vm.$t("title11005")))]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.showCutCurrency && _vm.isClickCurrency
              ? _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", placement: "top" },
                  },
                  [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "flex-col",
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        [
                          _c("span", [_vm._v("温馨提示：")]),
                          _c("span", [
                            _vm._v(
                              "仅支持单地区选择时切换币种，不支持多地区切换。"
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              "切换币种时，根据【设置】-【汇率设置】中的今日执行汇率进行计算。"
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              "无论是选择区间还是某一天的统计数据，都会以今日执行汇率为准。"
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              "需要注意的是，系统同步订单执行的是当时同步时间的汇率。"
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              "因此，在汇率切换后可能存在汇率偏差，不应仅凭此数据作为唯一比较标准。"
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c(
                      "el-button",
                      {
                        class: [
                          "my-cut-currency",
                          _vm.isCutCurrency && "rotate",
                        ],
                        staticStyle: {
                          color: "#1377FF",
                          padding: "0 0 0 15px",
                        },
                        attrs: {
                          disabled: _vm.isClickCurrency,
                          plain: "",
                          size: "small",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.btnClick("currency")
                          },
                        },
                      },
                      [
                        _c("i"),
                        _c("span", [_vm._v(_vm._s(_vm.$t("title1265")))]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showCutCurrency && !_vm.isClickCurrency
              ? _c(
                  "el-button",
                  {
                    class: ["my-cut-currency", _vm.isCutCurrency && "rotate"],
                    staticStyle: { color: "#1377FF", padding: "0 0 0 15px" },
                    attrs: {
                      disabled: _vm.isClickCurrency,
                      plain: "",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.btnClick("currency")
                      },
                    },
                  },
                  [_c("i"), _c("span", [_vm._v(_vm._s(_vm.$t("title1265")))])]
                )
              : _vm._e(),
            _vm.showCheckL || _vm.showCheckR || _vm.showCutCurrency
              ? _c("div", { staticClass: "verticalLine" })
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "flex-row align-center" },
            [
              !_vm.isHideTask
                ? _c("missionCenter", {
                    ref: "mission",
                    staticClass: "btn",
                    attrs: {
                      isHideTask: _vm.isHideTask,
                      isInclude: _vm.isInclude,
                      isImport: _vm.isImport,
                    },
                  })
                : _vm._e(),
              _vm.showCostomCol
                ? _c(
                    "div",
                    {
                      staticClass: "costom-col-btn flex-row",
                      staticStyle: {
                        "align-items": "center",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "colBtn",
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.createCol },
                        },
                        [_vm._v(_vm._s(_vm.$t("title11198")))]
                      ),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            "visible-arrow": false,
                            content: _vm.$t("title11209"),
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                width: "200",
                                offset: -78,
                                placement: "bottom",
                                "visible-arrow": false,
                                "popper-class": "col-popover",
                                trigger: "click",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col_edit_view flex-col" },
                                [
                                  _vm.costomColList.length
                                    ? _vm._l(_vm.costomColList, function (col) {
                                        return _c(
                                          "div",
                                          {
                                            key: col.id,
                                            staticClass: "flex-row col-row",
                                            staticStyle: {
                                              "justify-content":
                                                "space-between",
                                              "line-height": "20px",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "costom_name" },
                                              [_vm._v(_vm._s(col.name))]
                                            ),
                                            _c(
                                              "span",
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      "visible-arrow": false,
                                                      content:
                                                        _vm.$t("title1593"),
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.editCol(
                                                              col
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-edit",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      "visible-arrow": false,
                                                      content:
                                                        _vm.$t("title256"),
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          size: "mini",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.removeCol(
                                                              col
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-delete",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      })
                                    : _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "text-align": "center",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("title712")))]
                                      ),
                                ],
                                2
                              ),
                              _c(
                                "span",
                                {
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "16px",
                                      height: "16px",
                                      "margin-bottom": "-3px",
                                      cursor: "pointer",
                                    },
                                    attrs: { src: _vm.colEditBtn },
                                    on: {
                                      mouseenter: function ($event) {
                                        _vm.colEditBtn = _vm.colEditHoverImg
                                      },
                                      mouseleave: function ($event) {
                                        _vm.colEditBtn = _vm.colEditImg
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.$t("title5"),
                    placement: "top-start",
                  },
                },
                [
                  !_vm.isHideHelp
                    ? _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.btnClick("help")
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/control-help.png"),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.$t("title1897") + _vm.$t("title2379"),
                    placement: "top",
                  },
                },
                [
                  !_vm.isHideRefresh
                    ? _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.btnClick("refresh")
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/control-refresh.png"),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content:
                      _vm.$t("title430") +
                      _vm.$t("title10130") +
                      _vm.$t("title10515"),
                    placement: "top",
                  },
                },
                [
                  !_vm.isHideSetting
                    ? _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.btnClick("setting")
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/control-setting.png"),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "el-tooltip",
                {
                  directives: [
                    { name: "exportAuthButton", rawName: "v-exportAuthButton" },
                  ],
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.$t("title192") + _vm.$t("title2379"),
                    placement: "top-end",
                  },
                },
                [
                  !_vm.isHideDownload
                    ? _c(
                        "div",
                        {
                          staticClass: "btn",
                          on: {
                            click: function ($event) {
                              return _vm.btnClick("download")
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/control-download.png"),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.sortPanelShow && !_vm.sortType
        ? _c("sort-panel", {
            attrs: { sortItems: _vm.sortItems },
            on: { "after-save": _vm.afterSave },
            model: {
              value: _vm.sortPanelShow,
              callback: function ($$v) {
                _vm.sortPanelShow = $$v
              },
              expression: "sortPanelShow",
            },
          })
        : _vm._e(),
      _vm.sortTreePanelShow && _vm.sortType === "tree"
        ? _c("sort-tree-panel", {
            attrs: { sortItems: _vm.sortItems },
            on: { "after-save": _vm.afterSave },
            model: {
              value: _vm.sortTreePanelShow,
              callback: function ($$v) {
                _vm.sortTreePanelShow = $$v
              },
              expression: "sortTreePanelShow",
            },
          })
        : _vm._e(),
      _vm.sortTreePanelShow && _vm.sortType === "complex"
        ? _c("sortTreePanelCopy", {
            attrs: { sortItems: _vm.sortItems },
            on: { "after-save": _vm.afterSave },
            model: {
              value: _vm.sortTreePanelShow,
              callback: function ($$v) {
                _vm.sortTreePanelShow = $$v
              },
              expression: "sortTreePanelShow",
            },
          })
        : _vm._e(),
      _vm.costColPanelShow
        ? _c("costom-column-panel", {
            attrs: {
              sortItems: _vm.sortItems,
              api: _vm.apiUrl,
              id: _vm.colId,
              colInfo: _vm.colInfo,
            },
            on: { "after-save": _vm.afterSaveCol },
            model: {
              value: _vm.costColPanelShow,
              callback: function ($$v) {
                _vm.costColPanelShow = $$v
              },
              expression: "costColPanelShow",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }