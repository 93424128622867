import request from '@/utils/request'

export default {
  // 创建
  createCol (data) {
    return request({
      url: '/erp/custom-column/create_v2',
      method: 'post',
      data
    })
  },
  // 更新
  updateCol (data) {
    return request({
      url: '/erp/custom-column/update',
      method: 'put',
      data
    })
  },
  // 获取页面已添加字段
  getCols (params) {
    return request({
      url: '/erp/custom-column/page',
      method: 'get',
      params
    })
  },
  deleteCol(params) {
    return request({
      url: '/erp/custom-column/delete',
      method: 'delete',
      params
    })
  }
}
