<template>
  <div class="navbar" ref="navbar">
    <div class="nav-left">
      <!-- <logo v-if="sidebar.hide" type="nav" :collapse="isCollapse" /> -->
      <!-- <div v-else style="color:#000;font-size: 14px;">欢迎您，{{ nickname }}！</div> -->
      <!-- <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav"/> -->
      <tags-view v-if="needTagsView" />
    </div>
    <div class="right-menu">
      <template v-if="device!=='mobile'">
        <!-- <search id="header-search" class="right-menu-item" /> -->
        <!-- <div class="guide-btn" @click="open=true">新手向导</div> -->
        <div class="right-menu-item" v-if="false" @click="showClockScreen=!showClockScreen">
          <div class="icon icon1"></div>
        </div>
        <el-dropdown class="avatar-container" disabled @command="changeLang" v-if="false">
          <div class="right-menu-item" style="font-weight:500">{{ language.toUpperCase() }}</div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="en">English</el-dropdown-item>
            <el-dropdown-item divided command="id">Indonesia</el-dropdown-item>
            <el-dropdown-item divided command="vn">Tiếng Việt</el-dropdown-item>
            <el-dropdown-item divided command="th">ไทย</el-dropdown-item>
            <el-dropdown-item divided command="cn">中文</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-popover
          v-if="!forChatplus()"
              popper-class="concat-popover"
              placement="bottom-end"
              :offset="45"
              trigger="hover"
            >
              <div style="width: 166px;height: 196px;display: flex;align-items: center;flex-direction: column;line-height: 1;">
                <div style="font-weight: 600;font-size: 14px;color: #333;margin: 20px 0 15px 0;">实时掌握店铺销售情况</div>
                <img style="width: 100px;height: 100px;" src="@/assets/images/miniCode.png" />
                <div style="font-weight: 400;font-size: 12px;color: #333333;margin: 15px 0 20px 0;">微信小程序</div>
              </div>
              <div class="flex-row" style="background:#F6F7FB;padding:11px 15px;margin-left: 10px;border-radius: 19px;cursor:pointer" slot="reference">
                <img style="width:16px;height:16px;margin-right:4px" src="@/assets/images/mini.png" />
                <div style="font-weight: 400;font-size: 12px;color: #666666;">小程序</div>
              </div>
        </el-popover>
        <div class="right-menu-item" @click="$router.push('/notice/list')">
          <el-badge :is-dot="unreadCnt>0">
            <div class="icon icon2"></div>
          </el-badge>
        </div>
        <!-- <div class="right-menu-item" v-if="isCrm()">
          <missionCenter ref="mission"></missionCenter>
        </div> -->
        <div v-if="!forChatplus()" class="right-menu-item" @click="handleHelpClick">
          <div class="icon icon3"></div>
        </div>
        <div class="right-menu-item" v-if="false">
          <div class="icon icon4"></div>
        </div>
        <!-- <screenfull id="screenfull" class="right-menu-item hover-effect" /> -->
      </template>
      <el-dropdown class="avatar-container">
        <div class="avatar-wrapper">
          <div class="right-menu-item">
            <div class="user-nickname">{{ (nickname||'').slice(-2) }}</div>
          </div>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown" class="nav-user-dropdown">
          <div class="user-type">
            <span>{{ $t('title10251') }}:{{myPackage.packageName}}</span>
            <el-button v-if="!forChatplus()" type="primary" size="small" class="shandian" @click="jump('/user/package')">{{ $t('title10252') }}</el-button>
          </div>
          <router-link to="/system/user-info?state=0">
            <el-dropdown-item>{{ $t('title10253') }}</el-dropdown-item>
          </router-link>
          <router-link to="/system/user-info?state=2">
            <el-dropdown-item>{{ $t('title10254') }}</el-dropdown-item>
          </router-link>
          <router-link to="/system/user-info?state=3">
            <el-dropdown-item>{{ $t('title10255') }}</el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="logout">
            <span v-if="isRunas()">{{ $t('title10256') }}</span>
            <span v-else>{{ $t('title10257') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="clock-screen" v-show="showClockScreen">
      <div class="cover">
        <div class="content">
          <div class="name">{{ $t('title10258') }}</div>
          <div class="user">sun***</div>
          <el-form class="myForm" size="small">
            <div class="inline">
              <el-form-item class="join">
                <el-input class="join-left-right" :type="password?'password':'text'" v-model="clockPw" :placeholder="$t('title10259')">
                  <i slot="prefix" class="lock input-icon"/>
                  <i slot="suffix" @click="password=!password" :class="['input-icon', password?'open':'close']"/>
                </el-input>
                <el-button class="join-left" type="primary" ></el-button>
              </el-form-item>
            </div>
          </el-form>
          <div class="other" @click="showClockScreen=!showClockScreen">{{ $t('title10260') }}</div>
        </div>
        <div class="bottom">
          <div>{{ $t('title10261') }} © {{ new Date().getFullYear() }} www.xinjianerp.com. All Rights Reserved.</div>
          <div>{{ $t('title10262') }}ICP{{ $t('title10263') }}</div>
        </div>
      </div>
    </div>

    <!-- <custom-guide v-if="open" :open="open" :isNeverShow="isNeverShow" @close="open=false" /> -->
  </div>
</template>

<script>
import router from '@/router'
import { mapGetters,mapState} from 'vuex'
import TagsView from './TagsView'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'
import RuoYiGit from '@/components/RuoYi/Git'
import RuoYiDoc from '@/components/RuoYi/Doc'
import missionCenter from '@/views/crm/components/missionCenter/index.vue'
import {getPath, forChatplus} from "@/utils/ruoyi";
import Logo from "./Sidebar/Logo";
// import CustomGuide from './customGuide'
import {noviceGuide} from "@/api/system/user";
import {getUnReadCnt} from "@/api/system/notice";
import {getFromToken, isRunas, removeFromToken, setToken} from "@/utils/auth";
import {lang} from '@/i18n'
import store from '@/store'
export default {
  components: {
    TopNav,
    TagsView,
    Logo,
    // CustomGuide,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    RuoYiGit,
    RuoYiDoc,
    missionCenter
  },
  data(){
    return {
      packageName:'',
      isNeverShow:true,
      password:false,
      clockPw:'',
      showClockScreen:false,
      //新手指引
      open:false,
      unreadCnt:0,
      platforms: [],
      authForm: {},
      rules: {
        alias: [{required: true, message: this.$t('title6000'), trigger: "blur"}],
        warehouseId: [{required: true, message: this.$t('title6001'), trigger: "blur"}],
      },
      language: lang,
      storageUserId: JSON.parse(localStorage.getItem('TENANT_ID')) || null
    }
  },
  computed: {
    ...mapState({needTagsView: state => state.settings.tagsView}),
    ...mapGetters([
      'userId',
      'sidebarRouters',
      'myPackage',
      'sidebar',
      'avatar',
      'nickname',
      'device',
      'notice'
    ]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav
      }
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  created(){
    // this.getNoviceGuide();
    // this.initUnreadCnt();
  },
  mounted() {
    // const container = this.$refs.navbar
    // const resizeObserver = new ResizeObserver(entries => {
    //   const dom = this.$refs.mission
    //   this.$store.state.missionCenter = dom
    // })
    // resizeObserver.observe(container)
  },
  beforeDestroy() {
    // const container = this.$refs.navbar
    // const resizeObserver = new ResizeObserver(entries => {})
    // resizeObserver.unobserve(container)
  },
  watch: {
    storageUserId: {
      handler(val) {
        if (val) {
          // console.log(val)
          // console.log('localStorage.TENANT_ID')
          this.getNoviceGuide();
          this.initUnreadCnt();
        }
      },
      immediate: true
    },
    notice: {
      handler (val) {
        this.initUnreadCnt()
      }
    }
  },
  methods: {
    isRunas,
    forChatplus,
    jump(path){
      if(path==='/system/erp-user'){
        if(!this.checkPermi(['erp:user:list'])){
          this.$message.error(this.$t('title10245'));
          return ;
        }
      }
      this.open=false;
      if(path instanceof Array){
        path.forEach(item=>router.push(item))
      }else{
        router.push(path)
      }
    },
    isCrm(){
      const {sidebarRouters,routeMenuPriority}=this;
      let notSys=sidebarRouters.filter(item=>item.path!=='/system');
      let crmRouters=notSys.filter(item=>item.sidebarType==='crm');
      return crmRouters.length
    },
    handleHelpClick() {
      window.open('/docs');
    },
    close(){
      this.open=false;
    },
    initUnreadCnt() {
      getUnReadCnt().then(resp=>{
        this.unreadCnt = resp.data;
      });
    },
    getNoviceGuide(){
      noviceGuide().then(res=>{
        const {neverShow}=res.data;
        if(!neverShow){
          this.open=true;
        }
        this.isNeverShow=!!neverShow;
      })
      this.$store.dispatch('setMyPackage')
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      let confirmMsg = this.$t('title10264');
      if (isRunas()) {
        confirmMsg = this.$t('title10265');
      }
      this.$modal.confirm(confirmMsg, this.$t('title10266')).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          if (isRunas()) {
            setToken(getFromToken());
            removeFromToken();
            let path=localStorage.getItem('RUNAS')||'/system/list'
            location.href = getPath(path);
          } else {
            if (this.forChatplus()) {
              location.href = 'https://bi.zhisuitech.com'
              return
            }
            location.href = getPath('/index/home');
          }
        })
      }).catch(() => {});
    },
    changeLang (command) {
      location.reload();
      // this.language = command;
      // this.$i18n.locale = command
      localStorage.setItem('xinjianLang',command);
    }
  }
}
</script>

<style>
.concat-popover {
  box-shadow: 2px 2px 6px 0px rgba(162, 162, 162, 0.5);
  border-radius: 10px;
  padding: 0;
  .popper__arrow {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.navbar {
  height: 50px;
  padding:0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 66px;
  background: #FFFFFF;
  border-radius: 10px;
  &.leftMore{
    // padding-left: 75px;
  }
  .nav-left{
    align-self: flex-end;
    width:calc(100% - 380px);
  }
  .right-menu {
    margin-left:40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &:focus {
      outline: none;
    }
    .guide-btn{
      color:#1377ff;
      padding-left: 18px;
      padding-right: 10px;
      cursor: pointer;
      background: url(../../assets/images/g108.png) no-repeat left;
      background-size: 14px;
    }
    .right-menu-item {
      display:flex;
      align-items: center;
      justify-content: center;
      width: 38px;
      height: 38px;
      cursor:pointer;
      border-radius:50%;
      background: #F6F7FB;
      font-size:12px;
      color: #333;
      margin-left:10px;
      &:first-child{
        margin-left:0;
      }
      .icon{
        width:24px;
        height:24px;
        background:no-repeat center;
        background-size: contain;
        &.icon1{
          background-image: url(../../assets/images/ic_241lock@2x.png);
        }
        &.icon2{
          background-image: url(../../assets/images/ic_242lock@2x.png);
        }
        &.icon3{
          background-image: url(../../assets/images/ic_244lock@2x.png);
        }
        &.icon4{
          background-image: url(../../assets/images/ic_243lock@2x.png);
        }
        &.icon5{
          background-image: url(../../assets/images/mini.png);
        }
      }
      .iconMini{
        background:no-repeat center;
        background-size: contain;
        width:16px;
        height:16px;
        background-image: url(../../assets/images/mini.png);
      }
    }

    .avatar-container {
      margin-left:10px;
      .avatar-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .right-menu-item{
          background-color:#1377FF;
          color:#fff;
        }
        .el-icon-caret-bottom {
          font-size: 18px;
          margin-left:5px;
        }
      }
    }
  }
  .clock-screen{
    position:fixed;
    left:0;
    right:0;
    bottom:0;
    top:0;
    z-index:1999;
    color:#fff;
    font-size: 12px;
    text-align:center;
    background: url(../../assets/images/d107.png) no-repeat center;
    background-size:cover;
    .cover{
      position:absolute;
      width:100%;
      height:100%;
      background:rgba(51,51,51,0.6);
      .content{
        width:338px;
        margin:0 auto;
        position:relative;
        top:50%;
        transform: translateY(-60%);
        .name{
          width:54px;
          height:54px;
          line-height:54px;
          margin:0 auto;
          border-radius:50%;
          background:#1377ff;
          font-size:14px;
        }
        .user{
          font-size:24px;
          font-weight: 600;
          padding:11px 0 46px;
        }
        .inline{
          justify-content:center;
          ::v-deep .join-left-right{
            .el-input__inner{
              width:279px;
              border-left:0!important;
              height:40px!important;
              line-height: 40px;
              color:#fff;
              background:rgba(51,51,51,0.6);
              border-radius:2px 0 0 2px;
            }
            .input-icon{
              width:26px;
              height:26px;
              background: no-repeat center;
              background-size: contain;
              display:inline-block;
              vertical-align: text-top;
              &.open{
                background-size: 16px;
                background-image: url(../../assets/images/eye-close.png);
              }
              &.close{
                background-size: 16px;
                background-image: url(../../assets/images/eye-open.png);
              }
              &.lock{
                background-size: 20px;
                background-image: url(../../assets/images/d106.png);
              }
            }
          }
          .join-left{
            background:#1377ff url(../../assets/images/d108.png) no-repeat center;
            background-size: 20px;
            padding:20px 29px;
          }
        }
        .other{
          padding-top:15px;
          font-size:14px;
          font-weight: 600;
          cursor:pointer;
        }
      }
      .bottom{
        position:absolute;
        left:0;
        right:0;
        bottom:30px;
        line-height: 24px;
      }
    }
  }
}

</style>
