var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "my-dialog",
    {
      staticClass: "myDlg",
      attrs: {
        title: _vm.id
          ? _vm.$t("title11209") + _vm.$t("title11211")
          : _vm.$t("title11199") + _vm.$t("title11211"),
        width: "968px",
        showClose: true,
        "before-close": _vm.cancel,
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "flex-row", staticStyle: { "margin-bottom": "5px" } },
        [
          _c(
            "el-form",
            {
              ref: "baseForm",
              staticClass: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                inline: true,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "309px" },
                  attrs: { label: _vm.$t("title11200"), prop: "name" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      maxlength: "10",
                      "show-word-limit": "",
                      placeholder: _vm.$t("title1580") + _vm.$t("title11200"),
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "309px" },
                  attrs: { label: _vm.$t("title11201"), prop: "calculation" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: _vm.$t("title1246") + _vm.$t("title11201"),
                      },
                      model: {
                        value: _vm.form.calculation,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "calculation", $$v)
                        },
                        expression: "form.calculation",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("title11203"), value: 1 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("title11204"), value: 0 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("title11210"), value: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "309px", margin: "0" },
                  attrs: { label: _vm.$t("title11202"), prop: "show" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: _vm.$t("title1246") + _vm.$t("title11201"),
                      },
                      model: {
                        value: _vm.form.show,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "show", $$v)
                        },
                        expression: "form.show",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("title11205"), value: 1 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("title11206"), value: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "flex-row" }, [
        _c(
          "div",
          {
            staticClass: "white-box",
            staticStyle: {
              width: "250px",
              height: "580px",
              overflow: "hidden",
              "margin-right": "10px",
              "padding-right": "8px",
            },
          },
          [
            _c(
              "el-input",
              {
                staticClass: "searchIpt",
                staticStyle: {
                  width: "calc(100% - 8px)",
                  "margin-bottom": "10px",
                },
                attrs: {
                  clearable: "",
                  placeholder: _vm.$t("title1580") + _vm.$t("title10509"),
                },
                model: {
                  value: _vm.filterWord,
                  callback: function ($$v) {
                    _vm.filterWord = $$v
                  },
                  expression: "filterWord",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-center",
                    staticStyle: { height: "36px" },
                    attrs: { slot: "suffix" },
                    slot: "suffix",
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "14px",
                        height: "14px",
                        cursor: "pointer",
                      },
                      attrs: {
                        src: require("../../assets/images/costomColSearch.png"),
                      },
                    }),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "codeView",
                staticStyle: {
                  width: "100%",
                  overflow: "hidden auto",
                  height: "502px",
                },
              },
              _vm._l(_vm.codeOptions, function (code, index) {
                return _c(
                  "div",
                  {
                    key: "code" + index,
                    staticClass: "codeItem flex-row",
                    on: {
                      click: function ($event) {
                        return _vm.joinToCalc(code)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      [
                        _vm._v(" " + _vm._s(code.name) + " "),
                        code.code === "saleVolume"
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  placement: "right",
                                  content:
                                    "销量必须与其他金额字段结合计算，不能单独计算。",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-question" })]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("img", {
                      staticStyle: { width: "12px", height: "12px" },
                      attrs: { src: require("@/assets/images/addBtn.png") },
                    }),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "white-box",
            staticStyle: { width: "688px", height: "580px" },
          },
          [
            _c(
              "div",
              {
                staticClass: "flex-row",
                staticStyle: { "margin-bottom": "16px" },
              },
              [
                _vm._l(_vm.symbolList, function (symbol, index) {
                  return _c(
                    "div",
                    {
                      key: "symbol_" + index,
                      staticClass: "symbolBtn flex-center",
                      on: {
                        click: function ($event) {
                          return _vm.joinToCalc(symbol)
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          style: { fontSize: ["(", ")"].includes(symbol.code) },
                        },
                        [_vm._v(_vm._s(symbol.name))]
                      ),
                    ]
                  )
                }),
                _c(
                  "div",
                  {
                    staticClass: "appendIpt flex-row",
                    staticStyle: {
                      "align-items": "center",
                      "justify-content": "space-between",
                      "margin-right": "10px",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "numIpt noborder",
                      staticStyle: { width: "100px" },
                      attrs: {
                        type: "number",
                        size: "mini",
                        clearable: "",
                        placeholder: _vm.$t("title11207"),
                      },
                      model: {
                        value: _vm.costomNum,
                        callback: function ($$v) {
                          _vm.costomNum = $$v
                        },
                        expression: "costomNum",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "appendBtn", on: { click: _vm.joinNum } },
                      [_vm._v(_vm._s(_vm.$t("title10635")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "clearBtn",
                    on: {
                      click: function ($event) {
                        _vm.calcList = []
                        _vm.warning = false
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("title1037")))]
                ),
              ],
              2
            ),
            _c(
              "div",
              {
                staticClass: "sort_view_outside flex-col",
                style: { borderColor: _vm.warning ? "#FF3141" : "#d3d3d3" },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex-row sort_view" },
                  _vm._l(_vm.calcList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "calc_item_" + index,
                        staticClass: "calcItem draggable flex-center",
                      },
                      [
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              width: "54",
                              placement: "top",
                              "popper-class": "myPopper",
                              trigger: "hover",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(index)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("title256")))]
                            ),
                            _c(
                              "span",
                              {
                                attrs: { slot: "reference" },
                                slot: "reference",
                              },
                              [_vm._v(_vm._s(item.name))]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex-row",
                    staticStyle: { "justify-content": "space-between" },
                  },
                  [
                    _c(
                      "span",
                      { style: { color: _vm.warning ? "#FF3141" : "#666" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.warning
                              ? _vm.$t("title15486")
                              : _vm.$t("title15487")
                          )
                        ),
                      ]
                    ),
                    _c("span", { staticStyle: { color: "#999" } }, [
                      _vm._v(_vm._s(_vm.$t("title15488"))),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancel } },
            [_vm._v(_vm._s(_vm.$t("title587")))]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v(_vm._s(_vm.$t("title1013")))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }