<template>
  <div>
    <!-- <i class="el-icon-close close" @click="close"></i> -->
    <div class="lineCharts"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { mapGetters } from 'vuex'
export default {
  name: "",
  props: {
    row: {
      type: Object,
      default: () => {
        return {};
      },
    },
    ids:{
        type:Number,
        default: null
    },
    type:{
        type:String,
        default: null
    }
  },
  data() {
    return {
      xData:[],
      yData: [
        {
          name: "有效销售额",
          code: "saleAmount",
        },
        {
          name: "商品成本",
          code: "productCost",
        },
        {
          name: "打包费用",
          code: "packageFee",
        },
        {
          name: "卖家优惠",
          code: "sellerDiscountAmount",
        },
        {
          name: "运费支出",
          code: "shippingFee",
        },
        {
          name: "退款金额",
          code: "refundAmount",
        },
        {
          name: "佣金",
          code: "commission",
        },
        {
          name: "服务费",
          code: "serviceFee",
        },
        {
          name: "交易手续费",
          code: "paymentFee",
        },
        {
          name: "头程费用",
          code: "initialCost",
        },
        {
          name: "尾程费用",
          code: "finalCost",
        },
        {
          name: "税费",
          code: "tax",
        },
        {
          name: "广告税费",
          code: "adTax",
        },
        {
          name: "广告花费",
          code: "adCost",
        },
        {
          name: "其他调整成本",
          code: "otherAdjustFee",
        },
        {
          name: "测评成本",
          code: "testCost",
        },
      ],
      yData1: [
        {
          name: "有效销售额",
          code: "saleAmount",
        },
        {
          name: "商品成本",
          code: "productCost",
        },
        {
          name: "打包费用",
          code: "packageFee",
        },
        {
          name: "卖家优惠",
          code: "sellerDiscountAmount",
        },
        {
          name: "运费支出",
          code: "shippingFee",
        },
        {
          name: "退款金额",
          code: "refundAmount",
        },
        {
          name: "佣金",
          code: "commission",
        },
        {
          name: "服务费",
          code: "serviceFee",
        },
        {
          name: "交易手续费",
          code: "paymentFee",
        },
        {
          name: "头程费用",
          code: "initialCost",
        },
        {
          name: "尾程费用",
          code: "finalCost",
        },
        {
          name: "税费",
          code: "tax",
        },
        {
          name: "其他调整成本",
          code: "otherAdjustFee",
        },
        {
          name: "测评成本",
          code: "testCost",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['initialState'])
  },
  mounted() {
    if(this.type == 'linkSku' && this.row.platform =='tiktok'){
      this.yData = this.yData1
    }else{
    }
    this.dealConst()
    this.dealXData()
    this.timeId = setTimeout(() => {
        this.initCharts(this.yData, this.xData, this.ids);
    }, 300)
  },
  beforeDestroy() {
    clearTimeout(this.timeId)
  },
  methods: {
    close(){
      this.$emit('close')
    },
    dealConst(){
      let arr = [...this.yData]
      if(this.initialState.initialCost === 'false'){
        arr = this.yData.filter(item => item.code!= 'initialCost')
      }
      if(this.initialState.finalCost  === 'false'){
        arr = arr.filter(item => item.code!= 'finalCost')
      }
      this.yData = arr
    },
    dealXData() {
        this.yData.forEach((item) => {
            this.xData.push(this.row[item.code] || 0)
        })
        this.xData = this.xData.reverse()
    },
    initCharts(yData, xData, index) {
      document.getElementsByClassName("lineCharts")[index].removeAttribute('_echarts_instance_');
      var chartDom = document.getElementsByClassName("lineCharts")[index];
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: {
          text: "成本与有效销售额占比",
          textStyle: {
            fontSize: '14px',
            color: '#333333'
          },
          top: "8px",
          left: "8px"
        },
        tooltip: {
          show: false,
          // trigger: "axis",
          // axisPointer: {
          //   type: "shadow",
          // },
        },
        grid: {
          left: "3%",
          right: "18%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          show:true,
          type: "value",
          splitLine: {show: false},
          boundaryGap: [0, 0.01],
          axisLine: {
            show: true,  // 显示 x 轴的轴线
            lineStyle: {
                color: 'rgba(0,0,0,0.06)'  // 设置x轴的颜色
            }
          },
          axisLabel: {
            color: ' #666666'  // 确保 x 轴字体颜色为黑色
          }
        },
        yAxis: {
          type: "category",
          axisTick: {
            show: false  // 隐藏 y 轴的刻度线
          },
          axisLine: {
            lineStyle: {
                color: 'rgba(0,0,0,0.06)'  // 设置y轴的颜色
            }
          },
          axisLabel: {
            color: ' #666666'  // 确保 x 轴字体颜色为黑色
          },
          data: yData.map((item) => item.name).reverse(),
        },
        series: [
          {
            type: "bar",
            barWidth: "12px",
            data: xData.map((item) => {
              return {
                value: item,
                itemStyle: {
                    borderRadius: item > 0 ? [0,100,100,0]: [100,0,0,100]
                }
              }
            }),
            itemStyle: {
              normal:{
                color: function(params){
                  if(params.name == '有效销售额'){
                    return '#0FC6C2'
                  }else{
                    return '#1377FF'
                  }
                },
                label:{
                  show:true,
                  position:'right',
                  overflow: 'break',  // 超出部分不被截断，可以换行显示
                  formatter: function(params){
                    if(params.name == '有效销售额' && params.value){
                      return `￥${params.value.toFixed(2)}(100%)`
                    }else if(params.value) {
                      return `￥${(params.value).toFixed(2)}`+ (xData[xData.length - 1] ? `(${(((params.value).toFixed(2) / xData[xData.length - 1])*100).toFixed(2)}%)` : `(0%)`)
                    }else{
                      return `￥0(0%)`
                    }
                  }
                }
              }
            }
          },
        ],
      };
      option && myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.close{
  position: absolute;
  right:20px;
  top:20px;
  cursor: pointer;
  z-index:1000;
}
.lineCharts {
    width: 619px;
    height: 492px;
}

</style>
