import request from '@/utils/request'

// 使用租户名，获得租户编号带@
export function getTenantIdByName(name) {
  return request({
    url: '/system/tenant/get-id-by-name',
    method: 'get',
    params: {
      name
    }
  })
}
// 使用租户名，获得租户编号不带@
export function getTenantIdByMobile(mobile) {
  return request({
    url: '/system/tenant/get-id-by-mobile',
    method: 'get',
    params: {
      mobile
    }
  })
}

// 创建租户
export function createTenant(data) {
  return request({
    url: '/system/tenant/create',
    method: 'post',
    data: data
  })
}

// 更新租户
export function updateTenant(data) {
  return request({
    url: '/system/tenant/update',
    method: 'put',
    data: data
  })
}

export function updateTenantName(tenantName) {
  return request({
    url: '/system/tenant/update-tenant-name?tenantName=' + tenantName,
    method: 'post'
  })
}

// 删除租户
export function deleteTenant(id) {
  return request({
    url: '/system/tenant/delete?id=' + id,
    method: 'delete'
  })
}

// 获得租户
export function getTenant(id) {
  return request({
    url: '/system/tenant/get?id=' + id,
    method: 'get'
  })
}

export function runas(id) {
  return request({
    headers: {"runas": true},
    url: '/erp/tenant/runas?tenantId=' + id,
    method: 'post'
  })
}

// 获得租户分页
export function getTenantPage(query) {
  return request({
    url: '/system/tenant/page',
    method: 'get',
    params: query
  })
}

// 导出租户 Excel
export function exportTenantExcel(query) {
  return request({
    url: '/system/tenant/export-excel',
    method: 'get',
    params: query,
    responseType: 'blob'
  })
}

// 获取租户信息
export function getTenantInfo() {
  return request({
    url: '/erp/tenant/get',
    method: 'get'
  })
}

// 设置租户本地区域
export function setLocalRegion (data) {
  return request({
    url: '/erp/tenant/setLocalRegion',
    method: 'post',
    data
  })
}
// 获取是否显示某些字段的开关
export function getShowField (params) {
  return request({
    url: '/system/tenant/get-show-field',
    method: 'get',
    params
  })
}

// 设置是否显示某些字段的开关
export function setShowField (data) {
  return request({
    url: '/system/tenant/set-show-field',
    method: 'put',
    data
  })
}
//获取库存开关
export function getStockSwitch (id) {
  return request({
    url: '/erp/shop-order/get-stock-switch?tenantId='+id,
    method: 'get',
  })
}
//设置库存开关
export function setStockSwitch (stockSwitchStatus,tenantId) {
  return request({
    url: '/erp/shop-order/set-stock-switch?stockSwitchStatus='+stockSwitchStatus+'&tenantId='+tenantId,
    method: 'get',
  })
}
//超级套餐开关
export function updateSuperPackage (data) {
  return request({
    url: '/system/tenant/update-super-package',
    method: 'put',
    data
  })
}
